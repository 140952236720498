import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-cart-item-card',
  templateUrl: './cart-item-card.component.html',
  styleUrls: ['./cart-item-card.component.sass'],
})
export class CartItemCardComponent implements OnInit {
  @Input() serviceName: String
  @Input() packagePrice: String
  @Input() removeFunc = (a: String, b: String, c: String) => {}
  @Input() packageName: String
  @Input() packageDesc: String
  @Input() projectId: String
  @Input() unitId: String
  @Input() serviceId: String
  isHidden: boolean = false

  constructor() {}

  ngOnInit(): void {}

  onRemovePackage(projId, unitId, serviceId) {
    this.removeFunc(projId, unitId, serviceId)
  }

  onClickHide() {
    this.isHidden = !this.isHidden
  }
}
