<div class="card">
  <h2>Short Stay Setup</h2>
  <div class="content mb-3">
    <span class="body-2"
      >Your Unit is under going setup by Host - Stay Tuned to Live</span
    >
    <div class="countdown mt-3">
      <div class="countdown-component">
        <div class="countdown-value">{{ days | number: "2.0" }}</div>
        <div class="body-2">DAYS</div>
      </div>
      <div class="countdown-value">:</div>
      <div class="countdown-component">
        <div class="countdown-value">{{ hours | number: "2.0" }}</div>
        <div class="body-2">HRS</div>
      </div>
      <div class="countdown-value">:</div>
      <div class="countdown-component">
        <div class="countdown-value">{{ mins | number: "2.0" }}</div>
        <div class="body-2">MINS</div>
      </div>
      <div class="countdown-value">:</div>
      <div class="countdown-component">
        <div class="countdown-value">{{ secs | number: "2.0" }}</div>
        <div class="body-2">SECS</div>
      </div>
    </div>
  </div>
</div>
