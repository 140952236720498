import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError } from 'rxjs/operators'

import { UserStore } from '../store/user.store'

import { environment } from 'src/environments/environment'
import { Endpoints } from '../../../shared/enums/endpoints.enum'
import { Observable, throwError } from 'rxjs'
import { ApiFactory } from 'src/app/modules/shared/factory/api.factory'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userStore: UserStore) {}

  excludedUrls: [string, string, string, string, string, string] = [
    `${environment.baseUrl}${Endpoints.LOGIN}`,
    `${environment.baseUrl}${Endpoints.REGISTER}`,
    `${environment.baseUrl}${new ApiFactory(Endpoints.CHANGE_PASSWORD)}`,
    `${environment.baseUrl}${new ApiFactory(Endpoints.FORGOT_PASSWORD)}`,
    `${environment.signeasyBaseUrl}${new ApiFactory(Endpoints.SIGNEASY_LIST)}`,
    `${environment.signeasyBaseUrl}${new ApiFactory(
      Endpoints.SIGNEASY_DOCSIGN
    )}`,
  ]

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.excludedUrls.includes(req.url)) {
      return next.handle(req)
    } else {
      // Get the auth token from the service.
      const authToken = this.userStore.getUser()?.access_token

      // Clone the request and set the new header in one step.
      const authReq = req.clone({
        setHeaders: { Authorization: 'Bearer ' + authToken },
      })

      // send cloned request with header to the next handler.
      return next.handle(authReq).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // Handle unauthorized error
              // console.log("Unauthorized");
              // logout() user
              this.userStore.logoutUser()
            } else {
              return throwError(err)
            }
            // ......
          }
          return new Observable<HttpEvent<any>>()
        })
      )
    }
  }
}
