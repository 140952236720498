import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { Project } from 'src/app/modules/shared/models/project.model'
import { UnitType } from 'src/app/modules/shared/models/property.model'
import {
  PackageDetail,
  Partner,
  RenoPartners,
  RenovationPartnerPackageRes,
  UnitPartnersRes,
  UnitTypesRes,
} from 'src/app/modules/shared/models/services.model'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import { ServicesService } from 'src/app/modules/shared/service/services.service'
import {
  CartItem,
  ServiceItem,
  UnitItem,
  UserCart,
} from 'src/app/modules/shared/state/user/user.model'
import { SecuredStore } from '../../../store/secured.store'
import {
  HasServicesStore,
  HasServicesStoreModel,
} from '../store/has-services.store'

@Component({
  selector: 'app-reno-partners',
  templateUrl: './reno-partners.component.html',
  styleUrls: ['./reno-partners.component.sass'],
})
export class RenoPartnersComponent implements OnInit {
  state: HasServicesStoreModel
  shoppingCarts: UserCart[] = []
  servicesId: string
  serviceIdentifier: string
  servicesName: string
  unitItem: UnitItem
  packageItem: CartItem = {
    project_id: '',
    project_name: '',
    unit: [],
  }
  selectedPackage: PackageDetail

  private readonly _formSubmitSource = new BehaviorSubject<boolean>(false)
  readonly formSubmitState = this._formSubmitSource.asObservable()

  constructor(
    private securedStore: SecuredStore,
    private route: ActivatedRoute,
    private servicesService: ServicesService,
    private projectService: ProjectsService,
    private store: HasServicesStore,
    private propertyService: PropertiesService,
    private userStore: UserStore,
    private router: Router
  ) {
    this.securedStore.updatePageTitle('Reno Partner Listings')
  }

  ngOnInit(): void {
    this.store.resetState()
    this.shoppingCarts = this.userStore.getCart()
    const routeParams = this.route.snapshot.paramMap
    this.servicesId = routeParams.get('id')
    this.serviceIdentifier = routeParams.get('identifier')

    this.getAllPartners()
    this.getProjects()
  }

  getProjects = () => {
    this.projectService.getProjects().then((resp: Project[]) => {
      this.state = this.store.getStateValue()
      this.state.projects = resp.map((project: Project) => {
        let generatedOption: Option = {
          id: project.id,
          label: project.name,
          value: project.id,
        }
        return generatedOption
      })
      this.store.setState(this.state)
    })
  }

  getUnitTypes = (projectId: string) => {
    this.servicesService.getUnitTypes(projectId).then((resp: UnitTypesRes) => {
      let state = this.store.getStateValue()
      state.properties = resp.data.map((unit_type: UnitType) => {
        let generatedOption: Option = {
          id: unit_type.id,
          label: unit_type.type,
          value: unit_type.id,
        }
        return generatedOption
      })
      this.store.setState(state)
    })
  }

  getPartners = (unit_type_id: string) => {
    this.servicesService
      .getPartners(unit_type_id)
      .then((res: UnitPartnersRes) => {
        this.state = this.store.getStateValue()
        this.state.partners = res.renovation_partners.map(
          (partner: Partner) => {
            let generatedOption: RenoPartners = {
              id: partner.id,
              name: partner.name,
              phone_number: '',
              description: partner.description,
              avatar_url: partner.avatar_url,
            }
            return generatedOption
          }
        )
        this.store.setState(this.state)
      })
  }

  getAllPartners = () => {
    this.servicesService
      .getRenovationPartners()
      .then((resp: RenoPartners[]) => {
        let state = this.store.getStateValue()
        state.partners = resp
        this.store.setState(state)
      })
  }

  getPackages = (partner_id: string) => {
    this.servicesService
      .getPartnerPackages(partner_id)
      .then((res: RenovationPartnerPackageRes) => {
        this.state = this.store.getStateValue()
        this.state.packages = res.data.packages.map(
          (renoPackage: PackageDetail) => {
            let generatedOption: PackageDetail = {
              id: renoPackage.id,
              name: renoPackage.name,
              price: renoPackage.price,
              description: renoPackage.description,
              avatar_url: renoPackage.avatar_url,
            }
            return generatedOption
          }
        )
        this.store.setState(this.state)
      })
  }

  onSelectPackage = (selected: PackageDetail) => {
    this.selectedPackage = selected
    this._formSubmitSource?.next(true)
  }

  onCheckFormEvent = (isvalid: boolean) => {
    if (isvalid) {
      this.onSubmitPackage()
    }
  }

  setFilter = (
    project_id: string,
    project_name: string,
    unit_type_id: string,
    unit_type: string
  ) => {
    this.packageItem.project_id = project_id
    this.packageItem.project_name = project_name
    this.unitItem = {
      unit_type: unit_type,
      unit_type_id: unit_type_id,
      service: [],
    }
    this.packageItem.unit = [this.unitItem]
  }

  onSubmitPackage = () => {
    // prepare package object
    let packageDetail: PackageDetail = {
      id: this.selectedPackage.id,
      name: this.selectedPackage.name,
      price: this.selectedPackage.price,
      description: this.selectedPackage.description,
      avatar_url: this.selectedPackage.avatar_url,
    }
    let serviceItem: ServiceItem = {
      service_id: this.servicesId,
      service_name: 'Renovation',
      package: [packageDetail],
      service_identifier: this.serviceIdentifier,
    }
    this.unitItem.service = [serviceItem]

    // new user cart
    let user = this.userStore.getUser().user
    let packages: CartItem[] = [this.packageItem]
    let newCart: UserCart = {
      user: user,
      item_count: 1,
      cart: packages,
    }

    this.userStore.addToCart(newCart, this.servicesId)

    this.router.navigate(['/checkout'])
  }
}
