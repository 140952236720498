import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Observable, Subscription } from 'rxjs'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { FileUploadResponse } from 'src/app/modules/shared/models/file-upload.model'
import { UserProfile } from 'src/app/modules/shared/models/profile.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { FilesUploadService } from 'src/app/modules/shared/service/files-upload.service'
import { ProfileStore } from '../../store/profile.store'

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.sass'],
})
export class ProfileCardComponent implements OnInit {
  profileForm = new FormGroup({
    email: new FormControl(''),
    fullname: new FormControl(''),
    phone: new FormControl(''),
    birthday: new FormControl(''),
    interest: new FormControl(''),
    id_card_no: new FormControl(''),
  })
  referralLink: string = ''
  profilePic: SafeResourceUrl = ''
  userState: UserProfile
  profileSubscriber: Subscription
  interestList: string[] = ['Bangi', 'bangi']
  isMobile: boolean
  interestFormActive: boolean = false
  newAvatarUrl: string = ''
  @Input() isBppUser: boolean

  constructor(
    private sanitizer: DomSanitizer,
    private profileStore: ProfileStore,
    private uploadService: FilesUploadService,
    private userStore: UserStore,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {
    this.profileSubscriber = this.profileStore.getState().subscribe(res => {
      this.userState = res
      this.profilePic =
        res.data.profile.avatar_url == null ? '' : res.data.profile.avatar_url
      let currUser = this.userStore.getUser()
      currUser!.user.avatar_url = this.profilePic
      this.userStore.setUser(currUser)
      this.referralLink = res.data.profile.referral_code
      this.profileForm.get('email').setValue(res.data.email)
      this.profileForm.get('fullname').setValue(res.data.name)
      this.profileForm.get('phone').setValue(res.data.profile.phone)
      this.profileForm.get('birthday').setValue(res.data.profile.birth_date)
      this.profileForm.get('id_card_no').setValue(res.data.profile.id_card_no)
      this.interestList = res.data.profile.area_of_interests
    })

    if (window.screen.width <= 425) {
      this.isMobile = true
    }
  }

  ngOnDestroy(): void {
    this.profileSubscriber.unsubscribe()
  }

  onUpload(event) {
    let img: File = event.target.files[0]
    let imgUrl = URL.createObjectURL(img)
    this.profilePic = this.sanitizer.bypassSecurityTrustResourceUrl(imgUrl)
    this.uploadService
      .uploadFiles(img)
      .then((res: FileUploadResponse) => {
        this.newAvatarUrl = res.file_ids[0]
      })
      .catch(err => {
        console.log(err)
        this.toastService.show('Error', `Img upload failed`)
      })
      .finally(() => {})
    event.target.value = ''
  }

  delInterest(idx) {
    this.interestList.splice(idx, 1)
  }

  addNewInterest = () => {
    this.interestList.push(this.profileForm.get('interest').value)
    this.profileForm.get('interest').setValue('')
    this.toggleAddInterest()
  }

  toggleAddInterest = () => {
    this.interestFormActive = !this.interestFormActive
  }

  shareReferral = () => {
    // alert('Copied to clipboard')
    this.toastService.show('Copied', 'Copied to clipboard')
  }
}
