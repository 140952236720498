import { Component, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject } from 'rxjs'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import {
  DemoDashboardData,
  earningStatsData,
} from 'src/app/modules/shared/factory/demo.factory'
import {
  DashboardApiRes,
  // DashboardSummary,
  HasAccSetup,
  PendingDoc,
  PropertySummary,
  Protection,
  RecentAct,
  EarningsOverview,
} from 'src/app/modules/shared/models/dashboard.model'
import {
  PropertyDetail,
  SummaryCard,
} from 'src/app/modules/shared/models/property.model'
import { DashboardService } from 'src/app/modules/shared/service/dashboard.service'
import { SecuredStore } from '../../../store/secured.store'
import { DashboardNoActiveUnitModalComponent } from '../../component/dashboard-no-active-unit-modal/dashboard-no-active-unit-modal.component'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private securedStore: SecuredStore,
    private dashboardService: DashboardService,
    private userStore: UserStore,
    private modalService: NgbModal
  ) {
    this.securedStore.updatePageTitle('Home')
  }

  propertyDetailDemo: PropertyDetail[]

  dashboardData: DashboardApiRes[]

  earningStats

  demo = false

  dropdownObj = {
    reports: {
      show: false,
    },
    monthYear: {
      show: false,
    },
  }

  setupUnfinished: boolean = false

  selectedMonthYearString: string = ''
  selectedMonthYear = {
    month: 0,
    year: 0,
  }

  reportList = [
    {
      name: '',
      url: '',
    },
  ]

  revenueReportUrl: string

  monthYearList = [
    {
      month: 1,
      year: 2000,
    },
  ].map(data => new Date(data.year, data.month - 1, 2))

  private readonly _totalEarningsSrc = new BehaviorSubject<SummaryCard>(null)
  readonly totalEarningsState = this._totalEarningsSrc.asObservable()

  private readonly _OccRateSrc = new BehaviorSubject<SummaryCard>(null)
  readonly occRateState = this._OccRateSrc.asObservable()

  private readonly _avgDailyEarningSrc = new BehaviorSubject<SummaryCard>(null)
  readonly avgDailyEarningState = this._avgDailyEarningSrc.asObservable()

  private readonly _earningsOverviewSource =
    new BehaviorSubject<EarningsOverview>(null)
  readonly earningsOverviewState = this._earningsOverviewSource.asObservable()

  private readonly _propertySumSource = new BehaviorSubject<PropertySummary>(
    null
  )
  readonly propertySumState = this._propertySumSource.asObservable()

  private readonly _pendingDocSource = new BehaviorSubject<PendingDoc[]>(null)
  readonly pendingDocState = this._pendingDocSource.asObservable()

  private readonly _recentActSource = new BehaviorSubject<RecentAct[]>(null)
  readonly recentActState = this._recentActSource.asObservable()

  private readonly _protectionSource = new BehaviorSubject<Protection>(null)
  readonly protectionState = this._protectionSource.asObservable()

  private readonly _sourceOfBookingSource = new BehaviorSubject<any>(null)
  readonly sourceOfBookingState = this._sourceOfBookingSource.asObservable()

  private readonly _accSetupSource = new BehaviorSubject<HasAccSetup[]>(null)
  readonly accSetupState = this._accSetupSource.asObservable()

  noActiveUnits: boolean = false
  isShowNoUnitNotif: boolean = false
  isUnitNotifClicked: boolean = false
  private readonly _noUnitSrc = new BehaviorSubject<boolean>(false)
  readonly noUnitState = this._noUnitSrc.asObservable()
  currentDate: Date = new Date()
  responseDataYear: Number = this.currentDate.getFullYear()
  isBppUser: boolean = false

  ngOnInit(): void {
    let joinDate = new Date(this.userStore.getUser()?.user.user_registered_at)
    if (this.userStore.getUser()?.user.role == 4) {
      this.isBppUser = true
    } else {
      this.isBppUser = false
    }

    if (joinDate) {
      this.populateCalendarDropdown(joinDate, this.currentDate)
    }
    this.selectMonthYear(this.currentDate)
    this.populateData()
    this.noUnitState.subscribe(val => {
      if (val != this.noActiveUnits && val == true) {
        this.modalService.open(DashboardNoActiveUnitModalComponent)
        this.noActiveUnits = val
      }
      if (this.noActiveUnits) {
        this.isShowNoUnitNotif = true
      }
    })
  }

  populateCalendarDropdown(joinDate: Date, currentDate: Date) {
    this.monthYearList = []
    this.propertyDetailDemo = []

    const joinedYear = joinDate.getFullYear()
    const joinedMonth = joinDate.getMonth()
    let startYear = joinedYear
    let startMonth = joinedMonth
    let endYear = currentDate.getFullYear()

    while (startYear <= endYear) {
      if (startYear > joinedYear) {
        startMonth = 0
      }
      let endMonth = currentDate.getMonth() + 1
      if (endYear > startYear) {
        endMonth = 12
      }
      while (startMonth + 1 <= endMonth) {
        // NOTE: property === 1 is only used for demo
        // if its demo then startMonth need to plus one because the joined date is december
        // so the dropdown will start with january
        if (this.demo) {
          this.monthYearList.push(new Date(startYear, startMonth + 1, 2))
        } else {
          this.monthYearList.push(new Date(startYear, startMonth, 2))
        }
        // this.propertyDetailDemo.push(demoData(startYear, startMonth + 1))

        startMonth++
      }
      startYear++
    }
  }

  toggleMenu = menu => {
    this.dropdownObj[menu].show = !this.dropdownObj[menu].show
  }

  openReport(url: string) {
    window.open(url)
    this.dropdownObj['reports'].show = false
  }

  openRevenue(url: string) {
    window.open(url)
  }

  selectMonthYear(date: Date) {
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let shortMonth = date.toLocaleString('en-us', { month: 'short' })
    this.selectedMonthYearString = shortMonth + ' ' + year
    this.selectedMonthYear = {
      month: month,
      year: year,
    }
    if (this.dropdownObj['monthYear'].show) {
      this.dropdownObj['monthYear'].show = false
    }
  }

  updateMonthYear(date: Date) {
    this.selectMonthYear(date)
    this.populateData()
  }

  onClickNoUnitNotif = () => {
    this.isShowNoUnitNotif = false
    this.isUnitNotifClicked = true
  }

  populateData = () => {
    const year = this.selectedMonthYear.year
    const month = this.selectedMonthYear.month
    this.dashboardService
      .getDashboardData(month, year)
      .then((res: DashboardApiRes) => {
        // if user is still new and there are no units yet
        if (res.short_stay_management_subscriptions.length <= 0) {
          return this.populateDemo({ month, year })
        }

        this.processResData(res)
      })
      .catch(err => {
        console.log(err)
        // let res = this.populateDemo()
        // this.processResData(res)
      })
      .finally(() => {})
  }

  populateDemo = ({ month, year }) => {
    this.demo = true

    // 1. show new user/no units popup and notif
    this._noUnitSrc.next(true)

    // 2. set demo data based on selected month and year
    let currentDate = new Date()

    // 3. recall date function and set selection to only consist of this month and last month (for demo)
    let joinDate = new Date('12-01-2021')
    this.populateCalendarDropdown(joinDate, currentDate)

    // 4. remove current month from dropdown
    this.monthYearList.pop()

    // res = this.findDemo(this.selectedMonthYear.month, this.selectedMonthYear.year);
    this.dashboardService
      .getDashboardDemoData({ year, month })
      .then((res: DashboardApiRes) => {
        this.processResData(res)
      })
      .catch(err => {
        console.error(err)
      })
  }

  findDemo = (month: number, year: number) => {
    this.dashboardData = DemoDashboardData(this.propertyDetailDemo)
    this.earningStats = earningStatsData(this.propertyDetailDemo)

    const index = this.dashboardData.findIndex(
      data => data.month === month && data.year === year
    )

    const previousMonthIndex =
      (index + this.propertyDetailDemo.length - 1) %
      this.propertyDetailDemo.length

    // NOTE:  showing previous month
    return this.dashboardData[previousMonthIndex]
  }

  processResData = (res: DashboardApiRes) => {
    let totalEarnings: SummaryCard = {
      bg_color: 'lightblue',
      type: 'nominal',
      icon: 'app/assets/total-revenue-icon.svg',
      title: 'Monthly Earnings',
      value: res.total_earnings ? res.total_earnings : 0,
    }

    let occupancyRate: SummaryCard = {
      bg_color: 'thickblue',
      type: 'percentage',
      icon: 'app/assets/total-profit-icon.svg',
      title: 'Occupancy Rate',
      value: res.occupancy_rate ? res.occupancy_rate : 0,
    }

    let avgDailyEarnings: SummaryCard = {
      bg_color: 'thickorange',
      type: 'nominal',
      icon: 'app/assets/total-expenses-icon.svg',
      title: 'Average Daily Earning',
      value: res.average_daily_earnings ? res.average_daily_earnings : 0,
    }

    if (res.has_account_setup?.length > 0) {
      for (let setup of res.has_account_setup) {
        if (setup.is_completed == false) {
          this.setupUnfinished = true
          break
        }
      }
    }

    if (res.download_report) {
      for (let i = 0; i < 4; i++) {
        this.reportList = [
          {
            name: 'Construction Report',
            url: res.download_report.construction_url,
          },
          {
            name: 'Defect Check Report',
            url: res.download_report.defect_check_url,
          },
          {
            name: 'Renovation Report',
            url: res.download_report.renovation_url,
          },
          {
            name: 'Revenue Report',
            url: res.download_report.revenue_url,
          },
        ]

        this.revenueReportUrl = res.download_report.revenue_url
      }
    }
    this.responseDataYear = res.year ? res.year : this.responseDataYear
    this._totalEarningsSrc.next(totalEarnings)
    this._OccRateSrc.next(occupancyRate)
    this._avgDailyEarningSrc.next(avgDailyEarnings)
    this._earningsOverviewSource.next(res.earnings_overview)
    this._propertySumSource.next(res.property)
    this._pendingDocSource.next(res.pending_documents)
    this._recentActSource.next(res.recent_activities)
    this._protectionSource.next(res.protection)
    this._sourceOfBookingSource.next(res.source_of_booking)
    this._accSetupSource.next(res.has_account_setup)
  }
}
