import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { EditTenancyModalComponent } from './component/edit-tenancy-modal/edit-tenancy-modal.component'
import { ListToSellModalComponent } from './component/list-to-sell-modal/list-to-sell-modal.component'
import { EditStatusModalComponent } from './component/edit-status-modal/edit-status-modal.component'
import { RemoveFromListingModalComponent } from './component/remove-from-listing-modal/remove-from-listing-modal.component'
import {
  PropertyOverviewModel,
  SubscriptionStatusModel,
} from './model/property-overview.model'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import { Option } from 'src/app/modules/shared/factory/option.factory'

@Component({
  selector: 'app-property-overview',
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.sass'],
})
export class PropertyOverviewComponent implements OnInit {
  slides = [
    { img: 'app/assets/agoda-logo-icon.png' },
    { img: 'app/assets/agoda-logo-icon.png' },
    { img: 'app/assets/agoda-logo-icon.png' },
    { img: 'app/assets/agoda-logo-icon.png' },
    { img: 'app/assets/agoda-logo-icon.png' },
    { img: 'app/assets/agoda-logo-icon.png' },
  ]
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  statusDefectCheck = Constants.STATUS_DEFECT_CHECK
  statusKeyCollection = Constants.STATUS_KEY_COLLECTION
  statusLive = Constants.STATUS_LIVE
  statusNewLaunch = Constants.STATUS_NEW_LAUNCH
  statusPendingApproval = Constants.STATUS_PENDING_APPROVAL
  statusRenovation = Constants.STATUS_RENOVATION
  statusShortStaySetup = Constants.STATUS_SHORT_STAY_SETUP
  statusSold = Constants.STATUS_SOLD
  statusUnderConstruction = Constants.STATUS_UNDER_CONSTRUCTION
  statusSubExpired = Constants.STATUS_SUB_EXPIRED

  @Input() propertyOverviewState: Observable<PropertyOverviewModel>
  @Input() tenancyTypeListState: Observable<Option[]>
  @Input() hostListState: Observable<Option[]>
  @Input() defectCheckCallback = () => {}
  @Input() triggerDataReload = () => {}
  @Input() isBppUser: boolean

  property: PropertyOverviewModel

  dropdownObj = {
    modify: {
      show: false,
    },
  }

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.propertyOverviewState.subscribe(data => {
      this.property = data

      if (this.property && this.property.images.length <= 0) {
        this.property.images.push({ url: 'app/assets/no-image.png' })
      }
    })
  }

  toggleMenu = menu => {
    this.dropdownObj[menu].show = !this.dropdownObj[menu].show
  }

  showDefectCard() {
    this.defectCheckCallback()
  }

  openModal = modal => {
    switch (modal) {
      case 'edit':
        const tenancyModalRef = this.modalService.open(
          EditTenancyModalComponent
        )
        tenancyModalRef.componentInstance.propertyId = this.property.id
        tenancyModalRef.componentInstance.tenancyTypeListState =
          this.tenancyTypeListState
        tenancyModalRef.componentInstance.hostListState = this.hostListState
        tenancyModalRef.componentInstance.triggerDataReload =
          this.triggerDataReload
        break
      case 'list_to_sell':
        const sellReqModalRef = this.modalService.open(ListToSellModalComponent)
        sellReqModalRef.componentInstance.propertyId = this.property.id
        sellReqModalRef.componentInstance.indicativeMarketValue =
          this.property.market_value
        break
      case 'edit_status':
        const editStatusRef = this.modalService.open(EditStatusModalComponent)
        editStatusRef.componentInstance.propertyId = this.property.id
        editStatusRef.componentInstance.triggerDataReload =
          this.triggerDataReload
        break
      case 'remove_from_list':
        const removalRef = this.modalService.open(
          RemoveFromListingModalComponent
        )
        removalRef.componentInstance.propertyId = this.property.id
        break
      default:
        break
    }
  }

  slickInit(e) {
    // console.log('slick initialized')
  }

  breakpoint(e) {
    // console.log('breakpoint')
  }

  afterChange(e) {
    // console.log('afterChange')
  }

  beforeChange(e) {
    // console.log('beforeChange')
  }
}
