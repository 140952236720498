import { Component, Input, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject, Observable } from 'rxjs'
import { Documents } from '../../models/property.model'
import { MediaPreviewComponent } from '../media-preview/media-preview.component'

@Component({
  selector: 'app-doc-list-card',
  templateUrl: './doc-list-card.component.html',
  styleUrls: ['./doc-list-card.component.sass'],
})
export class DocListCardComponent implements OnInit {
  @Input() docListTitle: string = ''
  @Input() documentListState: Observable<Documents[]>
  @Input() triggerDataReload = () => {}
  docList: Documents[] = []

  private readonly _docSignRedirectSource = new BehaviorSubject<boolean>(false)

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    if (this.documentListState != undefined) {
      this.documentListState.subscribe(data => {
        this.docList = data
      })
    }

    let isRedirectedSource = this._docSignRedirectSource
    let callback = this.triggerDataReload

    window.addEventListener('focus', function () {
      if (isRedirectedSource.getValue() == true) {
        // console.log('callback is called')
        callback()
        isRedirectedSource.next(false)
      }
    })
  }

  onClickDoc(redirectUrl: string) {
    // console.log('redirect to : ', redirectUrl)
    window.open(redirectUrl)
    this._docSignRedirectSource.next(true)
  }

  onMediaPreview(url: string) {
    // const mediaModal = this.modalService.open(MediaPreviewComponent, {
    //   size: 'md',
    // })
    // mediaModal.componentInstance.mediaType = 'image'
    // mediaModal.componentInstance.mediaPath = url
    window.open(url)
  }
}
