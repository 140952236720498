import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { SummaryCard } from '../../models/property.model'

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent implements OnInit {
  @Input() summaryState: Observable<SummaryCard>
  SummaryData: SummaryCard
  valueIncrease: boolean = false
  difference: Number = 0
  showDifference: boolean = false

  constructor() {}

  ngOnInit(): void {
    this.summaryState?.subscribe(data => {
      this.valueIncrease = false
      if (data) {
        this.SummaryData = data
        this.SummaryData.value = Number((Math.round(Number(this.SummaryData.value) * 100) / 100).toFixed(2))
        this.SummaryData.prev_month = Number((Math.round(Number(this.SummaryData.prev_month) * 100) / 100).toFixed(2))//
        if(this.SummaryData.value > this.SummaryData.prev_month) {
          this.valueIncrease = true
        }
        this.difference = Number((Math.round((this.SummaryData.value - this.SummaryData.prev_month) * 100) / 100).toFixed(2))
        let selectedMonthFilter = new Date(this.SummaryData.selectedYear, this.SummaryData.selectedMonth-1, 1)
        let date = new Date()
        let currentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        if(this.SummaryData.prev_month && selectedMonthFilter < currentMonth) {
          this.showDifference = true
        }
      }
    })
  }
}
