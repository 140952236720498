import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { ApexAxisChartSeries, ApexOptions, ChartComponent } from 'ng-apexcharts'
import { Observable } from 'rxjs'
import { PropertySummary } from 'src/app/modules/shared/models/dashboard.model'

@Component({
  selector: 'app-properties-chart',
  templateUrl: './properties-chart.component.html',
  styleUrls: ['./properties-chart.component.sass'],
})
export class PropertiesChartComponent implements OnInit {
  @Input() propertySumState: Observable<PropertySummary>
  @Input() isBppUser: boolean
  @ViewChild('PieChart') chart: ChartComponent
  chartOptions: Partial<ApexOptions>
  totalUnits: number = 0

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.chartOptions = {
      dataLabels: {
        style: {
          fontSize: '9px',
          fontWeight: '500',
        },
      },
      colors: [
        '#607D8B',
        '#A1E7DC',
        '#FF7236',
        '#3B2313',
        '#603813',
        '#8DC63F',
        '#07B6CD',
        '#E32D26',
        '#FFC113',
      ],
      series: [10, 10, 10, 10, 10, 10, 10, 10, 10],
      chart: {
        height: this.isBppUser ? 'auto' : 180,
        type: 'pie',
      },
      legend: {
        fontSize: '9px',
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
        position: 'right',
      },
      labels: [
        'Under Construction',
        'Key Collection',
        'Defect Check',
        'Renovation',
        'Short Stay Setup',
        'Live',
        'New Launch',
        'Sold',
        'Pending Approval',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    this.propertySumState.subscribe(data => {
      if (data != null) {
        this.totalUnits = data.total_units
        // let keys = Object.keys(data.property_owned_share)
        let values: ApexAxisChartSeries = Object.values(
          data.property_owned_share
        )
        // this.chartOptions.labels = keys
        this.chartOptions.series = values
      }
    })
  }

  navPropertyList() {
    this.router.navigate(['/my-properties'])
  }
}
