<div class="payment-gateway-container">
  <div class="content-container">
    <div class="row g-0">
      <div class="col-12 col-lg-6">
        <div class="content left-content">
          <h2>Total</h2>
          <div class="price-tag d-flex align-items-end">
            <small>RM</small>
            <div class="ms-2 display-4">{{ total }}</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="content right-content">
          <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
            <ngx-stripe-payment
              [clientSecret]="clientSecret"
              [element]="element">
            </ngx-stripe-payment>
          </ng-container>
          <div class="row mt-5">
            <div class="col text-center">
              <button
                type="button"
                class="btn btn-warning w-100"
                (click)="!isLoading && pay()"
                [ngClass]="{ 'btn-load': isLoading }">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="isLoading"></span>
                {{ isLoading ? "Waiting" : "Pay" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="payment-overlay" [ngClass]="{ 'is-loading': loading }">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
