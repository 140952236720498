<app-sidebar></app-sidebar>
<app-notification-bar></app-notification-bar>
<div class="main-body-container">
  <div class="main-body-wrapper">
    <div class="title-container">
      <div class="title-wrapper">
        <svg-icon
          class="d-block d-md-none me-3"
          src="app/assets/menu.svg"
          (click)="toggleSidebar()"></svg-icon>
        <span class="display-1">{{ pageState?.pageTitle }}</span>
      </div>
      <div class="bell-wrapper">
        <!-- <svg-icon src="app/assets/bell.svg"></svg-icon> -->
      </div>
    </div>
    <div class="floating-container">
      <app-mini-menu class="d-none d-md-block"></app-mini-menu>
    </div>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
