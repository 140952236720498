import { Component, OnInit } from '@angular/core'
import { SocialUser } from 'angularx-social-login'
import { Subscription } from 'rxjs'
import { AuthConstants } from 'src/app/modules/shared/enums/auth.enum'
import { AuthService } from 'src/app/modules/shared/service/auth.service'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { SocialLoginAuthService } from 'src/app/modules/shared/service/social-login-auth.service'
import { ProfileStore } from '../../store/profile.store'

export interface NetworkItem {
  icon: string
  label: string
  connectionStatus: number // 0 for not connected, 1 for connected; 2 for disconnected
  type: AuthConstants
}

@Component({
  selector: 'app-network-card',
  templateUrl: './network-card.component.html',
  styleUrls: ['./network-card.component.sass'],
})
export class NetworkCardComponent implements OnInit {
  connections: NetworkItem[] = [
    {
      icon: 'app/assets/facebook-icon.svg',
      label: 'Facebook',
      type: AuthConstants.facebook,
      connectionStatus: 0,
    },
    {
      icon: 'app/assets/google-icon.svg',
      label: 'Google',
      type: AuthConstants.google,
      connectionStatus: 1,
    },
    // {
    //   icon: 'app/assets/wechat-icon.svg',
    //   label: 'Wechat',
    //   connectionStatus: 2,
    // },
  ]
  subscriber: Subscription

  constructor(
    private profileStore: ProfileStore,
    private socialLoginAuthService: SocialLoginAuthService,
    private authService: AuthService,
    private toastService: CustomToastService
  ) {
    this.subscriber = this.profileStore.getState().subscribe(res => {
      this.connections[0].connectionStatus = res.data.social_media.facebook
        ? 1
        : 0
      this.connections[1].connectionStatus = res.data.social_media.google
        ? 1
        : 0
      // this.connections[2].connectionStatus = res.data.social_media.wechat
      //   ? 1
      //   : 0
    })
  }

  ngOnInit(): void {}

  toggleSocialConnect = (type: AuthConstants, connectionStatus: number) => {
    if (type == AuthConstants.facebook) {
      if (connectionStatus == 1) {
        this.socialLoginAuthService
          .signOut()
          .then(() => {})
          .catch(err => {
            // console.log(err)
          })
          .finally(() => {
            this.authService
              .socialDisconnect(AuthConstants.facebook)
              .then(() => {
                window.location.reload()
              })
              .catch(err => {
                if (err.error.token) {
                  this.toastService.show('Error', err.error.token[0])
                } else if (err.error.email) {
                  this.toastService.show('Error', err.error.email[0])
                }
              })
          })
      } else {
        this.socialLoginAuthService.signInWithFB().then((user: SocialUser) => {
          this.authService
            .socialConnect(AuthConstants.facebook, user.authToken)
            .then(() => {
              this.toastService.show('Success', 'Connected to Facebook')
              window.location.reload()
            })
            .catch(err => {
              if (err.error.token) {
                this.toastService.show('Error', err.error.token[0])
              } else if (err.error.email) {
                this.toastService.show('Error', err.error.email[0])
              }
            })
        })
      }
    } else if (type == AuthConstants.google) {
      if (connectionStatus == 1) {
        this.socialLoginAuthService
          .signOut()
          .then(() => {})
          .catch(err => {
            // console.log(err)
          })
          .finally(() => {
            this.authService
              .socialDisconnect(AuthConstants.google)
              .then(res => {
                window.location.reload()
              })
              .catch(err => {
                if (err.error.token) {
                  this.toastService.show('Error', err.error.token[0])
                } else if (err.error.email) {
                  this.toastService.show('Error', err.error.email[0])
                }
              })
          })
      } else {
        this.socialLoginAuthService
          .signInWithGoogle()
          .then((user: SocialUser) => {
            this.authService
              .socialConnect(AuthConstants.google, user.idToken)
              .then(() => {
                this.toastService.show('Success', 'Connected to Google')
                window.location.reload()
              })
              .catch(err => {
                if (err.error.token) {
                  this.toastService.show('Error', err.error.token[0])
                } else if (err.error.email) {
                  this.toastService.show('Error', err.error.email[0])
                }
              })
          })
      }
    }
  }
}
