<div class="pricing-container">
  <h2 class="title">{{ title }}</h2>
  <div class="content mt-3" *ngIf="dataList.length > 0">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
      <div
        ngxSlickItem
        class="slick-card"
        *ngFor="let package of dataList; let idx = index">
        <div class="pricing-card">
          <h2 class="package-name mt-3">
            {{ package.name }}
          </h2>
          <div class="package-price mt-3">
            <small class="currency">RM</small>
            <div class="value">{{ package.price }}</div>
          </div>
          <div class="separator"></div>
          <p class="package-desc mt-3" [innerHTML]="package.description"></p>
          <div class="package-button text-center mt-5">
            <button
              type="button"
              class="btn btn-outline-primary w-100"
              (click)="selectPackage(package)">
              Subscribe
            </button>
            <a
              [routerLink]=""
              (click)="onMediaPreview(package.avatar_url, 'image')"
              >View Image</a
            >
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
    <!-- <div class="dir-btn left" (click)="slickModal.slickPrev()">
      <img src="app/assets/arrow-left.svg" alt="" />
    </div>
    <div class="dir-btn right" (click)="slickModal.slickNext()">
      <img src="app/assets/arrow-right.svg" alt="" />
    </div> -->
  </div>
  <div class="content row mt-3 no-partners" *ngIf="dataList.length == 0">
    <div class="col text-center">
      <h4>Please select partners first</h4>
    </div>
  </div>
</div>
