<div class="network-card">
  <div class="title">Network</div>
  <div class="row">
    <div
      class="col-12 col-lg-6"
      *ngFor="let conn of connections; let idx = index">
      <div
        class="network-item"
        (click)="toggleSocialConnect(conn.type, conn.connectionStatus)">
        <div class="network-item-label">
          <img [src]="conn.icon" class="icon" />
          <div class="name">{{ conn.label }}</div>
        </div>
        <div [ngSwitch]="conn.connectionStatus">
          <div *ngSwitchCase="0" class="network-item-status">
            <span>Connect</span>
          </div>
          <div *ngSwitchCase="1" class="network-item-status">
            <span>Connected</span>
            <img src="app/assets/checkmark.svg" />
          </div>
          <div *ngSwitchCase="2" class="network-item-status">
            <span>Disconnected</span>
            <img src="app/assets/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
