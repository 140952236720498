import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Developer } from 'src/app/modules/shared/models/project.model'

@Component({
  selector: 'app-developer-card',
  templateUrl: './developer-card.component.html',
  styleUrls: ['./developer-card.component.sass'],
})
export class DeveloperCardComponent implements OnInit {
  @Input() developerDataState: Observable<Developer>
  developer: Developer

  constructor() {}

  ngOnInit(): void {
    this.developerDataState.subscribe(data => {
      this.developer = data
    })
  }
}
