import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-password-card',
  templateUrl: './password-card.component.html',
  styleUrls: ['./password-card.component.sass'],
})
export class PasswordCardComponent implements OnInit {
  passwordForm = new FormGroup({
    newpassword: new FormControl(''),
    confirmpassword: new FormControl(''),
  })

  constructor() {}

  ngOnInit(): void {}
}
