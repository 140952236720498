import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import {
  PackageDetail,
  RenoPartners,
} from 'src/app/modules/shared/models/services.model'
import { Utility } from 'src/app/modules/shared/utility/utility'

@Injectable()
export class HasServicesStore {
  initialState: HasServicesStoreModel = {
    partners: [],
    packages: [],
    projects: [],
    properties: [],
  }

  private readonly _stateSource = new BehaviorSubject<HasServicesStoreModel>(
    this.initialState
  )

  constructor(private utility: Utility) {}

  getState(): Observable<HasServicesStoreModel> {
    return this._stateSource.asObservable()
  }

  getStateValue(): HasServicesStoreModel {
    return this._stateSource.getValue()
  }

  setState = (data: HasServicesStoreModel) => {
    this._stateSource.next(data)
  }

  resetState() {
    this._stateSource.next(this.utility.deepCopy(this.initialState))
  }
}

export interface HasServicesStoreModel {
  partners: RenoPartners[]
  packages: PackageDetail[]
  projects: Option[]
  properties: Option[]
}
