import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import {
  OrderReq,
  PurchasedPackage,
  PurchasedTypeItem,
} from 'src/app/modules/shared/models/payment.model'
import {
  CartItem,
  UserCart,
} from 'src/app/modules/shared/state/user/user.model'
import { PromoModel } from './model/promo.model'

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.sass'],
})
export class OrderSummaryComponent implements OnInit {
  @Input() checkoutDataState: Observable<UserCart>
  @Input() promoCodeState: Observable<PromoModel>
  @Input() loadingState: Observable<boolean>
  @Input() orderFunc = (req: OrderReq) => {}
  @Input() getPromoFunc = (req: string): boolean => {
    return false
  }

  checkoutDatas: UserCart
  projects: CartItem[] = []
  discount: number
  subTotal: number
  totalPayment: number
  isLoading: boolean = false
  isPromoError: boolean = false
  itemPurchaseSummary: PurchasedTypeItem[] = []
  validPromoCode: string = ''

  orderSummaryForm = new FormGroup({
    promoCode: new FormControl(''),
  })

  constructor(private userStore: UserStore) {}

  ngOnInit(): void {
    this.loadingState.subscribe(val => {
      this.isLoading = val
    })

    this.promoCodeState.subscribe(val => {
      if (val) {
        this.isPromoError = val.promo_err

        if (!this.isPromoError) {
          this.validPromoCode = val.code
          if (val.type == Constants.PERCENTAGE) {
            this.discount = this.subTotal * (Number(val.amount) / 100)
          } else {
            this.discount = val.amount
          }
        } else {
          this.validPromoCode = ''
          this.discount = 0
        }

        this.totalPayment =
          this.subTotal - this.discount < 0 ? 0 : this.subTotal - this.discount
      }
    })

    this.checkoutDataState.subscribe(data => {
      this.checkoutDatas = data
      if (this.checkoutDatas) {
        this.projects = this.checkoutDatas.cart
        this.subTotal = 0
        this.discount = 0
        this.totalPayment = 0
        let cartItemCount: number = 0
        this.itemPurchaseSummary = []

        for (let proj of this.checkoutDatas.cart) {
          for (let unit of proj.unit) {
            for (let serv of unit.service) {
              let packageSummary: PurchasedPackage = {
                id: serv.package[0]?.id,
                quantity: 1,
              }
              if (serv.service_identifier == Constants.SHORTSTAY_MGMT_SERVICE) {
                this.itemPurchaseSummary.push({
                  service_id: serv.service_id,
                  project_id: proj.project_id,
                  property_id: unit.unit_type_id,
                  items: [packageSummary],
                })
              } else {
                this.itemPurchaseSummary.push({
                  service_id: serv.service_id,
                  project_id: proj.project_id,
                  unit_type_id: unit.unit_type_id,
                  items: [packageSummary],
                })
              }

              this.subTotal = this.subTotal + Number(serv.package[0]?.price)
              cartItemCount++
            }
          }
        }

        this.totalPayment =
          this.subTotal - this.discount < 0 ? 0 : this.subTotal - this.discount
        this.userStore.setCartItemCount(cartItemCount)
      }
    })
  }

  applyPromo() {
    this.getPromoFunc(this.orderSummaryForm.get('promoCode').value)
  }

  onPayButtonPressed() {
    let req: OrderReq = {
      promotion_code_id: this.validPromoCode,
      items: this.itemPurchaseSummary,
    }
    this.orderFunc(req)
  }
}
