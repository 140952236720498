<div *ngIf="!success">
  <h1 class="text-center mb-1">User Login</h1>
  <small class="text-center">The verification link is no longer valid</small>
  <div class="row">
    <div class="d-grid gap-2 col-12">
      <button type="submit" class="btn btn-primary btn-request-new-link">
        Request New Link
      </button>
    </div>
  </div>
</div>
