import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { BookingSummary } from 'src/app/modules/shared/models/property.model'
import { BookingSumModel } from './model/booking-summary.model'

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.sass'],
})
export class BookingSummaryComponent implements OnInit {
  @Input() bookingSummaryState: Observable<BookingSumModel>
  bookingSummary: BookingSummary
  isSold: boolean

  totalBookingSummary: any = 0

  constructor() {}

  ngOnInit(): void {
    this.bookingSummaryState.subscribe(data => {
      this.bookingSummary = data.bookingSummary
      this.isSold = data.isSold
    })
  }
}
