<div class="key-collect-container">
  <span class="key-collect-title">Delegate Key Collection</span>
  <div *ngIf="!formSubmitted" class="fields-container">
    <form
      [formGroup]="keyCollectionForm"
      [ngClass]="{ 'has-errors': keyCollectionForm.invalid && submitted }">
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <custom-input
            [formGroup]="keyCollectionForm"
            id="collector_subject"
            name="collector_subject"
            label="Who is coming?"
            type="select"
            [valueList]="keyCollectionAttendees"></custom-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <custom-input
            [formGroup]="keyCollectionForm"
            id="collection_date"
            name="collection_date"
            label="Please Select Date"
            type="date"></custom-input>
        </div>
        <div *ngIf="onBehalf" class="col-12 col-md-6 mb-3">
          <custom-input
            [formGroup]="keyCollectionForm"
            id="name"
            name="name"
            label="Name"
            type="text"></custom-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <custom-input
            [formGroup]="keyCollectionForm"
            id="collection_time"
            name="collection_time"
            label="Please Select Time"
            type="timepicker"></custom-input>
        </div>
        <div *ngIf="onBehalf" class="col-12 col-md-6 mb-3">
          <custom-input
            [formGroup]="keyCollectionForm"
            id="contact_number"
            name="contact_number"
            label="Contact Number"
            type="text"></custom-input>
        </div>
      </div>
    </form>
    <div class="btn-container">
      <button
        type="button"
        class="btn schedule-btn"
        (click)="!isLoading && onSchedule()"
        [ngClass]="{ 'btn-load': isLoading }">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading"></span>
        {{ isLoading ? "Waiting" : "Schedule" }}
      </button>
    </div>
  </div>
  <div *ngIf="formSubmitted" class="fields-container">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <div
          class="collection-info"
          *ngIf="!isCollectorAgent; else collectorAgentMsg">
          <span class="collection-details"
            >Your booking key collection at
            {{ keyCollectionData.keyCollectionDateTime }},{{
              collection_time
            }}</span
          >
        </div>
        <ng-template #collectorAgentMsg>
          <div class="collection-info">
            <span class="collection-details"
              >Congrats! Your already purchase Key Collection Services. Please
              wait our agent to confirm with you.
            </span>
          </div>
        </ng-template>
        <div class="mt-4" *ngIf="!isCollectorAgent">
          <span class="key-collect-subtitle">Key Collection Address</span>
        </div>
        <div class="address-container mt-2" *ngIf="!isCollectorAgent">
          <span
            >{{ keyCollectionData.project_name }},
            {{ keyCollectionData.unit_number }}</span
          >
          <span>{{ keyCollectionData.project_address_1 }}</span>
          <span>{{ keyCollectionData.project_address_2 }}</span>
          <span class="mt-2">{{ keyCollectionData.project_phone_number }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
