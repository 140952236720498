<div class="mobile-filter-container">
  <div class="mobile-filter-button" (click)="toggleFilter()">
    <svg-icon src="app/assets/filter-alt-icon.svg"></svg-icon>
  </div>
  <div class="mobile-filter-content bg-white" [ngClass]="{ open: filterOpen }">
    <div class="d-flex flex-column h-100 justify-content-between">
      <div class="d-flex flex-column">
        <div
          class="filter-title d-flex align-items-center"
          (click)="toggleFilter()">
          <svg-icon src="app/assets/close-round.svg"></svg-icon>
          <span class="display-1 text-primary mt-1 ms-3">Close</span>
        </div>
        <div class="filter-container px-3">
          <div class="row gy-3">
            <div class="col-12">
              <h2>Property Type</h2>
              <div ngbDropdown>
                <div
                  class="dropdown-select d-flex align-items-center flex-wrap pe-3"
                  id="dropdownProjectType"
                  ngbDropdownToggle>
                  <div
                    class="badge bg-primary me-2 mb-2 rounded-pill"
                    *ngFor="let activeProjectType of filterConfig.projectType">
                    {{ activeProjectType }}
                    <div class="close">x</div>
                  </div>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownProjectType">
                  <div class="filter-list">
                    <div
                      class="form-check"
                      *ngFor="
                        let projectType of projectTypeList;
                        let i = index
                      ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="projectType{{ i }}"
                        [attr.checked]="
                          filterConfig.projectType.includes(projectType.value)
                            ? 'true'
                            : null
                        "
                        (change)="
                          toggleCheckbox('projectType', projectType.value)
                        " />
                      <label
                        class="form-check-label filter body-2"
                        for="projectType{{ i }}">
                        {{ projectType.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>Property Status</h2>
              <div ngbDropdown>
                <div
                  class="dropdown-select d-flex align-items-center flex-wrap pe-3"
                  id="dropdownProjectStatus"
                  ngbDropdownToggle>
                  <div
                    class="badge bg-primary me-2 mb-2 rounded-pill"
                    *ngFor="
                      let activeProjectStatus of filterConfig.projectStatus
                    ">
                    {{ activeProjectStatus }}
                    <div class="close">x</div>
                  </div>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownProjectStatus">
                  <div class="filter-list">
                    <div
                      class="form-check"
                      *ngFor="
                        let projectStatus of projectStatusList;
                        let i = index
                      ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="projectStatus{{ i }}"
                        [attr.checked]="
                          filterConfig.projectStatus.includes(
                            projectStatus.value
                          )
                            ? 'true'
                            : null
                        "
                        (change)="
                          toggleCheckbox('projectStatus', projectStatus.value)
                        " />
                      <label
                        class="form-check-label filter body-2"
                        for="projectStatus{{ i }}">
                        {{ projectStatus.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>Location</h2>
              <div ngbDropdown>
                <div
                  class="dropdown-select d-flex align-items-center flex-wrap pe-3"
                  id="dropdownState"
                  ngbDropdownToggle>
                  <div
                    class="badge bg-primary me-2 mb-2 rounded-pill"
                    *ngFor="let activeState of filterConfig.state">
                    {{ activeState }}
                    <div class="close">x</div>
                  </div>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownProjectStatus">
                  <div class="filter-list">
                    <div
                      class="form-check"
                      *ngFor="let state of stateList; let i = index">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="state{{ i }}"
                        [attr.checked]="
                          filterConfig.state.includes(state.value)
                            ? 'true'
                            : null
                        "
                        (change)="toggleCheckbox('state', state.value)" />
                      <label
                        class="form-check-label filter body-2"
                        for="state{{ i }}">
                        {{ state.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2>Tenure</h2>
              <div ngbDropdown>
                <div
                  class="dropdown-select d-flex align-items-center flex-wrap pe-3"
                  id="dropdownTenancyType"
                  ngbDropdownToggle>
                  <div
                    class="badge bg-primary me-2 mb-2 rounded-pill"
                    *ngFor="let activeTenancyType of filterConfig.tenancyType">
                    {{ activeTenancyType }}
                    <div class="close">x</div>
                  </div>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownTenancyType">
                  <div class="filter-list">
                    <div
                      class="form-check"
                      *ngFor="
                        let tenancyType of tenancyTypeList;
                        let i = index
                      ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="tenancyType{{ i }}"
                        [attr.checked]="
                          filterConfig.tenancyType.includes(tenancyType.value)
                            ? 'true'
                            : null
                        "
                        (change)="
                          toggleCheckbox('tenancyType', tenancyType.value)
                        " />
                      <label
                        class="form-check-label filter body-2"
                        for="tenancyType{{ i }}">
                        {{ tenancyType.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-button p-3">
        <button
          type="button"
          class="btn btn-primary w-100"
          (click)="applyFilter()">
          Search
        </button>
      </div>
    </div>
  </div>
</div>
<div class="list-btns flex-column flex-lg-row mb-2">
  <button
    class="btn btn-light"
    *ngIf="showAddCustomProjectCard"
    (click)="addCustomProject()">
    + Add Custom Project
  </button>
  <div class="list-filter" (click)="toggleFilter()">
    <div class="filter-btn">
      <span class="filter-btn-name">Property Type</span>
      <span class="filter-btn-selected">{{
        activeFilter.projectType.length > 0
          ? activeFilter.projectType.length + " Filter(s)"
          : "All"
      }}</span>
    </div>
    <div class="separator"></div>
    <div class="filter-btn">
      <span class="filter-btn-name">Property Status</span>
      <span class="filter-btn-selected">{{
        activeFilter.projectStatus.length > 0
          ? activeFilter.projectStatus.length + " Filter(s)"
          : "All"
      }}</span>
    </div>
    <div class="separator"></div>
    <div class="filter-btn">
      <span class="filter-btn-name">Location</span>
      <span class="filter-btn-selected">{{
        activeFilter.state.length > 0
          ? activeFilter.state.length + " Filter(s)"
          : "All"
      }}</span>
    </div>
    <div class="separator"></div>
    <div class="filter-btn me-2">
      <span class="filter-btn-name">Tenure</span>
      <span class="filter-btn-selected">{{
        activeFilter.tenancyType.length > 0
          ? activeFilter.tenancyType.length + " Filter(s)"
          : "All"
      }}</span>
    </div>
    <img src="app/assets/filter-icon.svg" class="filter-icon" />
  </div>
  <div class="list-filter-mega-menu" [ngClass]="{ open: filterOpen }">
    <div class="menu-list">
      <div class="menu">
        <span class="body-1">Property Type</span>
        <div class="filter-list">
          <div
            class="form-check"
            *ngFor="let projectType of projectTypeList; let i = index">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="projectType{{ i }}"
              [attr.checked]="
                filterConfig.projectType.includes(projectType.value)
                  ? 'true'
                  : null
              "
              (change)="toggleCheckbox('projectType', projectType.value)" />
            <label
              class="form-check-label filter body-2"
              for="projectType{{ i }}">
              {{ projectType.value }}
            </label>
          </div>
        </div>
      </div>
      <div class="menu">
        <span class="body-1">Property Status</span>
        <div class="filter-list">
          <div
            class="form-check"
            *ngFor="let projectStatus of projectStatusList; let i = index">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="projectStatus{{ i }}"
              [attr.checked]="
                filterConfig.projectStatus.includes(projectStatus.value)
                  ? 'true'
                  : null
              "
              (change)="toggleCheckbox('projectStatus', projectStatus.value)" />
            <label
              class="form-check-label filter body-2"
              for="projectStatus{{ i }}">
              {{ projectStatus.value }}
            </label>
          </div>
        </div>
      </div>
      <div class="menu">
        <span class="body-1">Location</span>
        <div class="filter-list">
          <div
            class="form-check"
            *ngFor="let state of stateList; let i = index">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="state{{ i }}"
              [attr.checked]="
                filterConfig.state.includes(state.value) ? 'true' : null
              "
              (change)="toggleCheckbox('state', state.value)" />
            <label class="form-check-label filter body-2" for="state{{ i }}">
              {{ state.value }}
            </label>
          </div>
        </div>
      </div>
      <div class="menu">
        <span class="body-1">Tenure</span>
        <div class="filter-list">
          <div
            class="form-check"
            *ngFor="let tenancyType of tenancyTypeList; let i = index">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="tenancyType{{ i }}"
              [attr.checked]="
                filterConfig.tenancyType.includes(tenancyType.value)
                  ? 'true'
                  : null
              "
              (change)="toggleCheckbox('tenancyType', tenancyType.value)" />
            <label
              class="form-check-label filter body-2"
              for="tenancyType{{ i }}">
              {{ tenancyType.value }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-button pt-2 pb-1 text-right">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="applyFilter()">
        Apply Filter
      </button>
    </div>
  </div>
</div>
<div class="row own-property-grid grid gx-3 gx-lg-4">
  <div
    *ngIf="
      !showAddCustomProjectCard && dataSource?.length <= 0;
      else defaultProjectview
    ">
    <div class="empty-container">
      <span>Your search not found!</span>
    </div>
  </div>
  <ng-template #defaultProjectview>
    <div class="col-6 col-xl-3 mt-1 mb-3" *ngFor="let project of dataSource">
      <app-project-card
        [onCardClick]="onCardClick"
        [dataSource]="project"></app-project-card>
    </div>
    <div class="col-6 col-xl-3 mt-1 mb-3" *ngIf="showAddCustomProjectCard">
      <app-add-custom-project-card
        (click)="addCustomProject()"></app-add-custom-project-card>
    </div>
  </ng-template>
</div>
