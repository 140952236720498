import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { Project } from 'src/app/modules/shared/models/project.model'
import {
  Property,
  UnitType,
} from 'src/app/modules/shared/models/property.model'
import {
  PackageDetail,
  ShortstayMgmtPackageRes,
  UnitTypesRes,
} from 'src/app/modules/shared/models/services.model'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import { ServicesService } from 'src/app/modules/shared/service/services.service'
import {
  CartItem,
  ServiceItem,
  UnitItem,
  UserCart,
} from 'src/app/modules/shared/state/user/user.model'
import { SecuredStore } from '../../../store/secured.store'
import {
  HasServicesStore,
  HasServicesStoreModel,
} from '../store/has-services.store'

@Component({
  selector: 'app-shortstay-management',
  templateUrl: './shortstay-management.component.html',
  styleUrls: ['./shortstay-management.component.sass'],
})
export class ShortstayManagementComponent implements OnInit {
  // observables
  private readonly _formSubmitSource = new BehaviorSubject<boolean>(false)
  readonly formSubmitState = this._formSubmitSource.asObservable()

  // vars
  state: HasServicesStoreModel
  shoppingCarts: UserCart[] = []
  selectedPackage: PackageDetail
  servicesId: string
  serviceIdentifier: string
  unitItem: UnitItem
  packageItem: CartItem = {
    project_id: '',
    project_name: '',
    unit: [],
  }

  constructor(
    private securedStore: SecuredStore,
    private store: HasServicesStore,
    private userStore: UserStore,
    private route: ActivatedRoute,
    private router: Router,
    private servicesService: ServicesService,
    private projectService: ProjectsService,
    private propertyService: PropertiesService
  ) {
    this.securedStore.updatePageTitle('Short Stay Management Services')
  }

  ngOnInit(): void {
    this.store.resetState()
    this.shoppingCarts = this.userStore.getCart()
    const routeParams = this.route.snapshot.paramMap
    this.servicesId = routeParams.get('id')
    this.serviceIdentifier = routeParams.get('identifier')
    this.getProjects()
    this.getPackages()
  }

  getProjects = () => {
    this.projectService.getProjects().then((resp: Project[]) => {
      this.state = this.store.getStateValue()
      this.state.projects = resp.map((project: Project) => {
        let generatedOption: Option = {
          id: project.id,
          label: project.name,
          value: project.id,
        }
        return generatedOption
      })
      this.store.setState(this.state)
    })
  }

  getUnitTypes = (projectId: string) => {
    this.propertyService
      .getPropertiesByProjectId(projectId)
      .then((resp: Property[]) => {
        let state = this.store.getStateValue()
        state.properties = resp.map((unit: Property) => {
          let generatedOption: Option = {
            id: unit.id,
            label: unit.unit_number,
            value: unit.id,
          }
          return generatedOption
        })
        this.store.setState(state)
      })
  }

  getPackages = () => {
    this.servicesService.getShortstayManagementServices().then((res: any) => {
      let packages: ShortstayMgmtPackageRes[] = res.data
      this.state = this.store.getStateValue()
      this.state.packages = packages.map((pkg: ShortstayMgmtPackageRes) => {
        let generatedOption: PackageDetail = {
          id: pkg.id,
          name: pkg.title,
          price: pkg.price,
          description: pkg.description,
          avatar_url: pkg.avatar_url,
        }
        return generatedOption
      })
      this.store.setState(this.state)
    })
  }

  onSelectPackage = (selected: PackageDetail) => {
    this.selectedPackage = selected
    this._formSubmitSource?.next(true)
  }

  onCheckFormEvent = (isvalid: boolean) => {
    if (isvalid) {
      this.onSubmitPackage()
    }
  }

  setFilter = (
    project_id: string,
    project_name: string,
    unit_type_id: string,
    unit_type: string
  ) => {
    this.packageItem.project_id = project_id
    this.packageItem.project_name = project_name
    this.unitItem = {
      unit_type: unit_type,
      unit_type_id: unit_type_id,
      service: [],
    }
    this.packageItem.unit = [this.unitItem]
  }

  onSubmitPackage = () => {
    // prepare package object
    let packageDetail: PackageDetail = {
      id: this.selectedPackage.id,
      name: this.selectedPackage.name,
      price: this.selectedPackage.price,
      description: this.selectedPackage.description,
      avatar_url: this.selectedPackage.avatar_url,
    }
    let serviceItem: ServiceItem = {
      service_id: this.servicesId,
      service_name: 'Short Stay Management',
      package: [packageDetail],
      service_identifier: this.serviceIdentifier,
    }
    this.unitItem.service = [serviceItem]

    // new user cart
    let user = this.userStore.getUser().user
    let packages: CartItem[] = [this.packageItem]
    let newCart: UserCart = {
      user: user,
      item_count: 1,
      cart: packages,
    }

    this.userStore.addToCart(newCart, this.servicesId)

    this.router.navigate(['/checkout'])
  }
}
