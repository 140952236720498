<div class="overview-container card">
  <div class="row">
    <div class="col-12 col-lg-5">
      <div class="title">{{ graphYear }} Earnings Overview</div>
      <!-- <div class="desc">Show overview {{ graphYear }}</div> -->
      <div class="row">
        <div class="col-6 col-lg-12">
          <div class="sub-title mt-3">Average Monthly Occupancy Rate</div>
          <div class="value-sm">{{ avgMonthlyOccRate | number: "1.2-2" }} %</div>
        </div>
        <div class="col-6 col-lg-12">
          <div class="sub-title mt-3">Average Monthly Earnings</div>
          <div class="value-lg">
            {{ avgTotalEarnings | currency: "MYR ":"symbol":"1.0-2" }}
          </div>
          <!-- <div class="footer mt-1">
            Past Month: {{ pastMonthVal | currency: "MYR ":"symbol":"1.0-2" }}
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7">
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [colors]="chartOptions.colors"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [xaxis]="chartOptions.xaxis"
          [stroke]="chartOptions.stroke"
          [grid]="chartOptions.grid"></apx-chart>
      </div>
    </div>
  </div>
</div>
