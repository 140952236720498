import { Component, Inject, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Protection } from 'src/app/modules/shared/models/dashboard.model'
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'app-my-protection',
  templateUrl: './my-protection.component.html',
  styleUrls: ['./my-protection.component.sass'],
})
export class MyProtectionComponent implements OnInit {
  @Input() protectionState: Observable<Protection>
  unprotected: number = 0
  protected: number = 0

  window: Window

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView
  }

  ngOnInit(): void {
    this.protectionState.subscribe(data => {
      if (data) {
        this.unprotected = data.unprotected
        this.protected = data.protected
      }
    })
  }

  // openChatra(){
  //   this.window.Chatra('open', true)
  // }
}
