import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ProfileStore } from '../../store/profile.store'

export interface NotifModel {
  label: string
  isActive: boolean
}

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.sass'],
})
export class NotificationCardComponent implements OnInit {
  notifList: NotifModel[] = [
    {
      label: 'Updates',
      isActive: false,
    },
    {
      label: 'Financial Reporting',
      isActive: false,
    },
    {
      label: 'Investment Opportunity',
      isActive: false,
    },
    {
      label: 'Marketing Newsletter',
      isActive: false,
    },
  ]
  subscriber: Subscription

  constructor(private profileStore: ProfileStore) {}

  ngOnInit(): void {
    this.subscriber = this.profileStore.getState().subscribe(res => {
      this.notifList[0].isActive = res.data.profile.notification_updates
      this.notifList[1].isActive =
        res.data.profile.notification_financial_report
      this.notifList[2].isActive =
        res.data.profile.notification_investment_opportunity
      this.notifList[3].isActive =
        res.data.profile.notification_marketing_newsletter
    })
  }

  onToggle(idx: number) {
    this.notifList[idx].isActive = !this.notifList[idx].isActive
  }
}
