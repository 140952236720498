import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { RecentAct } from 'src/app/modules/shared/models/dashboard.model'

@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.sass'],
})
export class RecentActivityComponent implements OnInit {
  @Input() recentActState: Observable<RecentAct[]>
  activities: RecentAct[] = []

  constructor() {}

  ngOnInit(): void {
    this.recentActState.subscribe(data => {
      this.activities = data
    })
  }
}
