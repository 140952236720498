import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.sass'],
})
export class NewPasswordComponent implements OnInit {
  token: string
  email: string

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParam => {
      if (queryParam.get('token')) {
        this.token = queryParam.get('token')
      }
      if (queryParam.get('email')) {
        this.email = queryParam.get('email')
      }
    })
  }
}
