import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { MediaPreviewComponent } from 'src/app/modules/shared/component/media-preview/media-preview.component'
import {
  AdditionalInfo,
  Unit,
} from 'src/app/modules/shared/models/project.model'
import { SalesCardModel } from './model/sales-card.model'

@Component({
  selector: 'app-sale-price-card',
  templateUrl: './sale-price-card.component.html',
  styleUrls: ['./sale-price-card.component.sass'],
})
export class SalePriceCardComponent implements OnInit {
  @Input() salesDataState: Observable<SalesCardModel>
  salesCardData: SalesCardModel
  matterportUrl: SafeResourceUrl = ''

  constructor(
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.salesDataState.subscribe(data => {
      this.salesCardData = data
      if (this.salesCardData?.display_suite_link) {
        this.matterportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.salesCardData.display_suite_link
        )
        // console.log('matterport : ', this.matterportUrl)
      }
    })
  }

  onMediaPreview(url: string) {
    const mediaModal = this.modalService.open(MediaPreviewComponent, {
      size: 'lg',
    })
    mediaModal.componentInstance.mediaType = 'image'
    mediaModal.componentInstance.mediaPath = url
  }
}
