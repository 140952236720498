import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'

import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private http: HttpClient) {}

  async getProjects(filterConfigStr?: string) {
    return new Promise((resolve, reject) => {
      let apiUrl = new ApiFactory(Endpoints.GET_PROJECTS)
        .including()
        .withImages()
        .toString()

      if (filterConfigStr) {
        apiUrl = `${apiUrl}${filterConfigStr}`
      }

      this.http.get<any>(`${environment.baseUrl}${apiUrl}`).subscribe({
        next: data => {
          resolve(data.data)
        },
        error: err => {
          reject(err)
        },
      })
    })
  }

  async getProjectById(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.GET_PROJECTS + '/' + id
          )
            .including()
            .withDeveloper()
            .withAdditionalInformations()
            .withRenovationPartners()
            .withUnitTypes()
            .withHosts()
            .withAgents()
            .withImages()
            .toString()}`
        )
        .subscribe({
          next: data => {
            resolve(data.data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async addToFavorites(projectId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.ADD_FAVORITE)}`,
          { project_id: projectId }
        )
        .subscribe({
          next: data => {
            resolve(data.data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
