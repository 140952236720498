export type Option = {
  id: string
  label: string
  value: string
}

const countryList: Option[] = [
  {
    id: 'Malaysia',
    label: 'Malaysia',
    value: 'Malaysia',
  },
]

const stateList: Option[] = [
  {
    id: 'Johor',
    label: 'Johor',
    value: 'Johor',
  },
  {
    id: 'Kedah',
    label: 'Kedah',
    value: 'Kedah',
  },
  {
    id: 'Kelantan',
    label: 'Kelantan',
    value: 'Kelantan',
  },
  {
    id: 'Kuala Lumpur',
    label: 'Kuala Lumpur',
    value: 'Kuala Lumpur',
  },
  {
    id: 'Labuan',
    label: 'Labuan',
    value: 'Labuan',
  },
  {
    id: 'Melaka',
    label: 'Melaka',
    value: 'Melaka',
  },
  {
    id: 'Negeri Sembilan',
    label: 'Negeri Sembilan',
    value: 'Negeri Sembilan',
  },
  {
    id: 'Pahang',
    label: 'Pahang',
    value: 'Pahang',
  },
  {
    id: 'Penang',
    label: 'Penang',
    value: 'Penang',
  },
  {
    id: 'Perak',
    label: 'Perak',
    value: 'Perak',
  },
  {
    id: 'Perlis',
    label: 'Perlis',
    value: 'Perlis',
  },
  {
    id: 'Putrajaya',
    label: 'Putrajaya',
    value: 'Putrajaya',
  },
  {
    id: 'Sabah',
    label: 'Sabah',
    value: 'Sabah',
  },
  {
    id: 'Sarawak',
    label: 'Sarawak',
    value: 'Sarawak',
  },
  {
    id: 'Selangor',
    label: 'Selangor',
    value: 'Selangor',
  },
  {
    id: 'Terengganu',
    label: 'Terengganu',
    value: 'Terengganu',
  },
]

const projectTypeList: Option[] = [
  {
    id: 'Apartment',
    label: 'Apartment',
    value: 'Apartment',
  },
  {
    id: 'Condominium',
    label: 'Condominium',
    value: 'Condominium',
  },
  {
    id: 'Serviced Apartment',
    label: 'Serviced Apartment',
    value: 'Serviced Apartment',
  },
  {
    id: 'Serviced Residence',
    label: 'Serviced Residence',
    value: 'Serviced Residence',
  },
  {
    id: 'Serviced Suites',
    label: 'Serviced Suites',
    value: 'Serviced Suites',
  },
  {
    id: 'Penthouse',
    label: 'Penthouse',
    value: 'Penthouse',
  },
  {
    id: 'Loft',
    label: 'Loft',
    value: 'Loft',
  },
  {
    id: 'Duplex',
    label: 'Duplex',
    value: 'Duplex',
  },
  {
    id: 'SoHo',
    label: 'SoHo',
    value: 'SoHo',
  },
  {
    id: 'SoFo',
    label: 'SoFo',
    value: 'SoFo',
  },
  {
    id: 'SoVo',
    label: 'SoVo',
    value: 'SoVo',
  },
  {
    id: 'Vos',
    label: 'Vos',
    value: 'Vos',
  },
  {
    id: 'Bungalow',
    label: 'Bungalow',
    value: 'Bungalow',
  },
  {
    id: 'Villa',
    label: 'Villa',
    value: 'Villa',
  },
  {
    id: 'Terrace - Single Storey',
    label: 'Terrace - Single Storey',
    value: 'Terrace - Single Storey',
  },
  {
    id: 'Terrace - Double Storey',
    label: 'Terrace - Double Storey',
    value: 'Terrace - Double Storey',
  },
  {
    id: 'Terrace - Three Storey',
    label: 'Terrace - Three Storey',
    value: 'Terrace - Three Storey',
  },
  {
    id: 'Townhouse',
    label: 'Townhouse',
    value: 'Townhouse',
  },
  {
    id: 'Semi - D',
    label: 'Semi - D',
    value: 'Semi - D',
  },
  {
    id: 'Other',
    label: 'Other',
    value: 'Other',
  },
]

const landTypeList: Option[] = [
  {
    id: 'Freehold',
    label: 'Freehold',
    value: 'Freehold',
  },
  {
    id: 'Leasehold',
    label: 'Leasehold',
    value: 'Leasehold',
  },
  {
    id: 'Malay Reserve',
    label: 'Malay Reserve',
    value: 'Malay Reserve',
  },
]

const tenancyTypeList: Option[] = [
  {
    id: 'Hybrid',
    label: 'Hybrid',
    value: 'Hybrid',
  },
  {
    id: 'Short Stay',
    label: 'Short Stay',
    value: 'Short Stay',
  },
  {
    id: 'Long Stay',
    label: 'Long Stay',
    value: 'Long Stay',
  },
  {
    id: 'Own Stay',
    label: 'Own Stay',
    value: 'Own Stay',
  },
]

const projectStatusList: Option[] = [
  {
    id: 'New Launch',
    label: 'New Launch',
    value: 'New Launch',
  },
  {
    id: 'Under Construction',
    label: 'Under Construction',
    value: 'Under Construction',
  },
  {
    id: 'Vacant Possession / Key Collection',
    label: 'Vacant Possession / Key Collection',
    value: 'Vacant Possession / Key Collection',
  },
  {
    id: 'Defect Check',
    label: 'Defect Check',
    value: 'Defect Check',
  },
  {
    id: 'Renovation',
    label: 'Renovation',
    value: 'Renovation',
  },
  {
    id: 'Short Stay Setup',
    label: 'Short Stay Setup',
    value: 'Short Stay Setup',
  },
  {
    id: 'Live',
    label: 'Live',
    value: 'Live',
  },
  {
    id: 'Lease for Sale',
    label: 'Lease for Sale',
    value: 'Lease for Sale',
  },
  {
    id: 'Remove from Listing',
    label: 'Remove from Listing',
    value: 'Remove from Listing',
  },
]

export const getCountryList = () => {
  return countryList
}

export const getStateList = () => {
  return stateList
}

export const getProjectTypeList = () => {
  return projectTypeList
}

export const getLandTypeList = () => {
  return landTypeList
}

export const getTenancyTypeList = () => {
  return tenancyTypeList
}

export const getProjectStatusList = () => {
  return projectStatusList
}

export const AggresivenessList: Option[] = [
  {
    id: 'Conservative',
    label: 'Conservative',
    value: 'Conservative',
  },
  {
    id: 'Active',
    label: 'Active',
    value: 'Active',
  },
  {
    id: 'Aggressive',
    label: 'Aggressive',
    value: 'Aggressive',
  },
]

export const GoalSettingList: Option[] = [
  {
    id: 'Retirement Planning',
    label: 'Retirement Planning',
    value: 'Retirement Planning',
  },
  {
    id: 'Passive income generation',
    label: 'Passive income generation',
    value: 'Passive income generation',
  },
  {
    id: 'Income Replacement',
    label: 'Income Replacement',
    value: 'Income Replacement',
  },
  {
    id: 'Family fund planning',
    label: 'Family fund planning',
    value: 'Family fund planning',
  },
]

export const InvestmentTypeList: Option[] = [
  {
    id: 'Flip',
    label: 'Flip',
    value: 'Flip',
  },
  {
    id: 'Long term rental income',
    label: 'Long term rental income',
    value: 'Long term rental income',
  },
  {
    id: 'Short stay rental income',
    label: 'Short stay rental income',
    value: 'Short stay rental income',
  },
  {
    id: 'Long term value appreciation',
    label: 'Long term value appreciation',
    value: 'Long term value appreciation',
  },
]

export const KeyDelegationAttendees: Option[] = [
  {
    id: 'Self Collect',
    label: 'Self Collect',
    value: 'Self Collect',
  },
  {
    id: 'On Behalf',
    label: 'On Behalf',
    value: 'On Behalf',
  },
  {
    id: 'Appoint HAS Personnel',
    label: 'Appoint HAS Personnel',
    value: 'Appoint HAS Personnel',
  },
]

export const DefectArea: Option[] = [
  {
    id: 'Doors',
    label: 'Doors',
    value: 'Doors',
  },
  {
    id: 'Windows',
    label: 'Windows',
    value: 'Windows',
  },
  {
    id: 'Wall',
    label: 'Wall',
    value: 'Wall',
  },
  {
    id: 'Ceiling',
    label: 'Ceiling',
    value: 'Ceiling',
  },
  {
    id: 'Floor',
    label: 'Floor',
    value: 'Floor',
  },
  {
    id: 'Electrical',
    label: 'Electrical',
    value: 'Electrical',
  },
  {
    id: 'Furnishing',
    label: 'Furnishing',
    value: 'Furnishing',
  },
  {
    id: 'Plumbing',
    label: 'Plumbing',
    value: 'Plumbing',
  },
  {
    id: 'Others',
    label: 'Others',
    value: 'Others',
  },
]
