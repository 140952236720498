<div class="investment-profile-card">
  <div class="title">Investment Profile</div>
  <form [formGroup]="investmentFormgroup">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="investmentFormgroup"
          id="aggresiveness"
          name="aggresiveness"
          label="Aggresiveness"
          type="select"
          [valueList]="aggresivenessList"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="investmentFormgroup"
          id="goals_setting"
          name="goals_setting"
          label="Goals Setting"
          type="select"
          [valueList]="goalSettingList"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="investmentFormgroup"
          id="invest_type"
          name="invest_type"
          label="Type of Investment"
          type="select"
          [valueList]="investmentTypeList"></custom-input>
      </div>
    </div>
  </form>
</div>
