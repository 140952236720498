import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-custom-basic-card',
  templateUrl: './custom-basic-card.component.html',
  styleUrls: ['./custom-basic-card.component.sass'],
})
export class CustomBasicCardComponent implements OnInit {
  @Input() onClick = () => {}
  constructor() {}

  ngOnInit(): void {}

  onClickBtn() {
    this.onClick()
  }
}
