import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import {
  craftLocation,
  Project,
  Unit,
} from 'src/app/modules/shared/models/project.model'
import {
  BookingSummary,
  Documents,
  HistoryModel,
  Host,
  HostListRes,
  MarketTrend,
  OccupancyRate,
  PropertyCategoriesRes,
  PropertyDetail,
  PropertyDetailResponse,
  TotalBookedNights,
  NominalCompare,
  SummaryCard,
} from 'src/app/modules/shared/models/property.model'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import {
  PropertyOverviewModel,
  SubscriptionStatusModel,
} from '../../component/property-overview/model/property-overview.model'
import { SecuredStore } from '../../../store/secured.store'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { DefectCheckModel } from '../../component/lodge-defect/model/lodge-defect.model'
import { KeyCollectModel } from '../../component/key-collect/model/key-collect.model'
import { ShortStayModel } from '../../component/shortstay-card/model/shortstay.model'
import { TimelineModel } from '../../component/timeline-card/model/timeline-card.model'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { BookingSumModel } from '../../component/booking-summary/model/booking-summary.model'
import { ExpenseModel } from '../../component/expenses/model/expense.model'
import { InsuranceProtectionModel } from '../../component/insurance-protection/model/insurance-protection.model'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { DashboardService } from 'src/app/modules/shared/service/dashboard.service'
import { populateMarketTrend } from 'src/app/modules/shared/factory/demo.factory'
import demoData from 'src/app/modules/shared/factory/demoData'

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.sass'],
})
export class PropertyDetailComponent implements OnInit {
  propertyOverview: PropertyOverviewModel
  property: PropertyDetail
  isShowDefectcheck: boolean = false
  statusDefectCheck = Constants.STATUS_DEFECT_CHECK
  statusKeyCollection = Constants.STATUS_KEY_COLLECTION
  statusLive = Constants.STATUS_LIVE
  statusNewLaunch = Constants.STATUS_NEW_LAUNCH
  statusPendingApproval = Constants.STATUS_PENDING_APPROVAL
  statusRenovation = Constants.STATUS_RENOVATION
  statusShortStaySetup = Constants.STATUS_SHORT_STAY_SETUP
  statusSold = Constants.STATUS_SOLD
  statusUnderConstruction = Constants.STATUS_UNDER_CONSTRUCTION
  statusSubExpired = Constants.STATUS_SUB_EXPIRED

  propertyId: string = ''
  isSubscribed: boolean = false
  is_demo: boolean = false
  isDemoNotifClicked: boolean = false
  isSubExpired: boolean = false
  isBppUser: boolean = false

  propertyDetailDemo: PropertyDetail[]
  marketTrend: MarketTrend[]

  dropdownObj = {
    reports: {
      show: false,
    },
    monthYear: {
      show: false,
    },
  }

  selectedMonthYearString: string = ''
  selectedMonthYear = {
    month: 0,
    year: 0,
  }

  reportList = [
    {
      name: '',
      url: '',
    },
  ]

  revenueReportUrl: string

  monthYearList = [
    {
      month: 1,
      year: 2000,
    },
  ].map(data => new Date(data.year, data.month - 1, 2))

  private readonly _unitDetailSource = new BehaviorSubject<Unit>(null)
  readonly unitDetailState = this._unitDetailSource.asObservable()

  private readonly _propertySource = new BehaviorSubject<any>(null)
  readonly propertyState = this._propertySource.asObservable()

  private readonly _propertyOverviewSource =
    new BehaviorSubject<PropertyOverviewModel>(null)
  readonly propertyOverviewState = this._propertyOverviewSource.asObservable()

  private readonly _documentListSource = new BehaviorSubject<Documents[]>(null)
  readonly documentListState = this._documentListSource.asObservable()

  private readonly _tenancyTypeListSource = new BehaviorSubject<Option[]>(null)
  readonly tenancyTypeListState = this._tenancyTypeListSource.asObservable()

  private readonly _hostListSource = new BehaviorSubject<Option[]>(null)
  readonly hostListState = this._hostListSource.asObservable()

  private readonly _hisConstSource = new BehaviorSubject<TimelineModel>(null)
  readonly histConstState = this._hisConstSource.asObservable()

  private readonly _renoHisSource = new BehaviorSubject<TimelineModel>(null)
  readonly renoHisState = this._renoHisSource.asObservable()

  private readonly _keyCollectionSource = new BehaviorSubject<KeyCollectModel>(
    null
  )
  readonly keyCollectionState = this._keyCollectionSource.asObservable()

  private readonly _defectCheckSource = new BehaviorSubject<DefectCheckModel>(
    null
  )
  readonly defectCheckState = this._defectCheckSource.asObservable()

  private readonly _shortStaySource = new BehaviorSubject<ShortStayModel>(null)
  readonly shortStayState = this._shortStaySource.asObservable()

  private readonly _occupancyRateSource = new BehaviorSubject<SummaryCard>(null)
  readonly occupancyRateState = this._occupancyRateSource.asObservable()

  private readonly _totalEarningsSrc = new BehaviorSubject<SummaryCard>(null)
  readonly totalEarningsState = this._totalEarningsSrc.asObservable()

  private readonly _avgDailyEarningsSrc = new BehaviorSubject<SummaryCard>(null)
  readonly avgDailyEarningsState = this._avgDailyEarningsSrc.asObservable()

  private readonly _channelListingsSource = new BehaviorSubject<any>(null)
  readonly channelListingsState = this._channelListingsSource.asObservable()

  private readonly _sourceOfBookingSource = new BehaviorSubject<any>(null)
  readonly sourceOfBookingState = this._sourceOfBookingSource.asObservable()

  private readonly _hostSource = new BehaviorSubject<Host>(null)
  readonly hostState = this._hostSource.asObservable()

  private readonly _marketTrendSource = new BehaviorSubject<MarketTrend[]>(null)
  readonly marketTrendState = this._marketTrendSource.asObservable()

  private readonly _bookingSummarySource = new BehaviorSubject<BookingSumModel>(
    null
  )
  readonly bookingSummaryState = this._bookingSummarySource.asObservable()

  private readonly _expensesSource = new BehaviorSubject<ExpenseModel>(null)
  readonly expensesState = this._expensesSource.asObservable()

  private readonly _insuranceProtectionSource =
    new BehaviorSubject<InsuranceProtectionModel>(null)
  readonly insuranceProtectionState =
    this._insuranceProtectionSource.asObservable()

  constructor(
    private route: ActivatedRoute,
    private propertyService: PropertiesService,
    private securedStore: SecuredStore,
    private projectService: ProjectsService,
    private userStore: UserStore,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.isShowDefectcheck = false
    let currentDate = new Date()
    let joinDate = new Date(this.userStore.getUser().user.user_registered_at)
    if (joinDate) {
      this.populateMonthYear(joinDate, currentDate)
    }
    if (this.userStore.getUser()?.user.role == 4) {
      this.isBppUser = true
    } else {
      this.isBppUser = false
    }
    this.selectMonthYear(currentDate)
    this.propertyId = this.route.snapshot.paramMap.get('id')
    this.populateData()
  }

  setDemoValues(propertyDetailDemo: PropertyDetail) {
    this.property = propertyDetailDemo
    this.propertyOverview = {
      id: this.property.id,
      project_name: this.property.project_name,
      project_location: craftLocation(
        this.property.project_address_1,
        this.property.project_address_2,
        this.property.project_post_code,
        this.property.project_state,
        this.property.project_area
      ),
      unit_number: this.property.unit_number,
      status: this.property.status,
      tags: this.property.tags,
      category: this.property.category,
      revenue_share: this.property.revenue_share,
      package_type: this.property.package_type,
      images: this.property.images,
      bedroom: this.property.unit_type?.bedroom,
      bathroom: this.property.unit_type?.bathroom,
      max_people: this.property.unit_type?.max_people,
      market_value: Number(this.property.market_value),
      parking_amount: String(this.property.parking?.amount),
      parking_lots_no: this.property.parking?.lot_numbers.toString(),
      unit_spa: this.property.unit_spa,
      commencement_date: this.property.commencement_date,
    }

    let bookingSum: BookingSumModel = {
      isSold: this.property.status == Constants.STATUS_SOLD ? true : false,
      bookingSummary: this.property.booking_summary,
    }

    let expensesSum: ExpenseModel = {
      isSold: this.property.status == Constants.STATUS_SOLD ? true : false,
      expenseSummary: this.property.expenses,
    }

    let insuranceProtect: InsuranceProtectionModel = {
      is_insured: this.property.is_insured,
      insurance_protection: this.property.insurance_protection,
    }

    let totalEarnings: SummaryCard = {
      bg_color: 'lightblue',
      type: 'nominal',
      icon: 'app/assets/total-revenue-icon.svg',
      title: 'Monthly Earnings',
      value: this.property.total_earnings.current_month
        ? this.property.total_earnings.current_month
        : 0,
      prev_month: this.property.total_earnings.prev_month
        ? this.property.total_earnings.prev_month
        : 0,
      selectedYear: this.selectedMonthYear.year,
      selectedMonth: this.selectedMonthYear.month,
    }

    let occupancyRate: SummaryCard = {
      bg_color: 'thickblue',
      type: 'percentage',
      icon: 'app/assets/total-profit-icon.svg',
      title: 'Occupancy Rate',
      value: this.property.occupancy_rate.current_month
        ? this.property.occupancy_rate.current_month
        : 0,
      prev_month: this.property.occupancy_rate.prev_month
        ? this.property.occupancy_rate.prev_month
        : 0,
      selectedYear: this.selectedMonthYear.year,
      selectedMonth: this.selectedMonthYear.month,
    }

    let avgDailyEarnings: SummaryCard = {
      bg_color: 'thickorange',
      type: 'nominal',
      icon: 'app/assets/total-expenses-icon.svg',
      title: 'Average Daily Earning',
      value: this.property.avg_daily_earnings.current_month
        ? this.property.avg_daily_earnings.current_month
        : 0,
      prev_month: this.property.avg_daily_earnings.prev_month
        ? this.property.avg_daily_earnings.prev_month
        : 0,
      selectedYear: this.selectedMonthYear.year,
      selectedMonth: this.selectedMonthYear.month,
    }

    this._propertyOverviewSource.next(this.propertyOverview)
    this._documentListSource.next(this.property.documents)
    this.securedStore.updatePageTitle(this.property.project_name)
    this._totalEarningsSrc.next(totalEarnings)
    this._occupancyRateSource.next(occupancyRate)
    this._avgDailyEarningsSrc.next(avgDailyEarnings)
    this._channelListingsSource.next(this.property.channel_listings)
    this._sourceOfBookingSource.next(this.property.source_of_booking)
    this._hostSource.next(this.property.host)
    this._marketTrendSource.next(this.marketTrend)
    this._bookingSummarySource.next(bookingSum)
    this._expensesSource.next(expensesSum)
    this._insuranceProtectionSource.next(insuranceProtect)

    this.isSubscribed = true
    this.isSubExpired = false
  }

  populateMonthYearByPropertyCalendar(startDate: Date, endDate: Date) {
    this.monthYearList = []
    let currentDate = startDate
    let currentYear = startDate.getFullYear()
    let currentMonth = startDate.getMonth()

    while (currentDate < endDate) {
      this.monthYearList.push(new Date(currentYear, currentMonth))
      currentMonth++
      if (currentMonth >= 12) {
        currentYear++
        currentMonth = 0
      }
      currentDate = new Date(currentYear, currentMonth)
    }
  }

  populateMonthYear(joinDate: Date, currentDate: Date) {
    this.monthYearList = []

    const joinedYear = joinDate.getFullYear()
    const joinedMonth = joinDate.getMonth()
    let startYear = joinedYear
    let startMonth = joinedMonth
    let endYear = currentDate.getFullYear()

    while (startYear <= endYear) {
      if (startYear > joinedYear) {
        startMonth = 0
      }
      let endMonth = currentDate.getMonth() + 1
      if (endYear > startYear) {
        endMonth = 12
      }
      while (startMonth + 1 <= endMonth) {
        this.monthYearList.push(new Date(startYear, startMonth, 2))
        startMonth++
      }
      startYear++
    }
  }

  toggleMenu = menu => {
    this.dropdownObj[menu].show = !this.dropdownObj[menu].show
  }

  showDefectcheck = () => {
    this.isShowDefectcheck = true
  }

  openReport(url: string) {
    window.open(url)
    this.dropdownObj['reports'].show = false
  }

  openRevenue(url: string) {
    window.open(url)
  }

  selectMonthYear(date: Date) {
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let shortMonth = date.toLocaleString('en-us', { month: 'short' })
    this.selectedMonthYearString = shortMonth + ' ' + year
    this.selectedMonthYear = {
      month: month,
      year: year,
    }
    if (this.dropdownObj['monthYear'].show) {
      this.dropdownObj['monthYear'].show = false
    }
  }

  updateMonthYear(date: Date) {
    this.selectMonthYear(date)
    this.populateData()
  }

  //? this function will find index for request month and year and set the demo values array.
  findDemo = (month: number, year: number) => {
    if (this.propertyDetailDemo) {
      const index = this.propertyDetailDemo.findIndex(
        demoData => demoData.month === month && demoData.year === year
      )

      // return month and year values greater than 1 && 2022
      this.marketTrend = populateMarketTrend(
        this.propertyDetailDemo.filter(
          demoData => demoData.month >= 1 && demoData.year >= 2022
        )
      )

      const previousMonthIndex =
        (index + this.propertyDetailDemo.length - 1) %
        this.propertyDetailDemo.length
      // NOTE:  showing previous month
      this.setDemoValues(this.propertyDetailDemo[previousMonthIndex])
    }
  }

  onClickNoUnitNotif = () => {
    this.isDemoNotifClicked = true
  }

  processPropertyResponse = (resp: PropertyDetailResponse) => {
    {
      const property = resp.data
      this.property = property

      this.propertyOverview = {
        id: property.id,
        project_name: property.project_name,
        project_location: craftLocation(
          property.project_address_1,
          property.project_address_2,
          property.project_post_code,
          property.project_state,
          property.project_area
        ),
        unit_number: property.unit_number,
        status: property.status,
        tags: property.tags,
        category: property.category,
        revenue_share: property.revenue_share,
        package_type: property.package_type,
        images: property.images,
        bedroom: property.unit_type?.bedroom,
        bathroom: property.unit_type?.bathroom,
        max_people: property.unit_type?.max_people,
        market_value: Number(property.market_value),
        parking_amount: this.property.parking?.amount
          ? String(this.property.parking?.amount)
          : null,
        parking_lots_no: this.property.parking?.lot_numbers
          ? this.property.parking?.lot_numbers.toString()
          : '0',
        unit_spa: this.property.unit_spa,
        commencement_date: this.property.commencement_date,
      }

      this.is_demo = property.is_demo ? true : false

      if (property.calendar) {
        this.populateMonthYearByPropertyCalendar(
          new Date(property.calendar.start_date),
          new Date(property.calendar.end_date)
        )
      }

      for (let sub of property.subscriptions) {
        if (sub.type == Constants.SHORTSTAY_MGMT_SERVICE) {
          this.isSubscribed = true
          let expDate = new Date(sub.active_until)
          let currentDate = new Date()
          currentDate.setHours(0, 0, 0, 0)

          if (expDate <= currentDate) {
            this.isSubExpired = true
          }
          break
        }
      }

      let constructionHistData: TimelineModel = {
        history_data: property.construction_history,
      }

      let renovationHistData: TimelineModel = {
        history_data: property.renovation_history,
      }

      let keyCollectData: KeyCollectModel = {
        keyCollectionDateTime: property.key_collection_date,
        key_collector: property.key_collector,
        propertyId: property.id,
        project_name: property.project_name,
        project_address_1: property.project_address_1,
        project_address_2: property.project_address_2,
        unit_number: property.unit_number,
        project_phone_number: property.project_phone_number,
      }

      let defectCheckData: DefectCheckModel = {
        propertyId: property.id,
        defect_check_history: property.defect_check_history,
        defect_areas: property.unit_type?.defects_areas,
      }

      let shortStayData: ShortStayModel = {
        short_stay_setup_start_at: property.short_stay_setup_start_at, //'2022-03-10 10:00:00'
      }

      let bookingSum: BookingSumModel = {
        isSold: property.status == Constants.STATUS_SOLD ? true : false,
        bookingSummary: property.booking_summary,
      }

      let expensesSum: ExpenseModel = {
        isSold: property.status == Constants.STATUS_SOLD ? true : false,
        expenseSummary: property.expenses,
      }

      let insuranceProtect: InsuranceProtectionModel = {
        is_insured: property.is_insured,
        insurance_protection: property.insurance_protection,
      }

      if (property.download_report) {
        for (let i = 0; i < 4; i++) {
          this.reportList = [
            {
              name: 'Construction Report',
              url: property.download_report.construction_url,
            },
            {
              name: 'Defect Check Report',
              url: property.download_report.defect_check_url,
            },
            {
              name: 'Renovation Report',
              url: property.download_report.renovation_url,
            },
            {
              name: 'Revenue Report',
              url: property.download_report.revenue_url,
            },
          ]

          this.revenueReportUrl = property.download_report.revenue_url
        }
      }

      let totalEarnings: SummaryCard = {
        bg_color: 'lightblue',
        type: 'nominal',
        icon: 'app/assets/total-revenue-icon.svg',
        title: 'Monthly Earnings',
        value: this.property.total_earnings.current_month
          ? this.property.total_earnings.current_month
          : 0,
        prev_month: this.property.total_earnings.prev_month
          ? this.property.total_earnings.prev_month
          : 0,
        selectedYear: this.selectedMonthYear.year,
        selectedMonth: this.selectedMonthYear.month,
      }

      let occupancyRate: SummaryCard = {
        bg_color: 'thickblue',
        type: 'percentage',
        icon: 'app/assets/total-profit-icon.svg',
        title: 'Occupancy Rate',
        value: this.property.occupancy_rate.current_month
          ? this.property.occupancy_rate.current_month
          : 0,
        prev_month: this.property.occupancy_rate.prev_month
          ? this.property.occupancy_rate.prev_month
          : 0,
        selectedYear: this.selectedMonthYear.year,
        selectedMonth: this.selectedMonthYear.month,
      }

      let avgDailyEarnings: SummaryCard = {
        bg_color: 'thickorange',
        type: 'nominal',
        icon: 'app/assets/total-expenses-icon.svg',
        title: 'Average Daily Earning',
        value: this.property.avg_daily_earnings.current_month
          ? this.property.avg_daily_earnings.current_month
          : 0,
        prev_month: this.property.avg_daily_earnings.prev_month
          ? this.property.avg_daily_earnings.prev_month
          : 0,
        selectedYear: this.selectedMonthYear.year,
        selectedMonth: this.selectedMonthYear.month,
      }

      this._propertyOverviewSource.next(this.propertyOverview)
      this._documentListSource.next(property.documents)
      this.securedStore.updatePageTitle(property.project_name)
      this._keyCollectionSource.next(keyCollectData)
      this._shortStaySource.next(shortStayData)
      this._totalEarningsSrc.next(totalEarnings)
      this._occupancyRateSource.next(occupancyRate)
      this._avgDailyEarningsSrc.next(avgDailyEarnings)
      this._channelListingsSource.next(property.channel_listings)
      this._sourceOfBookingSource.next(property.source_of_booking)
      this._hostSource.next(property.host)
      this._marketTrendSource.next(property.market_trend)
      this._bookingSummarySource.next(bookingSum)
      this._expensesSource.next(expensesSum)
      this._insuranceProtectionSource.next(insuranceProtect)

      this.projectService
        .getProjectById(property.project_id)
        .then((res: Project) => {
          constructionHistData.developer_avatar = res.developer.avatar_url
          constructionHistData.developer_name = res.developer.name
          constructionHistData.vp_date = res.vp_date
          renovationHistData.developer_avatar = res.developer.avatar_url
          renovationHistData.developer_name = res.developer.name
          renovationHistData.vp_date = res.vp_date
          defectCheckData.developer_avatar = res.developer.avatar_url
          defectCheckData.developer_name = res.developer.name
          defectCheckData.vp_date = res.vp_date
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this._hisConstSource.next(constructionHistData)
          this._renoHisSource.next(renovationHistData)
          this._defectCheckSource.next(defectCheckData)
        })

      this.propertyService
        .getPropertyCategories()
        .then((res: PropertyCategoriesRes) => {
          let tenancyType: Option
          let tenancyTypeList: Option[] = []
          for (let val of res.data) {
            tenancyType = {
              id: val.id,
              label: val.name,
              value: val.name,
            }
            tenancyTypeList.push(tenancyType)
          }

          this._tenancyTypeListSource.next(tenancyTypeList)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})

      this.propertyService
        .getHosts(property.id)
        .then((res: HostListRes) => {
          let host: Option
          let hostList: Option[] = []
          for (let val of res.data) {
            host = {
              id: val.id,
              label: val.name,
              value: val.id,
            }
            hostList.push(host)
          }
          this._hostListSource.next(hostList)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }
  }

  populateData = () => {
    const demo = this.route.snapshot.paramMap.get('demo') ? '/demo' : ''
    this.propertyService
      .getPropertyDetail(
        this.route.snapshot.paramMap.get('id'),
        this.selectedMonthYear.month,
        this.selectedMonthYear.year,
        demo
      )
      .then((resp: PropertyDetailResponse) =>
        this.processPropertyResponse(resp)
      )
      .catch(err => console.error(err))
  }
}
