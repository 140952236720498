import { Injectable } from '@angular/core'
import { SocialAuthService, SocialUser } from 'angularx-social-login'
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from 'angularx-social-login'

@Injectable({
  providedIn: 'root',
})
export class SocialLoginAuthService {
  constructor(private socialAuthService: SocialAuthService) {}

  async signInWithGoogle(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      this.socialAuthService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((resp: SocialUser) => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  async signInWithFB(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      this.socialAuthService
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((resp: SocialUser) => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  refreshToken(): void {
    this.socialAuthService
      .refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
      .then(resp => {
        // console.log(resp)
      })
  }

  async signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socialAuthService
        .signOut(true)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
