import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import {
  PackageDetail,
  Services,
  ShortstayMgmtPackageRes,
} from 'src/app/modules/shared/models/services.model'
import { ServicesService } from 'src/app/modules/shared/service/services.service'
import {
  CartItem,
  ServiceItem,
  UnitItem,
  UserCart,
} from 'src/app/modules/shared/state/user/user.model'

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.sass'],
})
export class SubscribeComponent implements OnInit {
  @Input() projectId: string = ''
  @Input() projectname: string = ''
  @Input() unitLabel: string = ''
  @Input() unitId: string = ''
  packages: ShortstayMgmtPackageRes[] = []
  redirectUrl: string = '/checkout'
  shortstayIdentifier: string
  shortstayServiceId: string

  constructor(
    private servicesService: ServicesService,
    private userStore: UserStore,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getHasServices()
    this.getPackages()
    if (this.packages.length > 1) {
      this.redirectUrl = '/has-services/shortstay-management'
    }
  }

  getHasServices = () => {
    this.servicesService.getServices().then((res: Services[]) => {
      for (let serv of res) {
        if (serv.identifier == Constants.SHORTSTAY_MGMT_SERVICE) {
          this.shortstayServiceId = serv.id
          this.shortstayIdentifier = serv.identifier
          break
        }
      }
    })
  }

  getPackages = () => {
    this.servicesService.getShortstayManagementServices().then((res: any) => {
      this.packages = res.data
    })
  }

  onSubscribe = () => {
    if (this.packages.length > 1) {
      this.router.navigate([
        this.redirectUrl,
        this.shortstayServiceId,
        this.shortstayIdentifier,
      ])
    } else {
      // prepare package object
      let packageDetail: PackageDetail = {
        id: this.packages[0].id,
        name: this.packages[0].title,
        price: this.packages[0].price,
        description: this.packages[0].description,
        avatar_url: '',
      }
      let serviceItem: ServiceItem = {
        service_id: this.shortstayServiceId,
        service_name: 'Short Stay Management',
        package: [packageDetail],
        service_identifier: this.shortstayIdentifier,
      }
      let unitItem: UnitItem = {
        unit_type: this.unitLabel,
        unit_type_id: this.unitId,
        service: [serviceItem],
      }
      let packageItem: CartItem = {
        project_id: this.projectId,
        project_name: this.projectname,
        unit: [unitItem],
      }

      // new user cart
      let user = this.userStore.getUser().user
      let packages: CartItem[] = [packageItem]
      let newCart: UserCart = {
        user: user,
        item_count: 1,
        cart: packages,
      }

      this.userStore.addToCart(newCart, this.shortstayServiceId)
      this.router.navigate([this.redirectUrl])
    }
  }
}
