<div class="property-card" (click)="handlePropertyCardClicked()">
  <div class="property-card-header">
    <img
      *ngIf="propertyData.images.length > 0"
      src="{{ propertyData.images[0].url }}" />
    <img *ngIf="propertyData.images.length < 1" src="app/assets/no-image.png" />
    <div
      class="property-status"
      [ngClass]="
        propertyData.is_insured === true ? 'status-success' : 'status-danger'
      ">
      {{
        propertyData.is_insured === true
          ? "Protected"
          : propertyData.is_insured === false
          ? "Unprotected"
          : "Demo"
      }}
    </div>
  </div>
  <div class="property-card-content">
    <div class="project-details">
      <h2 class="project-name">
        {{ propertyData.project_name }}
      </h2>
      <h4 class="unit-number">
        {{ propertyData.unit_number }}
      </h4>
    </div>
    <div class="project-tags mt-auto">
      <span
        class="badge body-1"
        [ngClass]="{
          statusLive: property?.status == statusLive,
          statusDefectCheck: property?.status == statusDefectCheck,
          statusKeyCollection: property?.status == statusKeyCollection,
          statusPendingApproval: property?.status == statusPendingApproval,
          statusRenovation: property?.status == statusRenovation,
          statusShortStaySetup: property?.status == statusShortStaySetup,
          statusSold: property?.status == statusSold,
          statusUnderConstruction: property?.status == statusUnderConstruction,
          statusNewLaunch: property?.status == statusNewLaunch,
          statusSubExpired: property?.status == statusSubExpired
        }">
        {{ propertyData.status }}
      </span>
    </div>
    <div class="pricing-details">
      <div class="label">Monthly Revenue</div>
      <div class="price-label">
        {{
          (propertyData.monthly_revenue | currency: "MYR":"symbol":"1.0-2") ||
            "MYR -"
        }}
      </div>
    </div>
  </div>
</div>
