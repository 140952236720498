import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UserStore } from '../../core/auth/store/user.store'
import { SidebarStore } from '../../shared/component/sidebar/store/sidebar.store'
import { SecuredStore, SecuredStoreModel } from './store/secured.store'

@Component({
  selector: 'app-secured',
  templateUrl: './secured.component.html',
  styleUrls: ['./secured.component.sass'],
})
export class SecuredComponent implements OnInit {
  pageState: SecuredStoreModel

  constructor(
    private route: ActivatedRoute,
    private sidebarStore: SidebarStore,
    private securedStore: SecuredStore,
    private userStore: UserStore,
    private router: Router
  ) {}

  ngOnInit(): void {
    // console.log(this.route);
    this.securedStore.getState().subscribe(data => {
      this.pageState = data
    })
    const user = this.userStore.getUser()
    if (!user) {
      this.router.navigate([`/`])
    }
  }

  toggleSidebar() {
    this.sidebarStore.toggleMenu()
  }
}
