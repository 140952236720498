<div class="mini-menu-container">
  <div class="shopping-cart-wrapper me-2" (click)="goToCheckoutPage()">
    <svg-icon src="app/assets/shopping-cart.svg"></svg-icon>
    <div class="cart-item-notif" *ngIf="cartItemCount > 0">
      {{ cartItemCount }}
    </div>
  </div>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-light btn-round dropdown-toggle"
      (click)="toggleMiniMenu('miniMenu')">
      {{ userEmail }}
      <div class="profile-picture ms-3 me-2">
        <img [src]="avatar" />
      </div>
    </button>
    <div class="dropdown-menu" [ngClass]="{ show: dropdownObj.miniMenu.show }">
      <a class="dropdown-item" href="#" (click)="logout($event)">
        <svg-icon src="app/assets/logout.svg"></svg-icon>
        Log out
      </a>
    </div>
  </div>
</div>
