<div class="project-detail-container">
  <div class="row mt-0 g-3 g-lg-4">
    <div class="col-12 col-lg-9">
      <div class="row g-3 g-lg-4">
        <div class="col-12">
          <app-project-overview-card
            [projectOverviewState]="
              projectOverviewState
            "></app-project-overview-card>
        </div>
        <div class="col-12">
          <app-sale-price-card
            [salesDataState]="salesDataState"></app-sale-price-card>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="row g-3 g-lg-4">
        <div class="col-12">
          <app-developer-card
            [developerDataState]="developerDataState"></app-developer-card>
        </div>
        <div class="col-12">
          <app-market-grade-card
            [marketGradeState]="marketGradeState"></app-market-grade-card>
        </div>
        <div class="col-12">
          <app-average-daily-rate
            [avgDailyRateState]="avgDailyRateState"></app-average-daily-rate>
        </div>
        <div class="col-12">
          <app-project-occupancy-rate
            [occupancyRateState]="
              occupancyRateState
            "></app-project-occupancy-rate>
        </div>
        <div class="col-12">
          <app-revenue-rate
            [revenueRateState]="revenueRateState"></app-revenue-rate>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-0 g-3 g-lg-4">
    <div class="col-12 col-lg-6">
      <app-host-partner [hostDataState]="hostDataState"></app-host-partner>
    </div>
    <div class="col-12 col-lg-6">
      <app-reno-partner
        [renoPartnerDataState]="renoPartnerDataState"></app-reno-partner>
    </div>
    <div class="col-12 col-lg-6">
      <app-agent-card [agentsDataState]="agentsDataState"></app-agent-card>
    </div>
  </div>
</div>
