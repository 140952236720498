import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { MediaPreviewComponent } from 'src/app/modules/shared/component/media-preview/media-preview.component'
import { PackageDetail } from 'src/app/modules/shared/models/services.model'
import { PricingPackageModel } from '../../model/reno-pricing.model'
import { HasServicesStore } from '../../pages/store/has-services.store'

@Component({
  selector: 'app-package-pricings',
  templateUrl: './package-pricings.component.html',
  styleUrls: ['./package-pricings.component.sass'],
})
export class PackagePricingsComponent implements OnInit {
  @Input() title: string = ''
  @Input() onSelectPackage = (param: any) => {}
  dataList: PackageDetail[] = []
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: `<div style='
      background-color: white;
      padding: 0px 10px;
      box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
      position: absolute;
      cursor: pointer;
      width: 41px;
      height: 26px;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      '><img src='app/assets/arrow-left.svg' alt='' /></div>`,
    nextArrow: `<div style='
      background-color: white;
      padding: 0px 10px;
      box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
      position: absolute;
      cursor: pointer;
      width: 41px;
      height: 26px;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      '><img src='app/assets/arrow-right.svg' alt='' /></div>`,
    infinite: false,
    responsive: [
      {
        breakpoint: 512,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
    ],
  }

  constructor(
    private store: HasServicesStore,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.store.getState().subscribe(data => {
      this.dataList = data.packages
    })
  }

  selectPackage(selectedPackage: PackageDetail) {
    this.onSelectPackage(selectedPackage)
  }

  onMediaPreview(path, type) {
    const mediaModal = this.modalService.open(MediaPreviewComponent, {
      size: 'lg',
    })
    mediaModal.componentInstance.mediaType = type
    mediaModal.componentInstance.mediaPath = path
  }

  slickInit(e) {
    // console.log('slick initialized')
  }

  breakpoint(e) {
    // console.log('breakpoint')
  }

  afterChange(e) {
    // console.log('afterChange')
  }

  beforeChange(e) {
    // console.log('beforeChange')
  }
}
