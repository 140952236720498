import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'

import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import {
  DefectPatchReq,
  HostListRes,
  KeyDelegationReq,
  ListToSellReq,
  ListToSellRes,
  LodgeDefectReq,
  PropertyCategoriesRes,
  PropertyDetailResponse,
  PropertyInfo,
  RemovalReq,
  StatusUpdReq,
  UpdateCategoryReq,
} from '../models/property.model'

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  constructor(private http: HttpClient) {}

  async getProperties() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.GET_PROPERTIES)
            .including()
            .withImages()}`
        )
        .subscribe({
          next: data => {
            resolve(data.data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getPropertyDetail(propertyId: string, month: number, year: number, demo: string = '') {
    return new Promise((resolve, reject) => {
      let url = demo + Endpoints.GET_PROPERTIES + '/' + propertyId

      if (year && month) {
        url += `?year=${year}`
        url += `&month=${month}`
      }

      this.http
        .get<PropertyDetailResponse>(
          `${environment.baseUrl}${new ApiFactory(
            url
          )
            .filter(month, 'month')
            .filter(year, 'year')
            .including()
            .withDocuments()
            .withHost()
            .withImages()
            .withUnitType()
            .withDefectAreas()
            .withKeyCollectionHistory()
            .withDefectCheckHistory()
            .withConstructionHistory()
            .withRenovationHistory()
            .withReport()
            .withMarketTrend()
            .toString()}`
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getPropertiesByProjectId(projectId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.GET_PROPERTIES
          )}?search[project_id]=${projectId}`
        )
        .subscribe({
          next: data => {
            resolve(data.data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async addHasProperty(propertyInfo: PropertyInfo) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.ADD_PROPERTY)}`,
          { ...propertyInfo }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async addCustomProperty(propertyInfo: PropertyInfo) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.ADD_CUSTOM_PROPERTY
          )}`,
          { ...propertyInfo }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async sellRequest(property_id: string, req: ListToSellReq) {
    let endPoint = Endpoints.SELL_REQUEST.replace('[PROPERTY_ID]', property_id)
    return new Promise((resolve, reject) => {
      this.http
        .post<ListToSellRes>(
          `${environment.baseUrl}${new ApiFactory(endPoint)}`,
          req
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getPropertyCategories() {
    return new Promise((resolve, reject) => {
      this.http
        .get<PropertyCategoriesRes>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.PROPERTY_CATEGORIES
          )}`
        )
        .subscribe({
          next: (res: PropertyCategoriesRes) => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getHosts(projectId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<HostListRes>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.HOSTS)
            .search('projectId')
            .toString()}${projectId}`
        )
        .subscribe({
          next: (res: HostListRes) => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async updateCategory(propertyId: string, req: UpdateCategoryReq) {
    let endPoint = Endpoints.UPDATE_CATEGORY.replace(
      '[PROPERTY_ID]',
      propertyId
    )
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`, req)
        .subscribe({
          next: (res: any) => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async editStatus(propertyId: string, req: StatusUpdReq) {
    let endPoint = Endpoints.EDIT_STATUS.replace('[PROPERTY_ID]', propertyId)
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`, req)
        .subscribe({
          next: (res: any) => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async removalRequest(propertyId: string, req: RemovalReq) {
    let endPoint = Endpoints.REMOVAL_REQUEST.replace(
      '[PROPERTY_ID]',
      propertyId
    )
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`, req)
        .subscribe({
          next: (res: any) => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async keyDelegation(propertyId: string, req: KeyDelegationReq) {
    let endPoint = Endpoints.KEY_DELEGATION.replace('[PROPERTY_ID]', propertyId)
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`, req)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async LodgeDefect(propertyId: string, req: LodgeDefectReq) {
    let endPoint = Endpoints.LODGE_DEFECT.replace('[PROPERTY_ID]', propertyId)
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`, req)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async patchDefectStatus(
    propertyId: string,
    defectId: string,
    req: DefectPatchReq
  ) {
    let endPoint = Endpoints.DEFECT_PATCH.replace(
      '[PROPERTY_ID]',
      propertyId
    ).replace('[DEFECT_ID]', defectId)
    return new Promise((resolve, reject) => {
      this.http
        .patch<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`, req)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  downloadConstructionReport(property_id: string) {
    let endPoint = Endpoints.UNDER_CONST_REPORT.replace(
      '[PROPERTY_ID]',
      property_id
    )
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(`${environment.baseUrl}${new ApiFactory(endPoint)}`)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
