<div class="ota-listings bg-white">
  <div class="title">Channel Listings</div>
  <div class="row mt-3">
    <div class="col-3" *ngFor="let channel of channelArray">
      <div *ngIf="channel[1]; else greyedImg">
        <img
          class="ota-image"
          src="app/assets/{{ channel[0] }}-logo-icon.png" />
      </div>
      <ng-template #greyedImg>
        <img
          class="ota-image"
          src="app/assets/{{ channel[0] }}-logo-icon-grey.png" />
      </ng-template>
    </div>
  </div>
</div>
