import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { RemovalReq } from 'src/app/modules/shared/models/property.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'

@Component({
  selector: 'app-remove-from-listing-modal',
  templateUrl: './remove-from-listing-modal.component.html',
  styleUrls: ['./remove-from-listing-modal.component.sass'],
})
export class RemoveFromListingModalComponent implements OnInit {
  @Input() propertyId: string
  removeFromListForm = new FormGroup({
    reasonSelect: new FormControl(''),
    confirmation_text: new FormControl(''),
    other_reason: new FormControl(''),
  })
  reasonList: string[] = [
    'Own Stay',
    "Don't wish to continue",
    'Change Management',
    'Selling Unit',
  ]

  constructor(
    private propertyService: PropertiesService,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {}

  onClickRemovalRequest = (): boolean => {
    let reason = this.removeFromListForm.get('reasonSelect').value
    if (reason == 'other') {
      reason = this.removeFromListForm.get('other_reason').value
    }
    let req: RemovalReq = {
      reason: reason,
    }

    if (reason != null && reason != '') {
      if (this.removeFromListForm.get('confirmation_text').value == 'CONFIRM') {
        this.propertyService
          .removalRequest(this.propertyId, req)
          .then(res => {
            // console.log(res)
            this.toastService.show(
              'Success',
              'Removal requested, please wait for moderation'
            )
          })
          .catch(err => {
            console.log(err)
            this.toastService.show('Error', 'Removal request error')
          })
          .finally(() => {})
      } else {
        // alert('Please Type "CONFIRM"')
        this.toastService.show('Error', 'Please Type "CONFIRM"')
        return false
      }
    } else {
      // alert('Please Select the Reason')
      this.toastService.show('Error', 'Please Select the Reason')
      return false
    }

    return true
  }
}
