<div class="market-trend bg-white">
  <div class="header">
    <div class="title">Market Trend</div>
    <div class="toggle">
      <div
        class="item"
        [ngClass]="{ active: selectedTrend == 'occupancy' }"
        (click)="toggleTrend('occupancy')">
        Occupancy
      </div>
      <div
        class="item"
        [ngClass]="{ active: selectedTrend == 'revenue' }"
        (click)="toggleTrend('revenue')">
        Revenue
      </div>
    </div>
  </div>
  <div class="content">
    <div class="content-title">{{ selectedTrend }} Trend</div>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [title]="chartOptions.title"
      #occupancyTrend></apx-chart>
  </div>
</div>
