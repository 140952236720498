<app-custom-modal [modalSubmit]="onClickEditCategory">
  <span modalTitle>Edit Category</span>
  <span modalBtn>Request</span>
  <form
    [formGroup]="editTenancyForm"
    [ngClass]="{ 'has-errors': editTenancyForm.invalid && submitted }">
    <!-- [ngClass]="{'has-errors': editTenancyForm.invalid && submitted}"-->
    <div class="row">
      <div class="col-12 col-md-12 mb-3">
        <custom-input
          [formGroup]="editTenancyForm"
          id="tenancy_status"
          label="Tenancy Status"
          name="tenancy_status"
          type="select"
          [valueList]="tenancyStatusList">
        </custom-input>
      </div>
      <div class="col-12 col-md-12 mb-3">
        <custom-input
          [formGroup]="editTenancyForm"
          id="managed_by"
          label="Manage by"
          name="managed_by"
          type="select"
          [valueList]="managedByList">
        </custom-input>
      </div>
    </div>
  </form>
</app-custom-modal>
