<div class="content">
  <div>
    <app-profile-card #profilecard [isBppUser]="isBppUser"></app-profile-card>
  </div>
  <div class="mt-3" [ngClass]="{ hidden: !isBppUser }">
    <app-bank-account #bankacc [bankNameList]="bankNameList"></app-bank-account>
  </div>
  <div class="mt-3">
    <app-notification-card #notifcard></app-notification-card>
  </div>
  <div class="mt-3">
    <app-investment-profile-card
      #investmentProfileCard></app-investment-profile-card>
  </div>
  <div class="mt-3">
    <app-network-card #networkcard></app-network-card>
  </div>
  <div class="mt-3">
    <app-password-card #passcard></app-password-card>
  </div>
  <div class="mt-3 mb-5 profile-save">
    <button
      class="btn"
      (click)="
        !isLoading &&
          onSave(
            profilecard,
            bankacc,
            notifcard,
            networkcard,
            passcard,
            investmentProfileCard
          )
      "
      [ngClass]="{ 'btn-load': isLoading }">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="isLoading"></span>
      {{ isLoading ? "Waiting" : "Save" }}
    </button>
  </div>
</div>
