<div class="main-container">
  <div class="title">Bank Account</div>
  <form [formGroup]="bankAccForm" class="bank-acc-form">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="bankAccForm"
          id="bank_acc_name"
          name="bank_acc_name"
          label="Full Name in your Bank Account"
          type="text"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="bankAccForm"
          id="bank_name"
          name="bank_name"
          label="Bank name"
          type="select"
          [valueList]="bankNameList"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="bankAccForm"
          id="bank_acc_no"
          name="bank_acc_no"
          label="Account number"
          type="text"></custom-input>
      </div>
    </div>
  </form>
</div>
