import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { UnitType } from 'src/app/modules/shared/models/property.model'
import { UnitTypesRes } from 'src/app/modules/shared/models/services.model'
import { ServicesService } from 'src/app/modules/shared/service/services.service'
import { HasServicesStore } from '../../pages/store/has-services.store'

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.sass'],
})
export class ProjectFilterComponent implements OnInit {
  @Input() getUnitTypes = (param: string) => {}
  @Input() getPartners = (param: string) => {}
  @Input() getAllPartners = () => {}
  @Input() setFilter = (
    projId: string,
    projName: string,
    unitid: string,
    unit: string
  ) => {}
  @Input() formSubmitState: Observable<boolean>
  @Output() formValidationStatus: EventEmitter<boolean> =
    new EventEmitter<boolean>()
  projectList: Option[] = []
  propertyList: Option[] = []
  selectedProject: Option
  selectedUnit: Option
  formSubmitted: boolean = false

  projectFilterGroup = new FormGroup({
    project_name: new FormControl('', Validators.required),
    unit_type: new FormControl('', Validators.required),
  })

  constructor(
    private store: HasServicesStore,
    private servicesService: ServicesService
  ) {}

  ngOnInit(): void {
    this.formSubmitState?.subscribe(val => {
      this.formSubmitted = val
      if (this.formSubmitted) {
        this.projectFilterGroup.markAllAsTouched()
        if (this.projectFilterGroup.valid) {
          // console.log('emit true')
          this.formValidationStatus.emit(true)
        } else {
          // console.log('emit false')
          this.formValidationStatus.emit(false)
        }
      }
    })

    this.store.getState().subscribe(data => {
      this.projectList = data.projects
      this.propertyList = data.properties
    })
  }

  handleOnChange = (projectId: string) => {
    this.selectedProject = this.projectList.find(val => {
      return val.id === projectId
    })
    this.setFilter(
      this.selectedProject?.value,
      this.selectedProject?.label,
      this.selectedUnit?.value,
      this.selectedUnit?.label
    )
    this.projectFilterGroup.get('unit_type').setValue('')
    this.getUnitTypes(projectId)
  }

  handleGetPartners = unit_type_id => {
    this.selectedUnit = this.propertyList.find(val => {
      return val.id === unit_type_id
    })
    this.setFilter(
      this.selectedProject?.value,
      this.selectedProject?.label,
      this.selectedUnit?.value,
      this.selectedUnit?.label
    )
    if (unit_type_id != '' && unit_type_id != null) {
      this.getPartners(unit_type_id)
    } else {
      this.getAllPartners()
    }
  }
}
