<div class="partner-container">
  <div class="title">Host Partner</div>
  <div class="slider" *ngIf="hostData?.length > 0; else noDataContainer">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let slide of hostData" class="slider-card">
        <img src="{{ slide.avatar_url }}" alt="" width="100%" />
      </div>
    </ngx-slick-carousel>
    <div
      class="dir-btn left"
      (click)="slickModal.slickPrev()"
      [ngClass]="{ 'd-none': hostData.length <= 1 }">
      <img src="app/assets/arrow-left.svg" alt="" />
    </div>
    <div
      class="dir-btn right"
      (click)="slickModal.slickNext()"
      [ngClass]="{ 'd-none': hostData.length <= 1 }">
      <img src="app/assets/arrow-right.svg" alt="" />
    </div>
  </div>
  <ng-template #noDataContainer>
    <div class="empty-container">
      <span> No Host to Show </span>
    </div>
  </ng-template>
</div>
