<div class="card notif-container">
  <div class="d-flex justify-content-between flex-row align-items-center">
    <img class="info" src="app/assets/info-icon.svg" />
    <h2>
      You do not have an active unit subscription with us, you will be shown a
      sample dashboard report.
    </h2>
  </div>
  <button class="btn btn-primary" (click)="onClickNoUnitNotif()">
    <h3>Ok</h3>
  </button>
</div>
