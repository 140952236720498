import { Injectable } from '@angular/core'

import { BehaviorSubject } from 'rxjs'

@Injectable()
export class SidebarStore {
  //State
  private readonly _menuOpenSource = new BehaviorSubject<any>(false)

  readonly menuOpenState = this._menuOpenSource.asObservable()

  constructor() {}

  getMenuOpen(): boolean {
    return this._menuOpenSource.getValue()
  }
  toggleMenu = () => {
    // this._onboardingFormSource.next(data)
    const currentSource = this._menuOpenSource.getValue()
    this._menuOpenSource.next(!currentSource)
  }
}
