import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { PropertySummary } from 'src/app/modules/shared/models/dashboard.model'

@Component({
  selector: 'app-properties-for-rent',
  templateUrl: './properties-for-rent.component.html',
  styleUrls: ['./properties-for-rent.component.sass'],
})
export class PropertiesForRentComponent implements OnInit {
  @Input() propertySumState: Observable<PropertySummary>
  propForRent: number = 0

  constructor() {}

  ngOnInit(): void {
    this.propertySumState.subscribe(data => {
      if (data != null) {
        this.propForRent = data.for_rent
      }
    })
  }
}
