import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Services } from 'src/app/modules/shared/models/services.model'
import { ServicesService } from 'src/app/modules/shared/service/services.service'

@Component({
  selector: 'app-unit-rental-basic-card',
  templateUrl: './unit-rental.component.html',
  styleUrls: ['./unit-rental.component.sass'],
})
export class UnitRentalBasicCardComponent implements OnInit {
  constructor(
    private router: Router,
    private servicesService: ServicesService
  ) {}

  ngOnInit(): void {}

  bookReno() {
    // console.log('book a reno clicked')
    this.servicesService.getServices().then((resp: Services[]) => {
      let renoService = resp.find(val => {
        if (val.name.toLowerCase().includes('reno')) {
          return val.id
        }
      })
      this.router.navigate(['/has-services/reno', renoService.id])
    })
  }
}
