import { environment } from 'src/environments/environment'
import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { ReactiveFormsModule } from '@angular/forms'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AuthInterceptor } from './modules/core/auth/interceptors/auth.interceptor'

import { UserStore } from './modules/core/auth/store/user.store'

//Social Login
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login'
//Social Login

//States
// import { StoreModule } from '@ngrx/store';
// import { UserReducer } from './modules/shared/state/user/user.reducer'

//Pages Modules
import { AuthPageModule } from './modules/pages/auth/auth.module'
import { BarePageModule } from './modules/pages/bare/bare.module'
import { SecuredPageModule } from './modules/pages/secured/secured.module'
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap'
import { CustomToastComponent } from './modules/shared/component/custom-toast/custom-toast.component'

import * as Sentry from '@sentry/angular'
import { BrowserTracing } from '@sentry/tracing'
import { Router } from '@angular/router'

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [environment.baseUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
})

@NgModule({
  declarations: [AppComponent, CustomToastComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //Social Login
    SocialLoginModule,
    //Social Login
    // StoreModule.forRoot({user: UserReducer})
    //Pages Modules
    AuthPageModule,
    BarePageModule,
    SecuredPageModule,
    NgbToastModule,
  ],
  providers: [
    UserStore,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //Social Login
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleApiKey),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookApiKey),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    //Social Login

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
