import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SecuredStore } from '../../../store/secured.store'
import { StripeService, StripePaymentElementComponent } from 'ngx-stripe'
import { StripeElementsOptions, PaymentIntent } from '@stripe/stripe-js'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.sass'],
})
export class PaymentGatewayComponent implements OnInit {
  loading: boolean = true
  total: string = '0'
  orderId: string = ''
  isLoading: boolean = false

  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  }

  constructor(
    private route: ActivatedRoute,
    private securedStore: SecuredStore,
    private userStore: UserStore,
    private stripeService: StripeService,
    private router: Router,
    private toastService: CustomToastService
  ) {
    this.securedStore.updatePageTitle('Payment')
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap
    const token = queryParams.get('token')
    this.total = queryParams.get('price')
    this.orderId = queryParams.get('orderId')
    this.elementsOptions.clientSecret = token
    setTimeout(() => {
      this.loading = false
    }, 1500)
  }

  pay() {
    this.isLoading = true
    this.stripeService
      .confirmPayment({
        elements: this.paymentElement.elements,
        // confirmParams: {
        //    payment_method_data: {
        //      billing_details: {
        //         name: this.stripeTest.get('name').value
        //      }
        //    }
        // },
        redirect: 'if_required',
      })
      .subscribe({
        next: res => {
          this.isLoading = false
          if (res.error) {
            this.toastService.show('Error', res.error.message)
          } else {
            this.userStore.resetCart()
            this.router.navigate(['/checkout/payment-status'], {
              queryParams: {
                isSuccess: true,
                orderId: this.orderId,
                paymentAmount: this.total,
              },
            })
          }
        },
        error: err => {
          // alert(`err : ${err}`)
          this.isLoading = false
          this.router.navigate(['/checkout/payment-status'], {
            queryParams: { isSuccess: false },
          })
          console.log(err)
        },
      })
  }
}
