<div class="payment-status-container">
  <div class="mt-5 card" *ngIf="isSuccess; else failedPayment">
    <img src="app/assets/payment-complete.svg" alt="" />
    <h3 class="mb-2 mt-1">Payment Success!</h3>
    <span class="body-1 mb-4">Thankyou for your payment.</span>
    <div class="mt-1 mb-1 dashed-border"></div>
    <div class="order-detail">
      <p class="mb-1">Order ID</p>
      <p class="mb-1">{{ orderId || 0 }}</p>
    </div>
    <div class="order-detail">
      <p class="mb-1">Amount Paid</p>
      <p class="mb-1">
        {{ paymentAmount || 0 | currency: "MYR ":"symbol":"1.0-2" }}
      </p>
    </div>
    <button class="mt-5 btn btn-primary" (click)="redirectProperty()">
      Back My Property
    </button>
  </div>
  <ng-template #failedPayment>
    <div class="mt-5 card">
      <img src="app/assets/payment-not-completed.svg" alt="" />
      <h3 class="failed-title mb-2 mt-1">Payment Unsuccess!</h3>
      <span class="body-1 mb-2">Please contact us for further assistance.</span>
      <button class="mt-5 btn btn-primary" (click)="redirectCart()">
        Shopping Cart
      </button>
    </div>
  </ng-template>
</div>
