import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import {
  BankNames,
  BankNamesResponse,
  PatchUserData,
  UserProfile,
} from 'src/app/modules/shared/models/profile.model'
import { CustomModalService } from 'src/app/modules/shared/service/custom-modal.service'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { ProfileService } from 'src/app/modules/shared/service/profile.service'
import { SecuredStore } from '../../../store/secured.store'
import { ProfileStore } from '../../store/profile.store'
import { BehaviorSubject } from 'rxjs'
import { Option } from 'src/app/modules/shared/factory/option.factory'

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.sass'],
})
export class MyProfileComponent implements OnInit {
  isLoading: boolean = false
  isBppUser: boolean = false
  bankNameList: Option[]

  private readonly _isBppUserSource = new BehaviorSubject<boolean>(false)
  readonly isBppUserState = this._isBppUserSource.asObservable()

  constructor(
    private profileService: ProfileService,
    private profileStore: ProfileStore,
    private userStore: UserStore,
    private securedStore: SecuredStore,
    // private customModalService: CustomModalService,
    private toastService: CustomToastService
  ) {
    this.securedStore.updatePageTitle('My Profile')
  }

  ngOnInit(): void {
    this.profileService
      .getProfile()
      .then((res: UserProfile) => {
        this.profileStore.setState(res)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})

    if (this.userStore.getUser()?.user.role == 4) {
      this._isBppUserSource.next(true)
    } else {
      this._isBppUserSource.next(false)
    }

    // get bank names
    this.profileService
      .getBankNames()
      .then((res: BankNamesResponse) => {
        let massagedBankNames: BankNames = new BankNames(res)
        this.bankNameList = massagedBankNames.bank_names
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})

    // subscribe to isbppuser state
    this.isBppUserState.subscribe(val => {
      this.isBppUser = val
    })
  }

  onSave(
    profileCard,
    bankacc,
    notifCard,
    networkCard,
    passCard,
    investmentProfileCard
  ) {
    let currentuserData: UserProfile = this.profileStore.getStepsWithoutSub()
    let newuserData: PatchUserData = {
      email: profileCard.profileForm.get('email').value,
      name: profileCard.profileForm.get('fullname').value,
      avatar_id: profileCard.newAvatarUrl,
      password: passCard.passwordForm.get('newpassword').value,
      password_confirmation: passCard.passwordForm.get('confirmpassword').value,
      phone: profileCard.profileForm.get('phone').value,
      area_of_interests: profileCard.interestList,
      birth_date: profileCard.profileForm.get('birthday').value,
      notification_updates: notifCard.notifList[0].isActive,
      notification_financial_report: notifCard.notifList[1].isActive,
      notification_investment_opportunity: notifCard.notifList[2].isActive,
      notification_marketing_newsletter: notifCard.notifList[3].isActive,
      aggresiveness:
        investmentProfileCard.investmentFormgroup.get('aggresiveness').value,
      type_of_investment:
        investmentProfileCard.investmentFormgroup.get('invest_type').value,
      goals_setting:
        investmentProfileCard.investmentFormgroup.get('goals_setting').value,
    }

    // only for BPP users
    if (this.isBppUser) {
      // id card no
      newuserData.id_card_no = profileCard.id_card_no

      // bank details
      newuserData.bank_acc_name = bankacc.bank_acc_name
      newuserData.bank_acc_no = bankacc.bank_acc_no
      newuserData.bank_name = bankacc.bank_name
    }

    this.isLoading = true
    this.profileService
      .updateProfile(newuserData)
      .then(res => {
        // this.customModalService.ShowBasicModal(
        //   'Success',
        //   'Successfully Updated',
        //   'Ok'
        // )
        this.toastService.show('Success', 'Successfully updated data')
        let currUser = this.userStore.getUser()
        currUser.user.avatar_url = profileCard.profilePic
        this.userStore.setUser(currUser)
      })
      .catch(err => {
        // this.customModalService.ShowBasicModal('Error', err, 'Ok')
        this.toastService.show('Error', 'Data update error')
        console.log(err)
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
