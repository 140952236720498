export enum Constants {
  INCLUDING = 'include=',
  SEARCH = '?search[SEARCH_PARAM]=',
  FILTER = 'filter[FILTER_PARAM]=',
  PROJECT_ID_SEARCH = '?project_id=',
  HOSTS = 'hosts',
  UNIT_TYPES = 'unit_types',
  HOST = 'host',
  UNIT_TYPE = 'unit_type',
  IMAGES = 'images',
  DEVELOPER = 'developer',
  DEVELOPERS = 'developers',
  ADDITION = '&',
  EQUAL = '=',
  PROFILE = 'profile',
  ADDITIONAL_INFORMATIONS = 'additional_informations',
  RENOVATION_PARTNERS = 'renovation_partners',
  AGENTS = 'agents',
  DOCUMENTS = 'documents',
  KEY_COLLECTION_HIS = 'key_collection_history',
  DEFECT_AREAS = 'defects_areas',
  DEFECT_CHECK_HIS = 'defect_check_history',
  CONSTRUCTION_HIS = 'construction_history',
  RENOVATION_HIS = 'renovation_history',
  SOCIAL_MEDIA = 'social_media',
  STATUS_DEFECT_CHECK = 'Defect Check',
  STATUS_KEY_COLLECTION = 'Key Collection',
  STATUS_LIVE = 'Live',
  STATUS_NEW_LAUNCH = 'New Launch',
  STATUS_PENDING_APPROVAL = 'Pending Approval',
  STATUS_RENOVATION = 'Renovation',
  STATUS_SHORT_STAY_SETUP = 'Short Stay Setup',
  STATUS_SOLD = 'Sold',
  STATUS_UNDER_CONSTRUCTION = 'Under Construction',
  STATUS_SUB_EXPIRED = 'Subscription Expired',
  PACKAGES = 'packages',
  AGGRESIVENESS = 'aggresiveness',
  TYPE_OF_INVESTMENT = 'type_of_investment',
  GOALS_SETTING = 'goals_setting',
  REPORT = 'report',
  MARKET_TREND = 'market_trend',
  SHORTSTAY_MGMT_SERVICE = 'short-stay-management-service',
  RENO_PACKAGES = 'renovation-packages',
  SHORTSTAY_ESSENTIALS = 'short-stay-essentials',
  PERCENTAGE = 'percentage',
}
