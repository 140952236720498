import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { HostPartnerAgent } from 'src/app/modules/shared/models/project.model'

@Component({
  selector: 'app-host-partner',
  templateUrl: './host-partner.component.html',
  styleUrls: ['./host-partner.component.sass'],
})
export class HostPartnerComponent implements OnInit {
  @Input() hostDataState: Observable<HostPartnerAgent[]>
  hostData: HostPartnerAgent[]
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    infinite: false,
  }

  constructor() {}

  ngOnInit(): void {
    this.hostDataState.subscribe(data => {
      this.hostData = data
    })
  }

  slickInit(e) {
    // console.log('slick initialized')
  }

  breakpoint(e) {
    // console.log('breakpoint')
  }

  afterChange(e) {
    // console.log('afterChange')
  }

  beforeChange(e) {
    // console.log('beforeChange')
  }
}
