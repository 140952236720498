import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.sass'],
})
export class NotificationBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
