<app-custom-modal [modalSubmit]="onClickSellReq">
  <span modalTitle>List To Sell</span>
  <span modalBtn>Request</span>
  <form
    [formGroup]="listToSellForm"
    [ngClass]="{ 'has-errors': listToSellForm.invalid && submitted }">
    <!-- [ngClass]="{'has-errors': listToSellForm.invalid && submitted}"-->
    <div class="row mb-3">
      <div class="col-8">
        <custom-input
          [formGroup]="listToSellForm"
          id="sell_price"
          name="sell_price"
          type="text"
          label="Desired Selling Price">
        </custom-input>
      </div>
      <div class="col-4 btn-container">
        <button class="btn" (click)="onSetIndicativeMarketValue()">
          Get Indicative Market Value
        </button>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="get_help"
          formControlName="get_help" />
        <label class="form-check-label text-secondary" for="getHelp">
          Get help from Sales Consultant
        </label>
      </div>
    </div>
  </form>
</app-custom-modal>
