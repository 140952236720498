<div class="insurance-protection bg-white">
  <div class="title">Insurance Protection</div>
  <div *ngIf="insuranceData?.is_insured; else not_insured">
    <div class="image-section">
      <img src="app/assets/payment-complete.svg" />
      <div class="label text-success">You are covered by HostProtect</div>
    </div>
    <div class="insurance-details mt-3">
      <div class="detail-row">
        <div class="detail-column">Certificate No.</div>
        <div class="detail-column text-right">
          {{ insuranceData?.insurance_protection.cert_number }}
        </div>
      </div>
      <div class="detail-row">
        <div class="detail-column">Protection Period</div>
        <div class="detail-column text-right">
          {{
            insuranceData?.insurance_protection.start_date | date: "dd/MM/yyyy"
          }}
          -
          {{
            insuranceData?.insurance_protection.end_date | date: "dd/MM/yyyy"
          }}
        </div>
      </div>
      <div class="detail-row">
        <div class="detail-column">All Risk Master Policy</div>
        <div class="detail-column text-right">
          {{ insuranceData?.insurance_protection.master_policy_all_risk }}
        </div>
      </div>
      <div class="detail-row">
        <div class="detail-column">Public Liability Master Policy</div>
        <div class="detail-column text-right">
          {{
            insuranceData?.insurance_protection.master_policy_public_liability
          }}
        </div>
      </div>
      <div class="detail-row">
        <div class="detail-column">Insured Location</div>
        <div class="detail-column text-right">
          {{ insuranceData?.insurance_protection.location }}
        </div>
      </div>
    </div>
  </div>
  <ng-template #not_insured>
    <div class="image-section">
      <img src="app/assets/payment-not-completed.svg" />
      <div class="label text-danger">You are not covered by HostProtect</div>
    </div>
    <div class="info-section text-center text-secondary mt-3">
      This unit is not covered by HostProtect. You are risk of bearing the costs
      of guest damages and accidents
    </div>
    <div class="button-section mt-4">
      <button class="btn btn-primary" onclick="Chatra('openChat', true);">
        Host Protect Now
      </button>
    </div>
  </ng-template>
</div>
