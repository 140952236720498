import { Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CustomModalComponent } from '../component/custom-modal/custom-modal.component'

@Injectable({
  providedIn: 'root',
})
export class CustomModalService {
  constructor(private modalService: NgbModal) {}

  ShowBasicModal(title: string, desc: string, btnTxt: string, navUrl?: string) {
    const customModalRef = this.modalService.open(CustomModalComponent)
    customModalRef.componentInstance.MsgTitle = title
    customModalRef.componentInstance.MsgDesc = desc
    customModalRef.componentInstance.MsgBtn = btnTxt
    customModalRef.componentInstance.navUrl = navUrl
  }
}
