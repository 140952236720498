import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Observable } from 'rxjs'
import { RenoPartners } from 'src/app/modules/shared/models/services.model'
import { HasServicesStore } from '../../pages/store/has-services.store'

@Component({
  selector: 'app-reno-partners-grid',
  templateUrl: './reno-partners-grid.component.html',
  styleUrls: ['./reno-partners-grid.component.sass'],
})
export class RenoPartnersGridComponent implements OnInit {
  @Input() getPackages = (param: string) => {}
  dataList: RenoPartners[] = [
    {
      id: 'a7babff2-365f-48b7-93ed-3eb04f930e43',
      name: 'The Makeover Guys',
      phone_number: '',
      description: '',
      avatar_url: 'app/assets/make-over-guys.png',
    },
  ]

  constructor(private store: HasServicesStore) {}

  ngOnInit(): void {
    this.store.getState().subscribe(data => {
      this.dataList = data.partners
    })
  }
}
