import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { HasAccSetup } from 'src/app/modules/shared/models/dashboard.model'
import { SetupItem } from './model/setup-item.model'

@Component({
  selector: 'app-has-account-setup',
  templateUrl: './has-account-setup.component.html',
  styleUrls: ['./has-account-setup.component.sass'],
})
export class HasAccountSetupComponent implements OnInit {
  @Input() accSetupState: Observable<HasAccSetup[]>
  dropdownMenus: SetupItem[] = [
    {
      dropdownId: 'ddown-1',
      dropdownTitle: 'Update Your Profile',
      duration: 'About 5 minutes',
      navBtnTxt: 'My Profile',
      isOpened: false,
      isSkipped: false,
      navUrl: 'url',
      isDone: false,
    },
    {
      dropdownId: 'ddown-2',
      dropdownTitle: 'Update Your Profile',
      duration: 'About 5 minutes',
      navBtnTxt: 'My Profile',
      isOpened: false,
      isSkipped: false,
      navUrl: 'url',
      isDone: false,
    },
  ]

  completed: number = 0
  notCompleted: number = 0
  completionPercentage: number = 0

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.accSetupState.subscribe(data => {
      if (data) {
        let idx = 0
        this.dropdownMenus = []
        this.completed = 0
        this.notCompleted = 0
        for (let accsetup of data) {
          if (accsetup.is_completed) {
            this.completed++
          } else {
            this.notCompleted++
          }
          let setup: SetupItem = {
            dropdownId: 'ddown-' + idx,
            dropdownTitle: accsetup.label,
            duration: accsetup.time_needed,
            navBtnTxt: accsetup.btn_label,
            isOpened: false,
            isSkipped: false,
            navUrl: accsetup.nav_url,
            isDone: accsetup.is_completed,
          }
          this.dropdownMenus.push(setup)
          idx++
        }
        this.completionPercentage =
          (this.completed / (this.completed + this.notCompleted)) * 100
      }
    })
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    for (var i = 0; i < this.dropdownMenus.length; i++) {
      this.dropdownMenus[i].isOpened = false
    }
    var ddIdx = this.dropdownMenus.findIndex(
      obj => obj.dropdownId == $event.panelId
    )
    this.dropdownMenus[ddIdx].isOpened = $event.nextState
  }

  onNav(idx) {
    // console.log('Navigated to : ', this.dropdownMenus[idx].navUrl)
    this.router.navigate([this.dropdownMenus[idx].navUrl])
  }

  // onSkip(idx) {
  //   console.log('Skipped to idx: ', idx)
  //   // this.dropdownMenus[idx].isSkipped = true
  // }
}
