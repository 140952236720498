import { Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import {
  GetServicesResponse,
  Services,
} from 'src/app/modules/shared/models/services.model'
import { ServicesService } from 'src/app/modules/shared/service/services.service'
import { SecuredStore } from '../../../store/secured.store'

@Component({
  selector: 'app-has-services-listings',
  templateUrl: './has-services-listings.component.html',
  styleUrls: ['./has-services-listings.component.sass'],
})
export class HasServicesListingsComponent implements OnInit {
  private readonly _servicesListSource = new BehaviorSubject<Services[]>([])
  readonly servicesListState = this._servicesListSource.asObservable()

  constructor(
    private securedStore: SecuredStore,
    private servicesService: ServicesService
  ) {
    this.securedStore.updatePageTitle('HAS Services')
  }

  ngOnInit(): void {
    this.servicesService.getServices().then((resp: Services[]) => {
      this._servicesListSource.next(resp)
    })
  }
}
