import { flatten } from '@angular/compiler'
import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { KeyDelegationAttendees } from 'src/app/modules/shared/factory/option.factory'
import {
  KeyCollectionHistory,
  KeyDelegationReq,
} from 'src/app/modules/shared/models/property.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import { KeyCollectModel } from './model/key-collect.model'

@Component({
  selector: 'app-key-collect',
  templateUrl: './key-collect.component.html',
  styleUrls: ['./key-collect.component.sass'],
})
export class KeyCollectComponent implements OnInit {
  @Input() keyCollectionState: Observable<KeyCollectModel>
  @Input() populateCallback = () => {}
  keyCollectionData: KeyCollectModel
  submitted: boolean = false
  isLoading: boolean = false

  onBehalf: boolean = false
  formSubmitted: boolean = false
  isCollectorAgent: boolean = false

  collection_address: string = ''

  keyCollectionAttendees = KeyDelegationAttendees

  keyCollectionForm = new FormGroup({
    collector_subject: new FormControl('', Validators.required),
    collection_date: new FormControl('', Validators.required),
    collection_time: new FormControl('', Validators.required),
    name: new FormControl(''),
    contact_number: new FormControl(''),
  })

  constructor(
    private propertyService: PropertiesService,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {
    this.keyCollectionState.subscribe(data => {
      this.keyCollectionData = data
      if (
        this.keyCollectionData.keyCollectionDateTime != '' &&
        this.keyCollectionData.keyCollectionDateTime
      ) {
        this.formSubmitted = true
        if (this.keyCollectionData.key_collector == 'Appoint HAS Personnel') {
          this.isCollectorAgent = true
        }
      }
    })

    this.keyCollectionForm
      .get('collector_subject')
      .valueChanges.subscribe(val => {
        if (val == 'On Behalf') {
          this.onBehalf = true
          this.keyCollectionForm.get('name').setValidators(Validators.required)
          this.keyCollectionForm
            .get('contact_number')
            .setValidators(Validators.required)
        } else {
          this.onBehalf = false
          this.keyCollectionForm.get('name').clearValidators()
          this.keyCollectionForm.get('contact_number').clearValidators()
        }
      })
  }

  onSchedule() {
    this.submitted = true
    this.keyCollectionForm.markAllAsTouched()
    if (this.keyCollectionForm.valid) {
      let req: KeyDelegationReq = {
        who_is_coming: this.keyCollectionForm.get('collector_subject').value,
        coming_date:
          this.keyCollectionForm.get('collection_date').value +
          ' ' +
          this.keyCollectionForm.get('collection_time').value,
        name: this.keyCollectionForm.get('name').value,
        contact_number: this.keyCollectionForm.get('contact_number').value,
      }

      this.isLoading = true
      this.propertyService
        .keyDelegation(this.keyCollectionData.propertyId, req)
        .then(res => {
          this.toastService.show('Success', 'Key collection scheduled')
          this.populateCallback()
        })
        .catch(err => {
          this.toastService.show('Error', 'Key collection schedule error')
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
