import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from 'src/app/app-routing.module'

import { AuthComponent } from './auth.component'

import { LoginComponent } from './login/login.component'
import { LoginFormComponent } from './login/component/login-form/login-form.component'

import { SignUpComponent } from './sign-up/sign-up.component'
import { SignUpFormComponent } from './sign-up/component/sign-up-form/sign-up-form.component'

import { VerifyComponent } from './verify/verify.component'

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { ForgotPasswordFormComponent } from './forgot-password/component/forgot-password-form/forgot-password-form.component'

import { NewPasswordComponent } from './new-password/new-password.component'
import { NewPasswordFormComponent } from './new-password/component/new-password-form/new-password-form.component'

import { LoginSocialMediaComponent } from './login/component/login-social-media/login-social-media.component'

@NgModule({
  imports: [CommonModule, BrowserModule, AppRoutingModule, ReactiveFormsModule],
  declarations: [
    AuthComponent,
    LoginComponent,
    LoginFormComponent,
    SignUpComponent,
    SignUpFormComponent,
    VerifyComponent,
    ForgotPasswordComponent,
    ForgotPasswordFormComponent,
    NewPasswordComponent,
    NewPasswordFormComponent,
    LoginSocialMediaComponent,
  ],
  exports: [
    AuthComponent,
    LoginComponent,
    LoginFormComponent,
    SignUpComponent,
    SignUpFormComponent,
    VerifyComponent,
    ForgotPasswordComponent,
    ForgotPasswordFormComponent,
    NewPasswordComponent,
    NewPasswordFormComponent,
    LoginSocialMediaComponent,
  ],
})
export class AuthPageModule {}
