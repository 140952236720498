<div class="sidebar-container" [ngClass]="{ open: menuOpen }">
  <div class="navigation-section">
    <img class="navigation-logo" src="app/assets/navigation-beta-logo.svg" />
    <div class="navigation-profile d-flex d-md-none">
      <div class="profile-picture me-2">
        <img src="app/assets/pp_180x180.png" />
      </div>
      <div class="profile-name">
        {{ userEmail }}
      </div>
    </div>
    <div class="navigation-container">
      <div class="navigation-title">Personal</div>
      <a
        *ngFor="let page of pageList"
        class="navigation"
        routerLink="{{ page.url }}"
        routerLinkActive="active">
        <svg-icon src="{{ page.icon }}"></svg-icon>
        {{ page.label }}
        <div class="dot"></div>
      </a>
    </div>
    <div class="navigation-container">
      <div class="navigation-title">HostAStay</div>
      <a
        *ngFor="let page of hostAStayPageList"
        class="navigation"
        routerLink="{{ page.url }}"
        routerLinkActive="active">
        <svg-icon src="{{ page.icon }}"></svg-icon>
        {{ page.label }}
        <div class="dot"></div>
      </a>
    </div>
  </div>
  <div class="logout-link-section d-block d-md-none">
    <a class="navigation" href="#" (click)="logout($event)">
      <svg-icon src="app/assets/logout.svg"></svg-icon>
      Log out
    </a>
  </div>
  <!-- <div class="image-link-section">
        <img src="app/assets/subscription.svg">
    </div> -->
  <div class="tnc-link-section">
    <a routerLink="/terms-and-conditions">Terms and Condition</a>
    <a routerLink="/privacy-policy">Privacy & Policy</a>
  </div>
</div>
<!-- <div class="mobile-toggle" (click)="toggleMobileMenu()">
    <svg-icon src="app/assets/menu.svg"></svg-icon>
</div> -->
<div class="overlay" (click)="toggleMobileMenu()"></div>
