<div class="has-account-setup card">
  <div class="title">Setup Your HAS Account</div>
  <div class="empty-bar mt-1">
    <div
      class="percentage-bar"
      [ngStyle]="{ width: completionPercentage + '%' }"></div>
  </div>
  <span class="percentage-desc mt-1"
    >{{ completionPercentage }}% Complete ({{ notCompleted }} task left)</span
  >
  <div class="setup-accordion mt-2">
    <ngb-accordion [closeOthers]="true" (panelChange)="beforeChange($event)">
      <ngb-panel
        [id]="menu.dropdownId"
        [disabled]="menu.isDone"
        *ngFor="let menu of dropdownMenus; let idx = index">
        <ng-template ngbPanelTitle class="header-container">
          <img
            class="pe-2 icon-success"
            src="app/assets/checkmark.svg"
            *ngIf="menu.isDone; else notDone" />
          <ng-template #notDone>
            <img
              class="chevron-img"
              src="app/assets/chevron-right.svg"
              [ngClass]="{ imgactive: menu.isOpened }" />
          </ng-template>
          <div class="drop-header">
            <div class="drop-header-title" [ngClass]="{ crossed: menu.isDone }">
              {{ menu.dropdownTitle }}
            </div>
            <div class="drop-header-time" *ngIf="!menu.isDone">
              {{ menu.duration }}
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent class="dropdown-content">
          <button class="btn btn-primary" (click)="onNav(idx)">
            {{ menu.navBtnTxt }}
          </button>
          <!-- <a (click)="onSkip(idx)">Skip for now</a> -->
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
