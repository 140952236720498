import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.sass'],
})
export class SignUpComponent implements OnInit {
  private readonly _referralCodeSource = new BehaviorSubject<string>('')
  readonly referralCodeState = this._referralCodeSource.asObservable()

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParam => {
      if (queryParam.get('referral')) {
        this._referralCodeSource.next(queryParam.get('referral'))
      }
    })
  }
}
