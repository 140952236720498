import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ApexOptions, ChartComponent } from 'ng-apexcharts'
import { Observable } from 'rxjs'
import { EarningsOverview } from 'src/app/modules/shared/models/dashboard.model'

@Component({
  selector: 'app-property-revenue-overview',
  templateUrl: './property-revenue-overview.component.html',
  styleUrls: ['./property-revenue-overview.component.sass'],
})
export class PropertyRevenueOverviewComponent implements OnInit {
  @Input() revenueOverviewState: Observable<EarningsOverview>
  @Input() graphYear: number
  @ViewChild('chart') chart: ChartComponent
  public chartOptions: Partial<ApexOptions>
  avgTotalEarnings: number = 0
  avgMonthlyOccRate: number = 0

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: 'Earnings',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      colors: ['#00B6CD'],
      chart: {
        height: 180,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2'],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
        ],
        tickPlacement: 'on',
      },
    }
  }

  ngOnInit(): void {
    this.revenueOverviewState.subscribe((data: EarningsOverview) => {
      if (data != null) {
        this.avgMonthlyOccRate = data.monthly_avg_occupancy_rate
          ? data.monthly_avg_occupancy_rate
          : 0
        this.avgTotalEarnings = data.monthly_avg_earnings
          ? data.monthly_avg_earnings
          : 0

        let keys = Object.keys(data.current_year_earnings)
        let values: number[] = []
        for (let val of Object.values(data.current_year_earnings)) {
          values.push(Math.round((Number(val) + Number.EPSILON) * 100) / 100)
        }
        this.chartOptions = {
          series: [
            {
              name: 'Earning',
              data: values,
            },
          ],
          colors: ['#00B6CD'],
          chart: {
            height: 180,
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 2,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories: keys,
            tickPlacement: 'on',
          },
        }
      }
    })
  }
}
