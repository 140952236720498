import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-demo-data-notification',
  templateUrl: './demo-data-notification.component.html',
  styleUrls: ['./demo-data-notification.component.sass'],
})
export class DemoDataNotificationComponent implements OnInit {
  @Input() onClickNoUnitNotif = () => {}

  constructor() {}

  ngOnInit(): void {}
}
