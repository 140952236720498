<div class="property-overview">
  <div class="image-slider">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let slide of property?.images">
        <img src="{{ slide.url }}" alt="" width="100%" class="slider-card" />
      </div>
    </ngx-slick-carousel>
    <div
      class="dir-btn left"
      (click)="slickModal.slickPrev()"
      [ngClass]="{ 'd-none': property?.images.length <= 1 }">
      <img src="app/assets/arrow-left.svg" alt="" />
    </div>
    <div
      class="dir-btn right"
      (click)="slickModal.slickNext()"
      [ngClass]="{ 'd-none': property?.images.length <= 1 }">
      <img src="app/assets/arrow-right.svg" alt="" />
    </div>
  </div>
  <div class="property-overview-content">
    <div class="overview-information-container">
      <div class="overview-information">
        <div class="project-name">{{ property?.project_name || "-" }}</div>
        <div class="project-location">
          {{ property?.project_location || "-" }}
        </div>
        <div class="project-unit">{{ property?.unit_number || "-" }}</div>
        <div class="project-tenancy">{{ property?.category || "-" }}</div>
        <div class="project-package-type" *ngIf="!isBppUser">
          Package Type : {{ property?.package_type || "-" }}
        </div>
        <div class="project-revenue-share" *ngIf="!isBppUser">
          Revenue Share : {{ property?.revenue_share || "-" }}
        </div>
        <div class="project-unit-spa" *ngIf="isBppUser">
          Unit SPA (MYR) :
          {{ property?.unit_spa | currency: " ":"symbol":"1.2" }}
        </div>
        <div class="project-parking-lots" *ngIf="isBppUser">
          Parking Lot No : {{ property?.parking_lots_no }}
        </div>
        <div class="project-commencement-date" *ngIf="isBppUser">
          Commencement Date : {{ property.commencement_date }}
        </div>
        <div class="facility-container mt-2 mb-1">
          <img src="app/assets/bed-icon.svg" />
          <span>{{ property?.bedroom || "-" }}</span>
          <img src="app/assets/bathroom-icon.svg" />
          <span>{{ property?.bathroom || "-" }}</span>
          <img src="app/assets/personnel-icon.svg" />
          <span>{{ property?.max_people || "-" }}</span>
          <img src="app/assets/parking-icon.svg" *ngIf="isBppUser" />
          <span *ngIf="isBppUser">{{ property?.parking_amount || "-" }}</span>
        </div>
      </div>
      <div class="dropdown">
        <button
          type="button"
          class="btn btn-outline-primary dropdown-toggle"
          (click)="toggleMenu('modify')">
          Modify
          <!-- <img src="app/assets/chevron-down-blue.svg"> -->
        </button>
        <ul class="dropdown-menu" [ngClass]="{ show: dropdownObj.modify.show }">
          <li>
            <a class="dropdown-item" (click)="openModal('edit')"
              >Edit Category</a
            >
          </li>
          <li>
            <a class="dropdown-item" (click)="showDefectCard()"
              >Defects Report</a
            >
          </li>
          <li>
            <a class="dropdown-item" (click)="openModal('list_to_sell')"
              >List to Sell</a
            >
          </li>
          <li>
            <a class="dropdown-item" (click)="openModal('remove_from_list')"
              >Remove Listing</a
            >
          </li>
          <li>
            <a class="dropdown-item" (click)="openModal('edit_status')"
              >Edit Status</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="project-tags flex-wrap">
      <div
        class="tag"
        [ngClass]="{
          statusLive: property?.status == statusLive,
          statusDefectCheck: property?.status == statusDefectCheck,
          statusKeyCollection: property?.status == statusKeyCollection,
          statusPendingApproval: property?.status == statusPendingApproval,
          statusRenovation: property?.status == statusRenovation,
          statusShortStaySetup: property?.status == statusShortStaySetup,
          statusSold: property?.status == statusSold,
          statusUnderConstruction: property?.status == statusUnderConstruction,
          statusNewLaunch: property?.status == statusNewLaunch,
          statusSubExpired: property?.status == statusSubExpired
        }">
        {{ property?.status }}
      </div>
      <div class="tag" *ngFor="let tag of property?.tags">{{ tag }}</div>
    </div>
  </div>
</div>
