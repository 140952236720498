<div class="filter-container">
  <form
    [formGroup]="projectFilterGroup"
    [ngClass]="{ 'has-errors': projectFilterGroup.invalid && formSubmitted }">
    <div class="row">
      <div class="col-12 col-sm-6">
        <custom-input
          [formGroup]="projectFilterGroup"
          label="Project"
          id="project_name"
          name="project_name"
          type="select"
          placeholder="Project Name"
          [valueList]="projectList"
          [handleOnChange]="handleOnChange"></custom-input>
      </div>
      <div class="col-12 col-sm-6">
        <custom-input
          [formGroup]="projectFilterGroup"
          label="Unit Type"
          id="unit_type"
          name="unit_type"
          type="select"
          placeholder="Unit Type"
          [valueList]="propertyList"
          [handleOnChange]="handleGetPartners"></custom-input>
      </div>
    </div>
  </form>
</div>
