<div class="password-card">
  <div class="title">Password</div>
  <form [formGroup]="passwordForm" class="profile-form">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="passwordForm"
          id="newpassword"
          name="newpassword"
          label="New Password"
          type="password"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="passwordForm"
          id="confirmpassword"
          name="confirmpassword"
          label="Confirm Password"
          type="password"></custom-input>
      </div>
    </div>
  </form>
</div>
