import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'

export class CustomValidators {
  public static readonly REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  public static readonly REGEX_IC_NUMBER =
    /(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})/

  static email(control: AbstractControl): any | null {
    return CustomValidators.pattern(
      'email',
      CustomValidators.REGEX_EMAIL
    )(control)
  }

  static icNumber(control: AbstractControl): any | null {
    return CustomValidators.pattern(
      'icNumber',
      CustomValidators.REGEX_IC_NUMBER
    )(control)
  }

  static pattern(type: string, regexPattern: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null
      }

      return !regexPattern.test(control.value) ? { [type]: true } : null
    }
  }

  static compare(target: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (!control || !control.parent) {
        return null
      }

      let comparedField = control.parent.get(target)
      let comparedFieldError = comparedField.errors
      if (comparedField.value != control.value) {
        comparedField.setErrors({ ...comparedFieldError, notSame: true })
        return { notSame: true }
      } else {
        delete comparedFieldError?.notSame
        comparedField.setErrors(comparedFieldError)
        return null
      }
    }
  }

  static comparePassword: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password')
    const confirm_password = control.get('confirm_password')

    if (password.value != confirm_password.value) {
      return { notSame: true }
    }

    return null
  }
}
