import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { ProfileStore } from '../../store/profile.store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.sass'],
})
export class BankAccountComponent implements OnInit {
  profileSubscriber: Subscription

  bankAccForm = new FormGroup({
    bank_acc_name: new FormControl(''),
    bank_name: new FormControl(''),
    bank_acc_no: new FormControl(''),
  })

  @Input() bankNameList: Option[]

  constructor(private profileStore: ProfileStore) {}

  ngOnInit(): void {
    this.profileSubscriber = this.profileStore.getState().subscribe(res => {
      this.bankAccForm
        .get('bank_acc_name')
        .setValue(res.data.profile.bank_acc_name)
      this.bankAccForm.get('bank_name').setValue(res.data.profile.bank_name)
      this.bankAccForm.get('bank_acc_no').setValue(res.data.profile.bank_acc_no)
    })
  }
}
