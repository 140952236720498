export enum Endpoints {
  LOGIN = '/auth/login',
  REGISTER = '/auth/register',
  SOCIAL_CONNECT = '/auth/social-connect',
  SOCIAL_DISCONNECT = '/auth/social-disconnect',
  RETRIEVE_PORTFOLIOS = '/portfolios',
  RETRIEVE_PORTFOLIOS_DOCUMENTS = '/agreements/[DOCUMENT_ID]/document',
  RETRIEVE_ACCOUNT_DETAILS = '/portfolios/[PORTFOLIO_ID]?include=user',
  UPDATE_ACCOUNT_DETAILS = '/portfolios/[PORTFOLIO_ID]/account',
  RETRIEVE_PROPERTY_DETAILS = '/portfolios/[PORTFOLIO_ID]?include=project',
  UPDATE_UNIT_LIST = '/portfolios/[PORTFOLIO_ID]/units',
  TRIGGER_PAYMENT = '/portfolios/[PORTFOLIO_ID]/payment',
  TRIGGER_AGREEMENT_CHECKING = '/portfolios/[PORTFOLIO_ID]/agreements',
  RETRIEVE_UNIT_DETAILS = '/portfolios/[PORTFOLIO_ID]/units/[UNIT_NO]',
  GET_PROJECTS = '/projects',
  GET_PROPERTIES = '/properties',
  ADD_PROPERTY = '/properties/store',
  AUTH_ME = '/auth/me',
  BANK_NAMES = '/auth/bank-names',
  ADD_CUSTOM_PROPERTY = '/custom-properties/store',
  CHANGE_PASSWORD = '/auth/change-password',
  FORGOT_PASSWORD = '/auth/reset-password',
  SIGNEASY_LIST = '/original',
  SIGNEASY_DOCSIGN = '/me/embedded/url',
  FILE_UPLOAD = '/files',
  TRANSACTIONS = '/transactions',
  PAYMENTS = '/payments',
  SERVICES = '/services',
  RENOVATION_PARTNERS = '/renovation-partners',
  SELL_REQUEST = '/properties/[PROPERTY_ID]/sell-request',
  PROPERTY_CATEGORIES = '/property-categories',
  HOSTS = '/hosts',
  UPDATE_CATEGORY = '/properties/[PROPERTY_ID]/edit-category',
  EDIT_STATUS = '/properties/[PROPERTY_ID]/edit-status',
  REMOVAL_REQUEST = '/properties/[PROPERTY_ID]/removal-request',
  KEY_DELEGATION = '/properties/[PROPERTY_ID]/key-delegation/create',
  LODGE_DEFECT = '/properties/[PROPERTY_ID]/defect-check/create',
  DEFECT_PATCH = '/properties/[PROPERTY_ID]/defect-check/[DEFECT_ID]',
  UNIT_TYPES = '/unit-types',
  UNIT_TYPE_DETAIL = '/unit-types/[UNIT_TYPE_ID]',
  RENOVATION_PARTNER_DETAIL = '/renovation-partners/[PARTNER_ID]',
  ORDERS = '/orders',
  UNDER_CONST_REPORT = '/properties/[PROPERTY_ID]/reports/under-construction',
  ADD_FAVORITE = '/projects/handle-favorite',
  DASHBOARD = '/dashboard',
  DASHBOARD_DEMO = '/demo/dashboard/[YEAR]/[MONTH]',
  ACC_SETUP_STATUS_UPD = '/account-setup',
  SHORTSTAY_MGMT_SERVICES = '/short-stay-management-services',
  PROMO_CODE = '/promotion-codes',
}
