import { Component, OnInit } from '@angular/core'
import { SafeResourceUrl } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { UserStore } from '../../../core/auth/store/user.store'

@Component({
  selector: 'app-mini-menu',
  templateUrl: './mini-menu.component.html',
  styleUrls: ['./mini-menu.component.sass'],
})
export class MiniMenuComponent implements OnInit {
  dropdownObj = {
    notification: {
      show: false,
    },
    miniMenu: {
      show: false,
    },
  }

  avatar: SafeResourceUrl = 'app/assets/pp_180x180.png'
  userEmail: string
  cartItemCount: number = 0
  userId: number

  constructor(private router: Router, private userStore: UserStore) {}

  ngOnInit(): void {
    this.userStore.getUser()

    this.userStore.userState.subscribe(res => {
      if (
        res != null &&
        res.user.avatar_url != null &&
        res.user.avatar_url.toString() != ''
      ) {
        this.avatar = res.user.avatar_url
      }
      this.userEmail = res?.user.email
      this.userId = res?.user.id
    })

    this.userStore.subCart().subscribe(carts => {
      let userCart = carts?.find(cart => {
        if (cart.user.id == this.userId) {
          return cart
        }
      })

      this.cartItemCount = userCart?.item_count <= 0 ? 0 : userCart?.item_count
    })
  }

  toggleMiniMenu = menu => {
    this.dropdownObj[menu].show = !this.dropdownObj[menu].show
  }

  goToCheckoutPage = () => {
    this.router.navigate(['/checkout'])
  }

  logout = e => {
    e.preventDefault()
    this.userStore.logoutUser()
  }
}
