import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import { DashboardApiRes } from '../models/dashboard.model'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  async getDashboardData(month, year) {
    let endpoint = new ApiFactory(Endpoints.DASHBOARD)
      .filter(month, 'month')
      .filter(year, 'year')
      .toString()
    return new Promise((resolve, reject) => {
      this.http
        .get<DashboardApiRes>(`${environment.baseUrl}${endpoint}`)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getDashboardDemoData({ month, year }) {
    let endpoint = Endpoints.DASHBOARD_DEMO
      .replace('[YEAR]', year)
      .replace('[MONTH]', month)
    return new Promise((resolve, reject) => {
      this.http
        .get<DashboardApiRes>(`${environment.baseUrl}${endpoint}`)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async updateSetupStatus(setupId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.baseUrl}${Endpoints.ACC_SETUP_STATUS_UPD}`, {
          action: setupId,
        })
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
