import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs/internal/Observable'
import { Project, ProjectFilter } from '../../models/project.model'
import {
  Option,
  getStateList,
  getProjectTypeList,
  getTenancyTypeList,
  getProjectStatusList,
} from 'src/app/modules/shared/factory/option.factory'
import { Constants } from '../../enums/constants.enum'
import * as $ from 'jquery'

@Component({
  selector: 'app-project-grid',
  templateUrl: './project-grid.component.html',
  styleUrls: ['./project-grid.component.sass'],
})
export class ProjectGridComponent implements OnInit {
  @Input() onCardClick: (id: string) => void
  @Input() showAddCustomProjectCard: boolean = false
  @Input() dataSourceState: Observable<Project[]>
  @Input() refreshData: (filterConfigStr: string) => void
  dataSource: Project[]

  stateList: Option[] = getStateList()
  projectTypeList: Option[] = getProjectTypeList()
  tenancyTypeList: Option[] = getTenancyTypeList()
  projectStatusList: Option[] = getProjectStatusList()

  filterOpen: boolean = false

  activeFilter: ProjectFilter = {
    state: [],
    projectType: [],
    tenancyType: [],
    projectStatus: [],
  }

  filterConfig: ProjectFilter = {
    state: [],
    projectType: [],
    tenancyType: [],
    projectStatus: [],
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.dataSourceState.subscribe(data => (this.dataSource = data))
  }

  addCustomProject() {
    this.router.navigate([
      '/my-properties/own-project-list/add',
      { isHasProject: false },
    ])
  }

  toggleCheckbox = (target: string, value: string) => {
    if (this.filterConfig[target].includes(value)) {
      const updatedList = this.filterConfig[target].filter(f => f != value)
      this.filterConfig[target] = updatedList
    } else {
      this.filterConfig[target].push(value)
    }
  }

  applyFilter = () => {
    //Attempting deep copy
    for (let key in this.filterConfig) {
      this.activeFilter[key] = this.filterConfig[key].map(f => f)
    }
    let filterConfigStr = ''
    if (this.activeFilter) {
      if (this.activeFilter.state.length > 0) {
        filterConfigStr +=
          Constants.ADDITION +
          'search[state]' +
          Constants.EQUAL +
          this.activeFilter.state.toString()
      }
      if (this.activeFilter.projectType.length > 0) {
        filterConfigStr +=
          Constants.ADDITION +
          'search[type]' +
          Constants.EQUAL +
          this.activeFilter.projectType.toString()
      }
      if (this.activeFilter.tenancyType.length > 0) {
        filterConfigStr +=
          Constants.ADDITION +
          'search[tenancy]' +
          Constants.EQUAL +
          this.activeFilter.tenancyType.toString()
      }
      if (this.activeFilter.projectStatus.length > 0) {
        filterConfigStr +=
          Constants.ADDITION +
          'search[status]' +
          Constants.EQUAL +
          this.activeFilter.projectStatus.toString()
      }
    }
    this.refreshData(filterConfigStr)

    this.toggleFilter()
  }

  toggleFilter = () => {
    this.filterOpen = !this.filterOpen
    if (this.filterOpen) {
      $('body').addClass('mobile-overflow-hidden')
    } else {
      $('body').removeClass('mobile-overflow-hidden')
    }
  }
}
