import { Component, Input, OnInit } from '@angular/core'
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms'
import { Router } from '@angular/router'

import { AuthService } from 'src/app/modules/shared/service/auth.service'

import { CustomValidators } from 'src/app/modules/shared/validators/validators'
import { Observable } from 'rxjs'

export interface ShowPassword {
  password: boolean
  confirm_password: boolean
}

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.sass'],
  providers: [AuthService],
})
export class SignUpFormComponent implements OnInit {
  @Input() referralCodeState: Observable<string>
  disableField: boolean = false

  submitted: boolean = false
  isLoading: boolean = false
  showPass: ShowPassword = {
    password: false,
    confirm_password: false,
  }

  signUpForm = new FormGroup(
    {
      email: new FormControl('', [Validators.required, CustomValidators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirm_password: new FormControl('', [Validators.required]),
      referralCode: new FormControl(''),
    },
    { validators: CustomValidators.comparePassword }
  )

  errors = {
    required: 'Email Address and Password can’t be blank',
    invalidEmail: 'Email Address is invalid',
    passwordLength: 'Password to short (minimum is 6 character)',
    notSame: 'Password does not match',
    emailTaken: '',
  }

  errObject = {
    message: '',
  }

  generateErrMessage: any = () => {
    if (
      this.signUpForm.get('email').errors?.required ||
      this.signUpForm.get('password').errors?.required ||
      this.signUpForm.get('confirm_password').errors?.required
    ) {
      this.errObject.message = this.errors.required
    } else if (this.signUpForm.get('email').errors?.email) {
      this.errObject.message = this.errors.invalidEmail
    } else if (this.signUpForm.get('password').errors?.minlength) {
      this.errObject.message = this.errors.passwordLength
    } else if (
      this.signUpForm.errors?.notSame ||
      this.signUpForm.errors?.notSame
    ) {
      this.errObject.message = this.errors.notSame
    }
  }

  constructor(private router: Router, private authService: AuthService) {
    this.signUpForm.valueChanges.subscribe(val => {
      this.generateErrMessage()
    })
  }

  ngOnInit(): void {
    this.referralCodeState.subscribe(data => {
      if (data) {
        this.signUpForm.get('referralCode').setValue(data)
        this.disableField = true
      }
    })
  }

  showHidePassword(passId) {
    if (passId == 'password') {
      this.showPass.password = !this.showPass.password
    } else {
      this.showPass.confirm_password = !this.showPass.confirm_password
    }
  }

  onSubmit = e => {
    e.preventDefault()
    this.generateErrMessage()

    this.submitted = true
    this.signUpForm.markAllAsTouched()

    // Object.values(this.signUpForm.controls).forEach(control => {
    //   control.markAsTouched()
    // })
    if (this.signUpForm.valid) {
      this.isLoading = true
      this.authService
        .register(
          this.signUpForm.value.email,
          this.signUpForm.value.password,
          this.signUpForm.value.confirm_password,
          this.signUpForm.value.referralCode
        )
        .then(() => {
          this.router.navigate([`home`])
        })
        .catch(err => {
          if (err.status == 422) {
            this.errObject.message = err.error.email[0]
            this.signUpForm.setErrors({
              emailTaken: true,
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
