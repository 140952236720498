<div class="checkout-container">
  <div class="row gy-3">
    <div class="col-12 col-xl-9">
      <div class="unit-checkout">
        <div *ngIf="items?.length > 0; else emptyCart">
          <div class="unit-container" *ngFor="let projectItem of items">
            <div *ngFor="let unitItem of projectItem?.unit">
              <div class="unit-title mb-3">
                <h2 class="unit-name">{{ projectItem?.project_name }}</h2>
                <h2 class="unit-type" *ngIf="unitItem?.unit_type !== ''">
                  {{ unitItem?.unit_type }}
                </h2>
              </div>
              <div *ngFor="let serviceItem of unitItem?.service">
                <div *ngFor="let purchasedPackage of serviceItem?.package">
                  <app-cart-item-card
                    [projectId]="projectItem?.project_id"
                    [unitId]="unitItem?.unit_type_id"
                    [serviceId]="serviceItem?.service_id"
                    [serviceName]="serviceItem?.service_name"
                    [packagePrice]="purchasedPackage?.price"
                    [packageName]="purchasedPackage?.name"
                    [packageDesc]="purchasedPackage?.description"
                    [removeFunc]="onRemovePackage"></app-cart-item-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #emptyCart>
          <div class="empty-container">
            <span>Your shopping cart is empty</span>
          </div>
        </ng-template>
      </div>
      <div class="others-checkout"></div>
    </div>
    <div class="col-12 col-xl-3">
      <app-order-summary
        [checkoutDataState]="checkoutDataState"
        [orderFunc]="addOrder"
        [getPromoFunc]="getPromo"
        [promoCodeState]="promoCodeState"
        [loadingState]="loadingState"></app-order-summary>
    </div>
  </div>
</div>
