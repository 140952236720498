<div [ngSwitch]="type" [formGroup]="form">
  <label
    for="{{ id }}"
    class="form-label"
    [ngClass]="{ 'display-none': label == '' }"
    >{{ label }}{{ mandatory ? "*" : "" }}</label
  >
  <!-- TEXT FIELD -->
  <input
    *ngSwitchCase="'text'"
    id="{{ id }}"
    type="{{ type }}"
    class="form-control"
    formControlName="{{ name }}"
    placeholder="{{ placeholder }}"
    [attr.disabled]="disableField ? '' : null"
    (keyup)="onKey($event)"
    (change)="onChange()" /><!--(keyup.enter)="{{ keyUpFunc }}"-->
  <!-- EMAIL FIELD -->
  <input
    *ngSwitchCase="'email'"
    id="{{ id }}"
    type="{{ type }}"
    class="form-control"
    formControlName="{{ name }}"
    placeholder="{{ placeholder }}"
    [attr.disabled]="disableField ? '' : null"
    (keyup)="onKey($event)"
    (change)="onChange()" />
  <!-- SELECT OPTION FIELD -->
  <select
    *ngSwitchCase="'select'"
    id="{{ id }}"
    class="form-select"
    formControlName="{{ name }}"
    [attr.disabled]="disableField ? '' : null"
    (change)="onChange()">
    <option [ngValue]="''" selected>Please Select</option>
    <option *ngFor="let value of valueList" [ngValue]="value.value">
      {{ value.label }}
    </option>
  </select>
  <!-- DATE FIELD -->
  <input type="hidden" formControlName="{{ name }}" *ngSwitchCase="'date'" />
  <input
    id="{{ id }}"
    type="text"
    class="form-control"
    value="{{ defaultDateValue }}"
    placeholder="{{ placeholder }}"
    [attr.disabled]="disableField ? '' : null"
    ngbDatepicker
    #d="ngbDatepicker"
    (click)="d.toggle()"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (dateSelect)="onDateSelected($event)"
    *ngSwitchCase="'date'" />
  <!-- TEXT AREA FIELD -->
  <div class="custom-textarea" *ngSwitchCase="'textarea'">
    <textarea
      id="{{ id }}"
      class="form-control"
      formControlName="{{ name }}"
      placeholder="{{ placeholder }}"
      [attr.disabled]="disableField ? '' : null"
      maxlength="{{ maxLength }}"
      (keyup)="onKey($event)"></textarea>
    <span>{{ textAreaLength }}/{{ maxLength }}</span>
  </div>
  <!-- PASSWORD -->
  <input
    *ngSwitchCase="'password'"
    id="{{ id }}"
    type="{{ type }}"
    class="form-control"
    formControlName="{{ name }}"
    placeholder="{{ placeholder }}"
    [attr.disabled]="disableField ? '' : null" />
  <!-- TIMEPICKER -->
  <div class="timepicker-group" *ngSwitchCase="'timepicker'">
    <input
      type="text"
      id="{{ id }}"
      class="form-control"
      formControlName="{{ name }}"
      (click)="onToggleTimepicker()" />
    <div
      class="transparent-bg"
      (click)="onToggleTimepicker()"
      *ngIf="showTimepicker"></div>
    <div class="timepicker-modal" *ngIf="showTimepicker">
      <form [formGroup]="customInputForm">
        <ngb-timepicker formControlName="timeControl"></ngb-timepicker>
      </form>
      <button class="btn btn-primary w-100" (click)="onSelectTime()">
        Pick Time
      </button>
    </div>
  </div>
  <!-- INVALID ERROR MSG -->
  <div class="invalid-feedback mt-2 mx-2">
    <img class="pe-2 icon-warning" src="app/assets/warning.svg" />
    <span class="body-1">
      {{ errorMessage }}
    </span>
  </div>
</div>
