import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { ShortStayModel } from './model/shortstay.model'

@Component({
  selector: 'app-shortstay-card',
  templateUrl: './shortstay-card.component.html',
  styleUrls: ['./shortstay-card.component.sass'],
})
export class ShortstayCardComponent implements OnInit {
  @Input() shortStayState: Observable<ShortStayModel>
  shortStayData: ShortStayModel = {}

  endTimestamp: number
  currTimestamp = new Date().getTime()
  intervalTimestamp: number

  days: number = 0
  hours: number = 0
  mins: number = 0
  secs: number = 0

  constructor() {}

  ngOnInit(): void {
    this.shortStayState.subscribe(data => {
      if (
        data.short_stay_setup_start_at != null &&
        data.short_stay_setup_start_at != ''
      ) {
        this.shortStayData = data

        this.endTimestamp = new Date(
          this.shortStayData.short_stay_setup_start_at
        ).getTime()
        this.intervalTimestamp = this.endTimestamp - this.currTimestamp

        if (this.intervalTimestamp > 0) {
          setInterval(() => {
            this.intervalTimestamp = this.intervalTimestamp - 1000
            let daysFloat = this.intervalTimestamp / (3600 * 1000 * 24)
            this.days = Math.floor(daysFloat)
            let hoursFloat = (daysFloat - this.days) * 24
            this.hours = Math.floor(hoursFloat)
            let minsFloat = (hoursFloat - this.hours) * 60
            this.mins = Math.floor(minsFloat)
            this.secs = Math.floor((minsFloat - this.mins) * 60)
          }, 1000)
        }
      }
    })
  }
}
