<div class="row">
  <div class="col-6">
    <button type="button" class="btn fb-btn" (click)="facebookOnClick()">
      <img src="app/assets/fb-icon.png" alt="Facebook Login" />
    </button>
  </div>
  <div class="col-6">
    <button type="button" class="btn google-btn" (click)="googleOnClick()">
      <img src="app/assets/google-icon.png" alt="Google Login" />
    </button>
  </div>
  <!-- <div class="col-4">
    <button type="button" class="btn wechat-btn">
      <img src="app/assets/wechat-icon.png" alt="Wechat Login" />
    </button>
  </div> -->
</div>
