import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Routes
import { BareComponent } from './modules/pages/bare/bare.component'
import { AuthComponent } from './modules/pages/auth/auth.component'
import { LoginComponent } from './modules/pages/auth/login/login.component'
import { SignUpComponent } from './modules/pages/auth/sign-up/sign-up.component'
import { VerifyComponent } from './modules/pages/auth/verify/verify.component'
import { SecuredComponent } from './modules/pages/secured/secured.component'
import { PropertyListingComponent } from './modules/pages/secured/my-properties/pages/property-listing/property-listing.component'
import { PropertyDetailComponent } from './modules/pages/secured/my-properties/pages/property-detail/property-detail.component'
import { PropertyAddComponent } from './modules/pages/secured/my-properties/pages/property-add/property-add.component'
import { ForgotPasswordComponent } from './modules/pages/auth/forgot-password/forgot-password.component'
import { NewPasswordComponent } from './modules/pages/auth/new-password/new-password.component'
import { OwnProjectListingComponent } from './modules/pages/secured/my-properties/pages/own-project-listing/own-project-listing.component'
import { ProjectListComponent } from './modules/pages/secured/project-listings/pages/project-list/project-list.component'
import { ProjectDetailComponent } from './modules/pages/secured/project-listings/pages/project-detail/project-detail.component'
import { MyProfileComponent } from './modules/pages/secured/my-profile/pages/my-profile/my-profile.component'
import { DashboardComponent } from './modules/pages/secured/home/pages/dashboard/dashboard.component'
import { HasServicesListingsComponent } from './modules/pages/secured/has-services/pages/has-services-listings/has-services-listings.component'
import { RenoPartnersComponent } from './modules/pages/secured/has-services/pages/reno-partners/reno-partners.component'
import { CheckoutComponent } from './modules/pages/secured/checkout/pages/checkout/checkout.component'
import { PaymentGatewayComponent } from './modules/pages/secured/checkout/pages/payment-gateway/payment-gateway.component'
import { PaymentStatusComponent } from './modules/pages/secured/checkout/pages/payment-status/payment-status.component'
import { NotFoundComponent } from './modules/pages/bare/404/not-found/not-found.component'
import { PrivacyPolicyComponent } from './modules/pages/bare/privacy-policy/privacy-policy.component'
import { TermsConditionsComponent } from './modules/pages/bare/terms-conditions/terms-conditions.component'
import { ShortstayManagementComponent } from './modules/pages/secured/has-services/pages/shortstay-management/shortstay-management.component'
import { BareChatraComponent } from './modules/pages/bare/bare-chatra/bare-chatra/bare-chatra.component'

const routes: Routes = [
  {
    path: 'bare',
    component: BareComponent,
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'verify',
        component: VerifyComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'new-password',
        component: NewPasswordComponent,
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      }, // redirect to `first-component`
    ],
  },
  {
    path: '',
    component: SecuredComponent,
    children: [
      {
        path: 'home',
        data: {
          title: 'Home',
        },
        children: [
          {
            path: '',
            component: DashboardComponent,
          },
        ],
      },
      {
        path: 'profile',
        data: {
          title: 'My Profile',
        },
        children: [
          {
            path: '',
            component: MyProfileComponent,
          },
        ],
      },
      {
        path: 'my-properties',
        data: {
          title: 'My Properties',
        },
        children: [
          {
            path: '',
            component: PropertyListingComponent,
          },
          {
            path: 'detail',
            component: PropertyDetailComponent,
          },
          {
            path: 'own-project-list',

            children: [
              {
                path: 'add',
                component: PropertyAddComponent,
              },
              {
                path: '',
                component: OwnProjectListingComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'project-listings',
        data: {
          title: 'Project Listings',
        },
        children: [
          {
            path: '',
            component: ProjectListComponent,
          },
          {
            path: 'detail',
            component: ProjectDetailComponent,
          },
        ],
      },
      {
        path: 'has-services',
        data: {
          title: 'HAS Services',
        },
        children: [
          {
            path: '',
            component: HasServicesListingsComponent,
          },
          {
            path: 'reno/:id/:identifier',
            data: {
              title: 'Reno Partner Listings',
            },
            children: [
              {
                path: '',
                component: RenoPartnersComponent,
              },
            ],
          },
          {
            path: 'shortstay-management/:id/:identifier',
            data: {
              title: 'Short Stay Management Services',
            },
            children: [
              {
                path: '',
                component: ShortstayManagementComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'checkout',
        data: {
          title: 'Shopping Cart',
        },
        children: [
          {
            path: '',
            component: CheckoutComponent,
          },
          {
            path: 'payment-gateway',
            component: PaymentGatewayComponent,
          },
          {
            path: 'payment-status',
            component: PaymentStatusComponent,
          },
        ],
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      }, // redirect to `first-component`
    ],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'bare-chatra',
    component: BareChatraComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
