import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import { Property } from 'src/app/modules/shared/models/property.model'

@Component({
  selector: 'app-property-grid',
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.sass'],
})
export class PropertyGridComponent implements OnInit {
  @Input() data: Observable<Property[]>
  dataList: Property[]

  constructor() {}

  ngOnInit(): void {
    this.data.subscribe(data => {
      this.dataList = [].concat(data)
    })
  }
}
