import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/modules/shared/service/auth.service'
import { CustomValidators } from 'src/app/modules/shared/validators/validators'

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.sass'],
})
export class ForgotPasswordFormComponent implements OnInit {
  submitted: boolean = false
  isLoading: boolean = false

  forgotPassForm = new FormGroup({
    email: new FormControl('', [Validators.required, CustomValidators.email]),
  })

  errors = {
    required: 'Email can’t be blank',
    invalidEmail: 'Email Address is invalid',
  }

  successMsg = ''

  errObject = {
    message: '',
    hasError: false,
  }

  generateErrMessage: any = () => {
    if (this.forgotPassForm.get('email').errors?.required) {
      this.errObject.message = this.errors.required
    } else if (this.forgotPassForm.get('email').errors?.email) {
      this.errObject.message = this.errors.invalidEmail
    }
  }

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  continueChangePass() {
    this.router.navigate(['/new-password'])
  }

  onSubmit = e => {
    e.preventDefault()
    this.generateErrMessage()

    this.submitted = true
    this.forgotPassForm.markAllAsTouched()

    Object.values(this.forgotPassForm.controls).forEach(control => {
      control.markAsTouched()
    })
    if (this.forgotPassForm.valid) {
      this.isLoading = true
      this.authService
        .postForgotPassword(this.forgotPassForm.get('email').value)
        .then((res: any) => {
          this.errObject.hasError = false
          this.successMsg = res.message
        })
        .catch(err => {
          // if (err.status == 422) {
          //   this.errObject.message = err.error.email[0]
          //   this.signUpForm.setErrors({
          //     emailTaken: true,
          //   })
          // }
          this.errObject.message = err.error.message
          this.errObject.hasError = true
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
