<div class="row">
  <div class="col-12">
    <app-project-filter
      [setFilter]="setFilter"
      [getUnitTypes]="getUnitTypes"
      [formSubmitState]="formSubmitState"
      (formValidationStatus)="onCheckFormEvent($event)"></app-project-filter>
  </div>
  <div class="col-12 mt-4" *ngIf="state?.packages?.length > 0">
    <app-package-pricings
      [onSelectPackage]="onSelectPackage"
      title="HAS Subscription"></app-package-pricings>
  </div>
</div>
