import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/modules/shared/service/auth.service'
import { CustomValidators } from 'src/app/modules/shared/validators/validators'

export interface ShowPassword {
  password: boolean
  confirm_password: boolean
}

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.sass'],
})
export class NewPasswordFormComponent implements OnInit {
  submitted: boolean = false
  isLoading: boolean = false
  newPassForm = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirm_password: new FormControl('', [Validators.required]),
    },
    { validators: CustomValidators.comparePassword }
  )
  @Input() userToken: string = ''
  @Input() userEmail: string = ''

  showPass: ShowPassword = {
    password: false,
    confirm_password: false,
  }

  errors = {
    required: 'Password can’t be blank',
    passwordLength: 'Password to short (minimum is 6 character)',
    notSame: 'Password does not match',
  }

  errObject = {
    message: '',
  }

  generateErrMessage: any = () => {
    if (
      this.newPassForm.get('password').errors?.required ||
      this.newPassForm.get('confirm_password').errors?.required
    ) {
      this.errObject.message = this.errors.required
    } else if (this.newPassForm.get('password').errors?.minlength) {
      this.errObject.message = this.errors.passwordLength
    } else if (
      this.newPassForm.errors?.notSame ||
      this.newPassForm.errors?.notSame
    ) {
      this.errObject.message = this.errors.notSame
    }
  }

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  showHidePassword(passId) {
    if (passId == 'password') {
      this.showPass.password = !this.showPass.password
    } else {
      this.showPass.confirm_password = !this.showPass.confirm_password
    }
  }

  onSubmit = e => {
    e.preventDefault()
    this.generateErrMessage()

    this.submitted = true
    this.newPassForm.markAllAsTouched()

    if (this.newPassForm.valid) {
      this.isLoading = true
      this.authService
        .postChangePassword(
          this.newPassForm.get('password').value,
          this.newPassForm.get('confirm_password').value,
          this.userToken,
          this.userEmail
        )
        .then(() => {
          this.router.navigate(['/login'])
        })
        .catch(err => {
          console.log(err)
          this.errObject.message = err.error.message
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
