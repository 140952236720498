import { Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router'
import {
  Project,
  ProjectFilter,
} from 'src/app/modules/shared/models/project.model'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import { SecuredStore } from '../../../store/secured.store'

@Component({
  selector: 'app-own-project-listing',
  templateUrl: './own-project-listing.component.html',
  styleUrls: ['./own-project-listing.component.sass'],
})
export class OwnProjectListingComponent implements OnInit {
  private readonly _projectListSource = new BehaviorSubject<Project[]>([])
  readonly projectListState = this._projectListSource.asObservable()

  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    private securedStore: SecuredStore
  ) {
    this.securedStore.updatePageTitle('Add Property')
  }

  ngOnInit(): void {
    this.refresh()
  }

  handleCardClick = (id: string) => {
    this.router.navigate([
      '/my-properties/own-project-list/add',
      { isHasProject: true, projectId: id },
    ])
  }

  refresh = (filterConfigStr?: string) => {
    this.projectsService
      .getProjects(filterConfigStr)
      .then((resp: Project[]) => {
        this._projectListSource.next(resp)
      })
  }
}
