<div class="auth-layout">
  <div class="background-pattern-container">
    <img
      src="app/assets/auth-background-pattern.svg"
      class="d-none d-sm-block background-pattern" />
    <img
      src="app/assets/auth-background-pattern-mobile.svg"
      class="d-block d-sm-none background-pattern" />
  </div>
  <div class="auth-card-container">
    <div class="card auth-card">
      <div class="auth-card-header">
        <div class="auth-card-header-text">
          <img src="app/assets/auth-logo.svg" />
          <h2 class="mb-0">{{ introObj[currentRoute].h2 }}</h2>
          <h4 class="mb-0">
            {{ introObj[currentRoute].h4 ? introObj[currentRoute].h4 : "" }}
          </h4>
        </div>
      </div>
      <div class="card-body auth-card-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
