<div class="lodge-defect-container">
  <div class="lodge-defect-header">
    <div class="title">Defect Check</div>
    <div class="developer-data">
      <div class="developer-details">
        <span>Developer : {{ defectCheckData?.developer_name }}</span>
        <span>Targeted VP Date: {{ defectCheckData?.vp_date }}</span>
      </div>
      <div>
        <img [src]="defectCheckData?.developer_avatar" alt="" />
      </div>
    </div>
  </div>
  <div class="content">
    <div class="defect-list" *ngIf="defectListShow">
      <table class="table">
        <thead>
          <tr>
            <th>Area Location</th>
            <th>Defect Area</th>
            <th>Defect Description</th>
            <th>Date Report Lodged</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of defectCheckData?.defect_check_history">
            <td>{{ data.area }}</td>
            <td>{{ data.defect_area }}</td>
            <td>{{ data.description }}</td>
            <td>{{ data.date_requested_at }}</td>
            <td>
              <div
                ngbDropdown
                class="d-inline-block"
                *ngIf="data.status == 'To Be Check'; else noncheckStatus">
                <div
                  class="status-dropdown"
                  id="dropdownBasic1"
                  ngbDropdownToggle>
                  {{ data.status }}
                  <img
                    class="dropdown-chevron"
                    src="app/assets/chevron-down-blue.svg" />
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button
                    class="status-dropdown-item fail"
                    ngbDropdownItem
                    (click)="onPatchStatus(data.id, 'Check Fail')">
                    Check Fail
                  </button>
                  <button
                    class="status-dropdown-item"
                    ngbDropdownItem
                    (click)="onPatchStatus(data.id, 'Closed')">
                    Closed
                  </button>
                </div>
              </div>
              <ng-template #noncheckStatus>
                <div>
                  {{ data.status }}
                </div>
              </ng-template>
            </td>
            <td>
              <img
                class="view-logo"
                src="app/assets/view_logo.png"
                (click)="
                  onPreviewDefect(
                    data.area,
                    data.defect_area,
                    data.description,
                    data.files
                  )
                " />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="report-new" *ngIf="!formActive" (click)="onAddDefect()">
      + Report New Defect
    </div>
    <div class="lodge-form" [ngClass]="{ 'd-none': !formActive }">
      <form
        [formGroup]="lodgeDefectForm"
        [ngClass]="{ 'has-errors': lodgeDefectForm.invalid && submitted }">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="lodgeDefectForm"
              id="areaLocation"
              name="areaLocation"
              label="Area Location"
              type="select"
              [valueList]="areaOpt"
              [handleOnChange]="handleAreaChange"></custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="lodgeDefectForm"
              id="defectArea"
              name="defectArea"
              label="Defect Area"
              type="select"
              [valueList]="defectAreaOpt"></custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="lodgeDefectForm"
              id="defectDesc"
              name="defectDesc"
              label="Defect Description"
              type="textarea"
              [maxLength]="135"></custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="img-field-title">Img/Video</div>
            <div class="img-field-list">
              <div
                class="img-field-uploaded"
                *ngFor="let media of mediaList; let mediaIdx = index">
                <div *ngIf="media.type == 'image'; else videoTemplate">
                  <img
                    class="uploaded-media"
                    [src]="media.safeUrl"
                    (click)="onMediaPreview(media.safeUrl, media.type)" />
                </div>
                <ng-template #videoTemplate>
                  <video
                    class="uploaded-media"
                    [src]="media.safeUrl"
                    (click)="onMediaPreview(media.safeUrl, media.type)"></video>
                </ng-template>
                <img
                  *ngIf="isEdit"
                  class="close-btn"
                  src="app/assets/img-del.png"
                  alt=""
                  (click)="onDeleteImg(mediaIdx)" />
              </div>
              <input
                id="uploader"
                type="file"
                (change)="onUpload($event)"
                class="d-none" />
              <label class="img-field-upload" for="uploader" *ngIf="isEdit">
                Add Img /Video
              </label>
            </div>
          </div>
        </div>
      </form>
      <div class="btn-container">
        <button
          class="btn btn-primary"
          (click)="!isLoading && onSubmitDefect()"
          *ngIf="isEdit; else returnBtn"
          [ngClass]="{ 'btn-load': isLoading }">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isLoading"></span>
          {{ isLoading ? "Waiting" : "Submit" }}
        </button>
        <ng-template #returnBtn>
          <button class="btn btn-primary" (click)="onReturn()">Return</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
