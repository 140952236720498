import { Injectable, TemplateRef } from '@angular/core'

export interface ToastModel {
  header: string
  content: string
}

@Injectable({
  providedIn: 'root',
})
export class CustomToastService {
  toasts: ToastModel[] = []

  constructor() {}

  show(header: string, content: string) {
    // console.log('clicked show')
    this.toasts.push({
      header: header,
      content: content,
    })
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast)
  }
}
