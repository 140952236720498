import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.sass'],
})
export class CustomModalComponent implements OnInit {
  @Input() modalTitle: string
  @Input() modalSubmit = (): boolean => {
    return false
  }

  // for basic modal usage
  @Input() MsgTitle: string = ''
  @Input() MsgDesc: string = ''
  @Input() MsgBtn: string = ''
  @Input() navUrl: string = ''

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {
    // console.log("Modal Title : ", this.modalTitle)
  }

  onClickOk() {
    if (this.navUrl != '' && this.navUrl) {
      this.router.navigate([this.navUrl])
    }
    this.activeModal.close()
  }

  onClickSubmit() {
    if (this.modalSubmit()) {
      this.activeModal.close()
    }
  }
}
