<div class="notification-container">
  <div class="title">Notification</div>
  <div class="email-label">
    <span>Email</span>
  </div>
  <div *ngFor="let notif of notifList; let idx = index" class="notification">
    <div class="notification-label">{{ notif.label }}</div>
    <div
      class="notification-toggle"
      [ngClass]="{ active: notif.isActive }"
      (click)="onToggle(idx)">
      <span
        class="notification-toggle-on"
        [ngClass]="{ active: notif.isActive }"
        >ON</span
      >
      <span
        class="notification-toggle-off"
        [ngClass]="{ active: notif.isActive }"
        >OFF</span
      >
      <div
        class="notification-toggle-btn"
        [ngClass]="{ active: notif.isActive }"></div>
    </div>
  </div>
</div>
