import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-bare',
  templateUrl: './bare.component.html',
  styleUrls: ['./bare.component.sass'],
})
export class BareComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
