<div class="row">
  <div class="col-12">
    <app-project-filter
      [getUnitTypes]="getUnitTypes"
      [getPartners]="getPartners"
      [getAllPartners]="getAllPartners"
      [setFilter]="setFilter"
      [formSubmitState]="formSubmitState"
      (formValidationStatus)="onCheckFormEvent($event)"></app-project-filter>
  </div>
  <div class="col-12 mt-4" *ngIf="state?.partners?.length > 0">
    <app-reno-partners-grid
      [getPackages]="getPackages"></app-reno-partners-grid>
  </div>
  <!-- reno-partner-list -->
  <div class="col-12 mt-4" *ngIf="state?.packages?.length > 0">
    <app-package-pricings
      [onSelectPackage]="onSelectPackage"
      title="Packages"></app-package-pricings>
  </div>
</div>
