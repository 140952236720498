import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import {
  craftLocation,
  Developer,
  HostPartnerAgent,
  Images,
  MarketGrade,
  Project,
  RateSummary,
} from 'src/app/modules/shared/models/project.model'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { SecuredStore } from '../../../store/secured.store'
import { ProjectOverviewModel } from '../../component/project-overview-card/model/project-overview.model'
import { SalesCardModel } from '../../component/sale-price-card/model/sales-card.model'

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.sass'],
})
export class ProjectDetailComponent implements OnInit {
  private readonly _projectOverviewSource =
    new BehaviorSubject<ProjectOverviewModel>(null)
  readonly projectOverviewState = this._projectOverviewSource.asObservable()

  private readonly _salesDataSource = new BehaviorSubject<SalesCardModel>(null)
  readonly salesDataState = this._salesDataSource.asObservable()

  private readonly _developerDataSource = new BehaviorSubject<Developer>(null)
  readonly developerDataState = this._developerDataSource.asObservable()

  private readonly _hostDataSource = new BehaviorSubject<HostPartnerAgent[]>(
    null
  )
  readonly hostDataState = this._hostDataSource.asObservable()

  private readonly _renoPartnerDataSource = new BehaviorSubject<
    HostPartnerAgent[]
  >(null)
  readonly renoPartnerDataState = this._renoPartnerDataSource.asObservable()

  private readonly _agentsDataSource = new BehaviorSubject<HostPartnerAgent[]>(
    null
  )
  readonly agentsDataState = this._agentsDataSource.asObservable()

  private readonly _marketGradeSource = new BehaviorSubject<MarketGrade>(null)
  readonly marketGradeState = this._marketGradeSource.asObservable()

  private readonly _avgDailyRateSource = new BehaviorSubject<RateSummary>(null)
  readonly avgDailyRateState = this._avgDailyRateSource.asObservable()

  private readonly _occupancyRateSource = new BehaviorSubject<RateSummary>(null)
  readonly occupancyRateState = this._occupancyRateSource.asObservable()

  private readonly _revenueRateSource = new BehaviorSubject<RateSummary>(null)
  readonly revenueRateState = this._revenueRateSource.asObservable()

  projectDetails: Project = {}

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private securedStore: SecuredStore
  ) {}

  ngOnInit(): void {
    let projectId = this.route.snapshot.paramMap.get('id')
    this.projectsService
      .getProjectById(projectId)
      .then((resp: Project) => {
        this.projectDetails = resp
        let images: Images[] = resp.images
        let projOverview: ProjectOverviewModel = {
          id: resp.id,
          name: resp.name,
          images: images,
          location: craftLocation(
            resp.address_1,
            resp.address_2,
            resp.post_code,
            resp.state,
            resp.area
          ),
          description: resp.description,
          status: resp.status,
          tags: resp.tags,
          is_favorite: resp.is_favorite,
        }

        let salesData: SalesCardModel = {
          price_range: resp.price_range,
          management_office_location: resp.management_office_location,
          built_up_price: resp.built_up_price,
          built_up_size: resp.built_up_size,
          listed_at: resp.listed_at,
          completion_year: resp.completion_year,
          type: resp.type,
          unit_types: resp.unit_types,
          additional_informations: resp.additional_informations,
          display_suite_link: resp.display_suite_link,
        }

        this._projectOverviewSource.next(projOverview)
        this._salesDataSource.next(salesData)
        this._developerDataSource.next(resp.developer)
        this._hostDataSource.next(resp.hosts)
        this._renoPartnerDataSource.next(resp.renovation_partners)
        this._agentsDataSource.next(resp.agents)
        this._marketGradeSource.next(resp.market_grade)
        this._avgDailyRateSource.next(resp.average_daily_rate)
        this._occupancyRateSource.next(resp.occupancy_rate)
        this._revenueRateSource.next(resp.revenue_rate)

        this.securedStore.updatePageTitle(resp.name)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }
}
