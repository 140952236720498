import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { HostPartnerAgent } from 'src/app/modules/shared/models/project.model'

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.sass'],
})
export class AgentCardComponent implements OnInit {
  @Input() agentsDataState: Observable<HostPartnerAgent[]>
  agentsData: HostPartnerAgent[]
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  }

  constructor() {}

  ngOnInit(): void {
    this.agentsDataState.subscribe(data => {
      this.agentsData = data
    })
  }

  slickInit(e) {
    // console.log('slick initialized')
  }

  breakpoint(e) {
    // console.log('breakpoint')
  }

  afterChange(e) {
    // console.log('afterChange')
  }

  beforeChange(e) {
    // console.log('beforeChange')
  }
}
