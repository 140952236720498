import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SecuredStore } from '../../../store/secured.store'

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.sass'],
})
export class PaymentStatusComponent implements OnInit {
  isSuccess: boolean = true
  orderId: string = ''
  paymentAmount: string = ''

  constructor(
    private securedStore: SecuredStore,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.securedStore.updatePageTitle('Payment')
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap
    const paymentSuccess = queryParams.get('isSuccess')
    this.paymentAmount = queryParams.get('paymentAmount')
    this.orderId = queryParams.get('orderId')
    if (paymentSuccess == 'true') {
      this.isSuccess = true
    } else {
      this.isSuccess = false
    }
  }

  redirectProperty() {
    this.router.navigate(['/my-properties'])
  }

  redirectCart() {
    this.router.navigate(['/checkout'])
  }
}
