<div class="booking-summary bg-white">
  <div class="title">Booking Summary</div>
  <div class="content mt-3" *ngIf="!isSold">
    <div *ngIf="bookingSummary?.reservations_list?.length > 0; else message">
      <table>
        <tbody>
          <tr *ngFor="let booking of bookingSummary?.reservations_list">
            <td>
              <div class="check-date">
                <img class="me-2" src="app/assets/calendar.svg" />
                {{ booking.start_date }}
                <span class="mx-1">to</span> {{ booking.end_date }}
              </div>
            </td>
            <td>
              <div class="pax">
                <img class="me-2" src="app/assets/profile.svg" />{{
                  booking.number_of_pax
                }}
              </div>
            </td>
            <td>MYR</td>
            <td class="text-right">{{ booking.revenue | number: "1.2" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #message>
      <div class="no-booking-container">
        <span>No booking at this moment</span>
      </div>
    </ng-template>
  </div>
  <div class="content-summary mt-3">
    <div class="summary-row">
      <div class="summary-column">Total Reservations</div>
      <div
        *ngIf="bookingSummary?.reservations_list?.length > 0; else emptyValue">
        <div class="summary-column">
          {{ bookingSummary?.total_reservations }}
        </div>
      </div>
      <ng-template #emptyValue>
        <div class="summary-column">-</div>
      </ng-template>
    </div>
    <div class="summary-row">
      <div class="summary-column">Booking Summary Total</div>
      <div *ngIf="bookingSummary?.total > 0; else emptySummary">
        <div class="summary-column">
          {{ bookingSummary?.total | currency: "MYR ":"symbol":"1.2" }}
        </div>
      </div>
      <ng-template #emptySummary>
        <div class="summary-column">MYR -</div>
      </ng-template>
    </div>
  </div>
</div>
