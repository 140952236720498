<div class="host-card bg-white">
  <div class="title">Host</div>
  <div *ngIf="hostData?.name != '' && hostData; else noHost">
    <div class="mt-3 content">
      <img [src]="hostData?.avatar_url" />
      <div class="host-details ms-3">
        <div class="name">{{ hostData?.name }}</div>
        <div class="status">{{ hostData?.description }}</div>
      </div>
    </div>
  </div>
  <ng-template #noHost>
    <div class="mt-3 no-host">
      <span>No Host Appointed</span>
    </div>
  </ng-template>
</div>
