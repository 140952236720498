import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { MarketGrade } from 'src/app/modules/shared/models/project.model'

@Component({
  selector: 'app-market-grade-card',
  templateUrl: './market-grade-card.component.html',
  styleUrls: ['./market-grade-card.component.sass'],
})
export class MarketGradeCardComponent implements OnInit {
  @Input() marketGradeState: Observable<MarketGrade>
  marketGrade: MarketGrade
  constructor() {}

  ngOnInit(): void {
    this.marketGradeState.subscribe(data => {
      this.marketGrade = data
    })
  }
}
