<div class="recent-act-container card">
  <div class="title">Recent Activity</div>
  <div class="doc-list">
    <div class="document" *ngFor="let activity of activities">
      <div
        class="placeholder-img"
        *ngIf="
          activity.userImg == '' || activity.userImg == null;
          else imgExist
        "></div>
      <ng-template>
        <img class="doc-icon" [src]="activity.userImg" />
      </ng-template>
      <div class="activity-summary">
        <div class="activity-summary-act">
          <span class="actor">{{ activity.user }}</span
          >&nbsp;<span class="action">{{ activity.activity }}</span>
        </div>
        <div class="activity-summary-time">{{ activity.date }}</div>
      </div>
    </div>
  </div>
</div>
