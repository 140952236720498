import { Component, Input, OnInit } from '@angular/core'
import { craftLocation, Project } from '../../models/project.model'

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.sass'],
})
export class ProjectCardComponent implements OnInit {
  @Input() dataSource: Project
  @Input() onCardClick: (id: string) => void
  projectData: Project = {
    name: 'Project Name',
    tags: ['Apartment', 'Freehold'],
    location: 'Location',
    developer: {
      id: '0',
      name: 'Developer',
    },
    images: [
      {
        url: 'app/assets/city-1-sample.png',
      },
    ],
  }

  constructor() {}

  ngOnInit(): void {
    this.projectData = this.dataSource || this.projectData
    this.projectData.location = craftLocation(
      this.projectData.address_1,
      this.projectData.address_2,
      this.projectData.post_code,
      this.projectData.state,
      this.projectData.area
    )
  }
}
