import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { HistoryModel } from 'src/app/modules/shared/models/property.model'
import { TimelineModel } from './model/timeline-card.model'

@Component({
  selector: 'app-timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: ['./timeline-card.component.sass'],
})
export class TimelineCardComponent implements OnInit {
  @Input() timelineTitle: String = ''
  @Input() timelineState: Observable<TimelineModel>
  timelineData: TimelineModel
  isHideList: boolean = true

  constructor() {}

  ngOnInit(): void {
    this.timelineState.subscribe(data => {
      this.timelineData = data
    })
  }

  showMore() {
    this.isHideList = !this.isHideList
  }
}
