import { Constants } from '../enums/constants.enum'

export class ApiFactory {
  private apiUrl: string
  private includeParameter: string[] = []
  constructor(apiUrl: string) {
    this.apiUrl = apiUrl
  }

  public including(include = Constants.INCLUDING): this {
    // annotation not necessary added to address comments
    this.apiUrl =
      this.apiUrl + (this.apiUrl.includes('?') ? '&' : '?') + include
    return this
  }

  public search(searchParam: string, search = Constants.SEARCH): this {
    this.apiUrl = this.apiUrl + search.replace('SEARCH_PARAM', searchParam)
    return this
  }

  public filter(
    paramVal,
    filterParam: string,
    filter = Constants.FILTER
  ): this {
    this.apiUrl = this.apiUrl.includes('?')
      ? this.apiUrl +
        '&' +
        filter.replace('FILTER_PARAM', filterParam) +
        paramVal
      : this.apiUrl +
        '?' +
        filter.replace('FILTER_PARAM', filterParam) +
        paramVal
    return this
  }

  public projIdSearch(proj_id_search = Constants.PROJECT_ID_SEARCH): this {
    this.apiUrl = this.apiUrl + proj_id_search
    return this
  }

  public withHosts(hosts = Constants.HOSTS): this {
    this.includeParameter = this.includeParameter.includes(hosts)
      ? this.includeParameter
      : this.includeParameter.concat(hosts)
    return this
  }

  public withUnitType(unitType = Constants.UNIT_TYPE): this {
    this.includeParameter = this.includeParameter.includes(unitType)
      ? this.includeParameter
      : this.includeParameter.concat(unitType)
    return this
  }

  public withHost(host = Constants.HOST): this {
    this.includeParameter = this.includeParameter.includes(host)
      ? this.includeParameter
      : this.includeParameter.concat(host)
    return this
  }

  public withUnitTypes(unitTypes = Constants.UNIT_TYPES): this {
    this.includeParameter = this.includeParameter.includes(unitTypes)
      ? this.includeParameter
      : this.includeParameter.concat(unitTypes)
    return this
  }

  public withImages(images = Constants.IMAGES): this {
    this.includeParameter = this.includeParameter.includes(images)
      ? this.includeParameter
      : this.includeParameter.concat(images)
    return this
  }

  public withDeveloper(developer = Constants.DEVELOPER): this {
    this.includeParameter = this.includeParameter.includes(developer)
      ? this.includeParameter
      : this.includeParameter.concat(developer)
    return this
  }

  public withDevelopers(developers = Constants.DEVELOPERS): this {
    this.includeParameter = this.includeParameter.includes(developers)
      ? this.includeParameter
      : this.includeParameter.concat(developers)
    return this
  }

  public withAdditionalInformations(
    additional_info = Constants.ADDITIONAL_INFORMATIONS
  ): this {
    this.includeParameter = this.includeParameter.includes(additional_info)
      ? this.includeParameter
      : this.includeParameter.concat(additional_info)
    return this
  }

  public withRenovationPartners(
    renovation_partners = Constants.RENOVATION_PARTNERS
  ): this {
    this.includeParameter = this.includeParameter.includes(renovation_partners)
      ? this.includeParameter
      : this.includeParameter.concat(renovation_partners)
    return this
  }

  public withAgents(agents = Constants.AGENTS): this {
    this.includeParameter = this.includeParameter.includes(agents)
      ? this.includeParameter
      : this.includeParameter.concat(agents)
    return this
  }

  public withProfile(profile = Constants.PROFILE): this {
    this.includeParameter = this.includeParameter.includes(profile)
      ? this.includeParameter
      : this.includeParameter.concat(profile)
    return this
  }

  public withSocialMedia(social_media = Constants.SOCIAL_MEDIA): this {
    this.includeParameter = this.includeParameter.includes(social_media)
      ? this.includeParameter
      : this.includeParameter.concat(social_media)
    return this
  }

  public withDocuments(documents = Constants.DOCUMENTS): this {
    this.includeParameter = this.includeParameter.includes(documents)
      ? this.includeParameter
      : this.includeParameter.concat(documents)
    return this
  }

  public withKeyCollectionHistory(
    key_collection_his = Constants.KEY_COLLECTION_HIS
  ): this {
    this.includeParameter = this.includeParameter.includes(key_collection_his)
      ? this.includeParameter
      : this.includeParameter.concat(key_collection_his)
    return this
  }

  public withDefectCheckHistory(
    defect_check_his = Constants.DEFECT_CHECK_HIS
  ): this {
    this.includeParameter = this.includeParameter.includes(defect_check_his)
      ? this.includeParameter
      : this.includeParameter.concat(defect_check_his)
    return this
  }

  public withConstructionHistory(
    construction_his = Constants.CONSTRUCTION_HIS
  ): this {
    this.includeParameter = this.includeParameter.includes(construction_his)
      ? this.includeParameter
      : this.includeParameter.concat(construction_his)
    return this
  }

  public withRenovationHistory(
    renovation_his = Constants.RENOVATION_HIS
  ): this {
    this.includeParameter = this.includeParameter.includes(renovation_his)
      ? this.includeParameter
      : this.includeParameter.concat(renovation_his)
    return this
  }

  public withPackages(packages = Constants.PACKAGES): this {
    this.includeParameter = this.includeParameter.includes(packages)
      ? this.includeParameter
      : this.includeParameter.concat(packages)
    return this
  }

  public withAggresiveness(aggresiveness = Constants.AGGRESIVENESS): this {
    this.includeParameter = this.includeParameter.includes(aggresiveness)
      ? this.includeParameter
      : this.includeParameter.concat(aggresiveness)
    return this
  }

  public withTypeOfInvestment(
    type_of_investment = Constants.TYPE_OF_INVESTMENT
  ): this {
    this.includeParameter = this.includeParameter.includes(type_of_investment)
      ? this.includeParameter
      : this.includeParameter.concat(type_of_investment)
    return this
  }

  public withGoalsSetting(goals_setting = Constants.GOALS_SETTING): this {
    this.includeParameter = this.includeParameter.includes(goals_setting)
      ? this.includeParameter
      : this.includeParameter.concat(goals_setting)
    return this
  }

  public withReport(report = Constants.REPORT): this {
    this.includeParameter = this.includeParameter.includes(report)
      ? this.includeParameter
      : this.includeParameter.concat(report)
    return this
  }

  public withMarketTrend(markettrend = Constants.MARKET_TREND): this {
    this.includeParameter = this.includeParameter.includes(markettrend)
      ? this.includeParameter
      : this.includeParameter.concat(markettrend)
    return this
  }

  public withDefectAreas(defectareas = Constants.DEFECT_AREAS): this {
    this.includeParameter = this.includeParameter.includes(defectareas)
      ? this.includeParameter
      : this.includeParameter.concat(defectareas)
    return this
  }

  toString() {
    return (
      this.apiUrl +
      (this.includeParameter ? this.includeParameter.toString() : '')
    )
  }
}
