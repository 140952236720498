import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ApexAxisChartSeries, ApexOptions, ChartComponent } from 'ng-apexcharts'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-source-of-booking',
  templateUrl: './source-of-booking.component.html',
  styleUrls: ['./source-of-booking.component.sass'],
})
export class SourceOfBookingComponent implements OnInit {
  @Input() sourceOfBookingState: Observable<any>
  @ViewChild('sourcePieChart') chart: ChartComponent
  chartOptions: Partial<ApexOptions>

  constructor() {
    this.chartOptions = {
      dataLabels: {
        style: {
          fontSize: '9px',
          fontWeight: '500',
        },
      },
      colors: [
        '#8F9091',
        '#F37877',
        '#4174B9',
        '#243977',
        '#1F3562',
        '#049CD9',
        '#F9DE4B',
        '#098ca3'
      ],
      series: [10, 10, 10, 10, 10, 10, 10],
      chart: {
        height: 180,
        type: 'pie',
      },
      legend: {
        fontSize: '9px',
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
      },
      labels: [
        'Agoda',
        'AirBnB',
        'Ctrip',
        'Booking',
        'Expedia',
        'Traveloka',
        'Tiket',
        'Hostplatform'
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
  }

  ngOnInit(): void {
    this.sourceOfBookingState.subscribe(data => {
      if (data) {
        let keys = Object.keys(data)
        let values: ApexAxisChartSeries = Object.values(data)
        this.chartOptions.labels = keys
        this.chartOptions.series = values
      }
    })
  }
}
