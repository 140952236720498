import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import { Property } from 'src/app/modules/shared/models/property.model'
import { DashboardService } from 'src/app/modules/shared/service/dashboard.service'

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.sass'],
})
export class PropertyCardComponent implements OnInit {
  @Input() property: Property
  propertyData: Property = {
    id: '1',
    project_name: 'Twinpines @Tropicana Grandhill',
    unit_number: 'A-25-17',
    status: 'Renovation',
    monthly_revenue: '3906.00',
    images: [{ url: 'app/assets/trop-tower.png' }],
  }
  statusDefectCheck = Constants.STATUS_DEFECT_CHECK
  statusKeyCollection = Constants.STATUS_KEY_COLLECTION
  statusLive = Constants.STATUS_LIVE
  statusNewLaunch = Constants.STATUS_NEW_LAUNCH
  statusPendingApproval = Constants.STATUS_PENDING_APPROVAL
  statusRenovation = Constants.STATUS_RENOVATION
  statusShortStaySetup = Constants.STATUS_SHORT_STAY_SETUP
  statusSold = Constants.STATUS_SOLD
  statusUnderConstruction = Constants.STATUS_UNDER_CONSTRUCTION
  statusSubExpired = Constants.STATUS_SUB_EXPIRED

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.propertyData = this.property || this.propertyData
  }

  handlePropertyCardClicked(): void {
    let param: any = {
      id: this.propertyData.id,
      unit_no: this.propertyData.unit_number,
    }

    if (this.propertyData.is_demo) param.demo = true;

    this.router.navigate(['/my-properties/detail', param])
  }
}
