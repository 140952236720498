import { Component, Input, OnInit } from '@angular/core'
import {
  GetRenoPartnersPackagesResponse,
  RenoPartners,
} from 'src/app/modules/shared/models/services.model'
import { ServicesService } from 'src/app/modules/shared/service/services.service'
import { HasServicesStore } from '../../pages/store/has-services.store'

@Component({
  selector: 'app-reno-partners-card',
  templateUrl: './reno-partners-card.component.html',
  styleUrls: ['./reno-partners-card.component.sass'],
})
export class RenoPartnersCardComponent implements OnInit {
  @Input() data: RenoPartners
  @Input() getPackages = (param: string) => {}

  constructor(
    private servicesService: ServicesService,
    private store: HasServicesStore
  ) {}

  ngOnInit(): void {}

  fetchRenoPackages(id: string) {
    this.getPackages(id)
  }
}
