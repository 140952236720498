<div class="project-card" (click)="onCardClick(projectData.id)">
  <div class="project-card-header">
    <img
      *ngIf="projectData.images.length > 0"
      src="{{ projectData.images[0].url }}" />
    <img *ngIf="projectData.images.length < 1" src="app/assets/no-image.png" />
  </div>
  <div class="project-card-content">
    <h2 class="content-title">{{ projectData?.name }}</h2>
    <h4 class="content-detail">{{ projectData?.location }}</h4>
    <h4 class="content-detail">{{ projectData.developer?.name }}</h4>
    <div class="category mt-auto" *ngIf="projectData?.tags?.length > 0">
      <div class="category-item mt-1" *ngFor="let tag of projectData.tags">
        <div class="body-1">{{ tag }}</div>
      </div>
    </div>
  </div>
</div>
