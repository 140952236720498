<div class="media-container">
  <div *ngIf="mediaType == 'image'; else videoMedia">
    <picture>
      <source [srcset]="mediaPath" type="image/webp" />
      <img [src]="mediaPath" />
    </picture>
  </div>
  <ng-template #videoMedia>
    <vjs-video-container vjs-ratio="4:3">
      <video
        class="video-js vjs-default-skin"
        controls
        preload="auto"
        poster="poster.jpg">
        <source [src]="mediaPath" />
      </video>
    </vjs-video-container>
  </ng-template>
  <div class="close-img" (click)="onCloseImg()">
    <img src="app/assets/close.svg" alt="" />
  </div>
</div>
