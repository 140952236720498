import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { PropertySummary } from 'src/app/modules/shared/models/dashboard.model'

@Component({
  selector: 'app-properties-for-sale',
  templateUrl: './properties-for-sale.component.html',
  styleUrls: ['./properties-for-sale.component.sass'],
})
export class PropertiesForSaleComponent implements OnInit {
  @Input() propertySumState: Observable<PropertySummary>
  propForSale: number = 0

  constructor() {}

  ngOnInit(): void {
    this.propertySumState.subscribe(data => {
      if (data != null) {
        this.propForSale = data.for_sale
      }
    })
  }
}
