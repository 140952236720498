<div class="profile-container">
  <div class="title">Profile</div>
  <div class="row">
    <div class="col-12 col-lg-6 profile-pic">
      <div class="img-container">
        <img [src]="profilePic" *ngIf="profilePic != ''" />
      </div>
      <div class="profile-pic-upload">
        <!-- <button class="btn" (click)="onUpload($event)">+ Picture</button> -->
        <input
          id="uploader"
          type="file"
          (change)="onUpload($event)"
          class="d-none" />
        <label class="profile-pic-upload-btn" for="uploader"> + Picture </label>
        <span> minimum<br />Size 100 x 100 px </span>
      </div>
    </div>
    <div
      class="col-12 col-lg-6 referral-link flex-row flex-lg-column align-items-center align-items-lg-start justify-content-start justify-content-lg-end mt-3">
      <span class="referral-link-title me-3"> Referral Links </span>
      <div class="referral-link-content">
        <span>{{ referralLink }}</span>
        <div ngxClipboard [cbContent]="referralLink" (click)="shareReferral()">
          <img class="icon" src="app/assets/copy-icon.svg" />
          <img class="icon" src="app/assets/share-icon.svg" *ngIf="isMobile" />
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="profileForm" class="profile-form">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="profileForm"
          id="email"
          name="email"
          label="Email Address"
          type="text"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="profileForm"
          id="fullname"
          name="fullname"
          label="Full Name"
          type="text"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="profileForm"
          id="phone"
          name="phone"
          label="Phone No"
          type="text"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <custom-input
          [formGroup]="profileForm"
          id="birthday"
          name="birthday"
          label="Birthday Date"
          type="date"></custom-input>
      </div>
      <div class="col-12 col-md-6 mb-3" *ngIf="isBppUser">
        <custom-input
          [formGroup]="profileForm"
          id="id_card_no"
          name="id_card_no"
          label="Identity Card Number"
          type="text"></custom-input>
      </div>
    </div>
  </form>

  <div class="interest-container" *ngIf="!isBppUser">
    <div class="interest-container-title">
      <span>Area of Interest</span>
      <img src="app/assets/info-icon.svg" class="info-icon" />
    </div>
    <div class="content">
      <div
        *ngFor="let interest of interestList; let idx = index"
        class="interest-item mt-1">
        <span>{{ interest }}</span>
        <img
          src="app/assets/clear-icon.svg"
          class="clear-icon"
          (click)="delInterest(idx)" />
      </div>
      <form [formGroup]="profileForm" class="mt-1" *ngIf="interestFormActive">
        <custom-input
          [formGroup]="profileForm"
          id="interest"
          name="interest"
          type="text"
          [keyUpFunc]="addNewInterest"></custom-input>
      </form>
      <button
        class="btn mt-1"
        *ngIf="!interestFormActive"
        (click)="toggleAddInterest()">
        + Add Area of Interest
      </button>
    </div>
  </div>
</div>
