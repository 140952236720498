<div
  class="card-container card h-100"
  [ngClass]="{
    lightblue: SummaryData?.bg_color == 'lightblue',
    thickblue: SummaryData?.bg_color == 'thickblue',
    thickorange: SummaryData?.bg_color == 'thickorange'
  }">
  <img [src]="SummaryData?.icon" />
  <div class="content">
    <h4 class="title">{{ SummaryData?.title }}</h4>

    <!-- main value, either nominal or percentage -->
    <div
      class="large-font value"
      *ngIf="SummaryData?.type == 'nominal'; else percentageMain">
      {{ SummaryData?.value | currency: "MYR ":"symbol":"1.2" }}
    </div>
    <ng-template #percentageMain>
      <div class="large-font value">{{ SummaryData?.value }} %</div>
    </ng-template>

    <div *ngIf="showDifference" class="d-flex align-items-start">
      <!-- show either green-up triangle or red-down triangle -->
      <img
        src="app/assets/green-triangle.svg"
        class="me-1 updown-triangle"
        *ngIf="valueIncrease; else redTriangle" />
      <ng-template #redTriangle>
        <img
          src="app/assets/red-triangle.svg"
          class="me-1 updown-triangle"
          *ngIf="difference != 0" />
      </ng-template>

      <!-- show either prev month nominal value or prev month percentage -->
      <h4
        *ngIf="SummaryData?.type == 'nominal'; else percentageSub"
        class="subvalue">
        {{ difference | currency: "MYR ":"symbol":"1.2" }}
      </h4>
      <ng-template #percentageSub>
        <h4 class="subvalue">{{ difference }} %</h4>
      </ng-template>
    </div>
  </div>
</div>
