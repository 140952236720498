import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-add-custom-project-card',
  templateUrl: './add-custom-project-card.component.html',
  styleUrls: ['./add-custom-project-card.component.sass'],
})
export class AddCustomProjectCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
