import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { SidebarStore } from './store/sidebar.store'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit {
  menuOpen: boolean = this.sidebarStore.getMenuOpen()

  activePage: string

  userEmail: string

  pageList: any = [
    {
      url: '/home',
      label: 'Home',
      icon: 'app/assets/home-icon.svg',
    },
    {
      url: '/profile',
      label: 'My Profile',
      icon: 'app/assets/profile.svg',
    },
    {
      url: '/my-properties',
      label: 'My Properties',
      icon: 'app/assets/property.svg',
    },
    // {
    //   url: "/secured/investment",
    //   label: "Investment",
    //   icon: "app/assets/investment.svg"
    // }
  ]

  hostAStayPageList: any = [
    {
      url: '/project-listings',
      label: 'Project Listings',
      icon: 'app/assets/home-icon.svg',
    },
    {
      url: '/has-services',
      label: 'HAS Services',
      icon: 'app/assets/has-services.svg',
    },
  ]

  constructor(
    private router: Router,
    private sidebarStore: SidebarStore,
    private userStore: UserStore
  ) {}

  ngOnInit(): void {
    this.activePage = this.router.url
    // this.route.queryParams.subscribe(params => {
    //   this.name = params['name'];
    //   console.log(params)
    // });
    this.sidebarStore.menuOpenState.subscribe(val => {
      this.menuOpen = val
    })

    this.userStore.getUser()
    this.userStore.userState.subscribe(res => {
      this.userEmail = res?.user.email
    })
  }

  toggleMobileMenu = () => {
    this.sidebarStore.toggleMenu()
  }

  logout = e => {
    e.preventDefault()
    this.userStore.logoutUser()
  }
}
