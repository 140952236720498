import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.sass'],
})
export class MediaPreviewComponent implements OnInit {
  mediaType: string
  mediaPath: any

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onCloseImg() {
    this.activeModal.close()
  }
}
