import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-add-property-card',
  templateUrl: './add-property-card.component.html',
  styleUrls: ['./add-property-card.component.sass'],
})
export class AddPropertyCardComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onClickAddProperty() {
    this.router.navigate(['/my-properties/own-project-list'])
  }
}
