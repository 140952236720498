<app-custom-modal [modalSubmit]="onClickRemovalRequest">
  <span modalTitle>Remove From Listing</span>
  <span modalBtn>OK</span>
  <form [formGroup]="removeFromListForm" class="remove-from-listing-form">
    <label for="status_radio">Reason for removal?</label>
    <div class="row" *ngFor="let reason of reasonList">
      <div class="col-12 v-row-center">
        <input
          type="radio"
          class="radio-btn"
          [value]="reason"
          formControlName="reasonSelect" /><span>{{ reason }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 v-row-center">
        <input
          type="radio"
          class="radio-btn"
          value="other"
          formControlName="reasonSelect" /><span>Others : </span>
        <input type="text" class="other-input" formControlName="other_reason" />
      </div>
    </div>
    <div class="h-col-center mb-2">
      <custom-input
        [formGroup]="removeFromListForm"
        id="confirmation_text"
        label="Type “CONFIRM” to confirm remove listing"
        name="confirmation_text"
        type="text">
      </custom-input>
    </div>
  </form>
</app-custom-modal>
