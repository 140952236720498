import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SocialUser } from 'angularx-social-login'
import { AuthConstants } from 'src/app/modules/shared/enums/auth.enum'
import { AuthService } from 'src/app/modules/shared/service/auth.service'
import { SocialLoginAuthService } from 'src/app/modules/shared/service/social-login-auth.service'

@Component({
  selector: 'app-login-social-media',
  templateUrl: './login-social-media.component.html',
  styleUrls: ['./login-social-media.component.sass'],
  providers: [SocialLoginAuthService],
})
export class LoginSocialMediaComponent implements OnInit {
  constructor(
    private socialLoginAuthService: SocialLoginAuthService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  facebookOnClick = () => {
    this.socialLoginAuthService.signInWithFB().then((user: SocialUser) => {
      this.authService
        .socialLogin(AuthConstants.facebook, user.authToken)
        .then(() => {
          this.router.navigate([`home`])
        })
        .catch(err => {
          console.log(err)
        })
    })
  }

  googleOnClick = () => {
    this.socialLoginAuthService.signInWithGoogle().then((user: SocialUser) => {
      this.authService
        .socialLogin(AuthConstants.google, user.idToken)
        .then(() => {
          this.router.navigate([`home`])
        })
        .catch(err => {
          console.log(err)
        })
    })
  }
}
