import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import {
  RenovationPartnerPackageRes,
  UnitPartnersRes,
  UnitTypesRes,
} from '../models/services.model'

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClient) {}

  async getServices() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(`${environment.baseUrl}${new ApiFactory(Endpoints.SERVICES)}`)
        .subscribe({
          next: data => {
            resolve(data.data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getRenovationPartners() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.RENOVATION_PARTNERS
          )}`
        )
        .subscribe({
          next: data => {
            resolve(data.data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getRenovationPartnersPackages(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.RENOVATION_PARTNERS + '/' + id
          )
            .including()
            .withPackages()}`
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getUnitTypes(project_id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<UnitTypesRes>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.UNIT_TYPES
          ).projIdSearch()}${project_id}`
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getPartners(unit_type_id: string) {
    let endpoint = Endpoints.UNIT_TYPE_DETAIL.replace(
      '[UNIT_TYPE_ID]',
      unit_type_id
    )
    return new Promise((resolve, reject) => {
      this.http
        .get<UnitPartnersRes>(
          `${environment.baseUrl}${new ApiFactory(endpoint)
            .including()
            .withRenovationPartners()
            .toString()}`
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getPartnerPackages(partner_id: string) {
    let endPoint = Endpoints.RENOVATION_PARTNER_DETAIL.replace(
      '[PARTNER_ID]',
      partner_id
    )
    return new Promise((resolve, reject) => {
      this.http
        .get<RenovationPartnerPackageRes>(
          `${environment.baseUrl}${new ApiFactory(endPoint)
            .including()
            .withPackages()
            .toString()}`
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getShortstayManagementServices() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.SHORTSTAY_MGMT_SERVICES
          )}`
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
