import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { NgApexchartsModule } from 'ng-apexcharts'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { ClipboardModule } from 'ngx-clipboard'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { NgxStripeModule } from 'ngx-stripe'

import { AppRoutingModule } from 'src/app/app-routing.module'

import { SecuredComponent } from './secured.component'
import { PropertyListingComponent } from './my-properties/pages/property-listing/property-listing.component'
import { PropertyCardComponent } from './my-properties/component/property-card/property-card.component'
import { PropertyGridComponent } from './my-properties/component/property-grid/property-grid.component'
import { PropertyDetailComponent } from './my-properties/pages/property-detail/property-detail.component'
import { AddPropertyCardComponent } from './my-properties/component/add-property-card/add-property-card.component'
import { PropertyOverviewComponent } from './my-properties/component/property-overview/property-overview.component'
import { TotalBookedNightsComponent } from './my-properties/component/total-booked-nights/total-booked-nights.component'
import { OccupancyRateComponent } from './my-properties/component/occupancy-rate/occupancy-rate.component'
import { TotalRevenueComponent } from './my-properties/component/total-revenue/total-revenue.component'
import { OtaListingsComponent } from './my-properties/component/ota-listings/ota-listings.component'
import { MarketTrendComponent } from './my-properties/component/market-trend/market-trend.component'
import { InsuranceProtectionComponent } from './my-properties/component/insurance-protection/insurance-protection.component'
import { BookingSummaryComponent } from './my-properties/component/booking-summary/booking-summary.component'
import { ExpensesComponent } from './my-properties/component/expenses/expenses.component'
import { HostCardComponent } from './my-properties/component/host-card/host-card.component'
import { LodgeDefectComponent } from './my-properties/component/lodge-defect/lodge-defect.component'
import { KeyCollectComponent } from './my-properties/component/key-collect/key-collect.component'
import { EditTenancyModalComponent } from './my-properties/component/property-overview/component/edit-tenancy-modal/edit-tenancy-modal.component'
import { PropertyAddComponent } from './my-properties/pages/property-add/property-add.component'
import { ListToSellModalComponent } from './my-properties/component/property-overview/component/list-to-sell-modal/list-to-sell-modal.component'
import { EditStatusModalComponent } from './my-properties/component/property-overview/component/edit-status-modal/edit-status-modal.component'
import { CustomBasicCardComponent } from './my-properties/component/custom-basic-card/custom-basic-card.component'
import { SubscribeComponent } from './my-properties/component/custom-basic-card/component/subscribe/subscribe.component'
import { KeyCollectionBasicCardComponent } from './my-properties/component/custom-basic-card/component/key-collection/key-collection.component'
import { UnitRentalBasicCardComponent } from './my-properties/component/custom-basic-card/component/unit-rental/unit-rental.component'
import { RenovationStatusBasicCardComponent } from './my-properties/component/custom-basic-card/component/renovation-status/renovation-status.component'
import { ShortStayBasicCardComponent } from './my-properties/component/custom-basic-card/component/short-stay-setup/short-stay-setup.component'
import { RemoveFromListingModalComponent } from './my-properties/component/property-overview/component/remove-from-listing-modal/remove-from-listing-modal.component'
import { OwnProjectListingComponent } from './my-properties/pages/own-project-listing/own-project-listing.component'
import { DeveloperCardComponent } from './project-listings/component/developer-card/developer-card.component'
import { ProjectListComponent } from './project-listings/pages/project-list/project-list.component'
import { SalePriceCardComponent } from './project-listings/component/sale-price-card/sale-price-card.component'
import { MarketGradeCardComponent } from './project-listings/component/market-grade-card/market-grade-card.component'
import { AverageDailyRateComponent } from './project-listings/component/average-daily-rate/average-daily-rate.component'
import { RevenueRateComponent } from './project-listings/component/revenue-rate/revenue-rate.component'
import { HostPartnerComponent } from './project-listings/component/host-partner/host-partner.component'
import { RenoPartnerComponent } from './project-listings/component/reno-partner/reno-partner.component'
import { AgentCardComponent } from './project-listings/component/agent-card/agent-card.component'
import { ProjectOverviewCardComponent } from './project-listings/component/project-overview-card/project-overview-card.component'
import { ProjectDetailComponent } from './project-listings/pages/project-detail/project-detail.component'
import { ProjectOccupancyRateComponent } from './project-listings/component/project-occupancy-rate/project-occupancy-rate.component'
import { MyProfileComponent } from './my-profile/pages/my-profile/my-profile.component'
import { ProfileCardComponent } from './my-profile/component/profile-card/profile-card.component'
import { NotificationCardComponent } from './my-profile/component/notification-card/notification-card.component'
import { NetworkCardComponent } from './my-profile/component/network-card/network-card.component'
import { PasswordCardComponent } from './my-profile/component/password-card/password-card.component'
import { InvestmentProfileCardComponent } from './my-profile/component/investment-profile-card/investment-profile-card.component'
import { DashboardComponent } from './home/pages/dashboard/dashboard.component'
import { PropertyRevenueOverviewComponent } from './home/component/property-revenue-overview/property-revenue-overview.component'
import { PropertiesChartComponent } from './home/component/properties-chart/properties-chart.component'
import { PropertiesForRentComponent } from './home/component/properties-for-rent/properties-for-rent.component'
import { PropertiesForSaleComponent } from './home/component/properties-for-sale/properties-for-sale.component'
import { PropertyPreviewBannerComponent } from './home/component/property-preview-banner/property-preview-banner.component'
import { RecentActivityComponent } from './home/component/recent-activity/recent-activity.component'
import { MyProtectionComponent } from './home/component/my-protection/my-protection.component'
import { HasAccountSetupComponent } from './home/component/has-account-setup/has-account-setup.component'
import { HasServicesListingsComponent } from './has-services/pages/has-services-listings/has-services-listings.component'
import { HasServicesGridComponent } from './has-services/component/has-services-grid/has-services-grid.component'
import { HasServicesCardComponent } from './has-services/component/has-services-card/has-services-card.component'
import { RenoPartnersGridComponent } from './has-services/component/reno-partners-grid/reno-partners-grid.component'
import { RenoPartnersCardComponent } from './has-services/component/reno-partners-card/reno-partners-card.component'
import { PackagePricingsComponent } from './has-services/component/package-pricings/package-pricings.component'
import { RenoPartnersComponent } from './has-services/pages/reno-partners/reno-partners.component'
import { ProjectFilterComponent } from './has-services/component/project-filter/project-filter.component'
import { CheckoutComponent } from './checkout/pages/checkout/checkout.component'
import { TimelineCardComponent } from './my-properties/component/timeline-card/timeline-card.component'
import { ShortstayCardComponent } from './my-properties/component/shortstay-card/shortstay-card.component'

//Shared
import { SharedModule } from '../../shared/shared.module'

//Stores
import { ProfileStore } from './my-profile/store/profile.store'
import { SecuredStore } from './store/secured.store'
import { OrderSummaryComponent } from './checkout/component/order-summary/order-summary.component'
import { PaymentGatewayComponent } from './checkout/pages/payment-gateway/payment-gateway.component'
import { HasServicesStore } from './has-services/pages/store/has-services.store'
import { PaymentStatusComponent } from './checkout/pages/payment-status/payment-status.component'
import { ShortstayManagementComponent } from './has-services/pages/shortstay-management/shortstay-management.component'
import { CartItemCardComponent } from './checkout/component/cart-item-card/cart-item-card.component'
import { DashboardNoActiveUnitModalComponent } from './home/component/dashboard-no-active-unit-modal/dashboard-no-active-unit-modal.component'
import { NominalCardComponent } from './my-properties/component/nominal-card/nominal-card.component';
import { MonthlyEarningsComponent } from './home/component/monthly-earnings/monthly-earnings.component';
import { BankAccountComponent } from './my-profile/component/bank-account/bank-account.component'

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AngularSvgIconModule.forRoot(),
    ClipboardModule,
    SlickCarouselModule,
    SharedModule,
    NgxStripeModule.forRoot(
      'pk_test_51JeCDzL6mabzuPy30eNli5bX1RfVHcKZO5sOqTOTXLtVojfsesfb6HMjKu4dvfrgolDJncIlHHARAoactNn6B48F00e7Xq10jG'
    ),
  ],
  declarations: [
    SecuredComponent,
    PropertyListingComponent,
    PropertyCardComponent,
    PropertyGridComponent,
    PropertyDetailComponent,
    AddPropertyCardComponent,
    PropertyOverviewComponent,
    TotalBookedNightsComponent,
    OccupancyRateComponent,
    TotalRevenueComponent,
    OtaListingsComponent,
    MarketTrendComponent,
    InsuranceProtectionComponent,
    BookingSummaryComponent,
    ExpensesComponent,
    HostCardComponent,
    LodgeDefectComponent,
    KeyCollectComponent,
    EditTenancyModalComponent,
    PropertyAddComponent,
    ListToSellModalComponent,
    EditStatusModalComponent,
    CustomBasicCardComponent,
    SubscribeComponent,
    KeyCollectionBasicCardComponent,
    UnitRentalBasicCardComponent,
    RenovationStatusBasicCardComponent,
    ShortStayBasicCardComponent,
    RemoveFromListingModalComponent,
    OwnProjectListingComponent,
    DeveloperCardComponent,
    ProjectListComponent,
    SalePriceCardComponent,
    MarketGradeCardComponent,
    AverageDailyRateComponent,
    RevenueRateComponent,
    HostPartnerComponent,
    RenoPartnerComponent,
    AgentCardComponent,
    ProjectOverviewCardComponent,
    ProjectDetailComponent,
    ProjectOccupancyRateComponent,
    MyProfileComponent,
    ProfileCardComponent,
    NotificationCardComponent,
    NetworkCardComponent,
    PasswordCardComponent,
    InvestmentProfileCardComponent,
    DashboardComponent,
    PropertyRevenueOverviewComponent,
    PropertiesChartComponent,
    PropertiesForRentComponent,
    PropertiesForSaleComponent,
    PropertyPreviewBannerComponent,
    RecentActivityComponent,
    MyProtectionComponent,
    HasAccountSetupComponent,
    HasServicesListingsComponent,
    HasServicesGridComponent,
    HasServicesCardComponent,
    RenoPartnersGridComponent,
    RenoPartnersCardComponent,
    PackagePricingsComponent,
    RenoPartnersComponent,
    ProjectFilterComponent,
    CheckoutComponent,
    OrderSummaryComponent,
    TimelineCardComponent,
    ShortstayCardComponent,
    PaymentGatewayComponent,
    PaymentStatusComponent,
    ShortstayManagementComponent,
    CartItemCardComponent,
    DashboardNoActiveUnitModalComponent,
    NominalCardComponent,
    MonthlyEarningsComponent,
    BankAccountComponent,
  ],
  exports: [
    SecuredComponent,
    PropertyListingComponent,
    PropertyCardComponent,
    PropertyGridComponent,
    PropertyDetailComponent,
    AddPropertyCardComponent,
    PropertyOverviewComponent,
    TotalBookedNightsComponent,
    OccupancyRateComponent,
    TotalRevenueComponent,
    OtaListingsComponent,
    MarketTrendComponent,
    InsuranceProtectionComponent,
    BookingSummaryComponent,
    ExpensesComponent,
    HostCardComponent,
    LodgeDefectComponent,
    KeyCollectComponent,
    EditTenancyModalComponent,
    PropertyAddComponent,
    ListToSellModalComponent,
    EditStatusModalComponent,
    CustomBasicCardComponent,
    SubscribeComponent,
    KeyCollectionBasicCardComponent,
    UnitRentalBasicCardComponent,
    RenovationStatusBasicCardComponent,
    ShortStayBasicCardComponent,
    RemoveFromListingModalComponent,
    OwnProjectListingComponent,
    DeveloperCardComponent,
    ProjectListComponent,
    SalePriceCardComponent,
    MarketGradeCardComponent,
    AverageDailyRateComponent,
    RevenueRateComponent,
    HostPartnerComponent,
    RenoPartnerComponent,
    AgentCardComponent,
    ProjectOverviewCardComponent,
    ProjectDetailComponent,
    ProjectOccupancyRateComponent,
    MyProfileComponent,
    ProfileCardComponent,
    NotificationCardComponent,
    NetworkCardComponent,
    PasswordCardComponent,
    InvestmentProfileCardComponent,
    DashboardComponent,
    PropertyRevenueOverviewComponent,
    PropertiesChartComponent,
    PropertiesForRentComponent,
    PropertiesForSaleComponent,
    PropertyPreviewBannerComponent,
    RecentActivityComponent,
    MyProtectionComponent,
    HasAccountSetupComponent,
    HasServicesListingsComponent,
    HasServicesGridComponent,
    HasServicesCardComponent,
    RenoPartnersGridComponent,
    RenoPartnersCardComponent,
    PackagePricingsComponent,
    RenoPartnersComponent,
    ProjectFilterComponent,
    TimelineCardComponent,
    ShortstayCardComponent,
  ],
  providers: [ProfileStore, SecuredStore, HasServicesStore],
})
export class SecuredPageModule {}
