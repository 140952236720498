<div class="properties-chart card">
  <div class="row" style="height: 100%">
    <div class="col-12 col-lg-3 detail-container">
      <div class="title">Properties</div>
      <div class="unit">
        <span class="unit-label">Total Units</span>
        <span class="unit-val">{{ totalUnits }}</span>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <div class="content-container">
        <div id="PieChart">
          <apx-chart
            [dataLabels]="chartOptions.dataLabels"
            [colors]="chartOptions.colors"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [responsive]="chartOptions.responsive"
            [legend]="chartOptions.legend">
          </apx-chart>
        </div>
        <div style="flex: 1"></div>
        <div class="see-all mt-3">
          <div (click)="navPropertyList()">
            <span>See All Properties</span>
            <img src="app/assets/arrow-right.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
