<div class="my-protection-container card">
  <div class="title">My Protection</div>
  <div class="content mt-3">
    <div class="content-info">
      <div class="amount">{{ unprotected }}</div>
      <div class="label">Unprotected</div>
    </div>
    <div class="separator"></div>
    <div class="content-info">
      <div class="amount">{{ protected }}</div>
      <div class="label">Protected</div>
    </div>
  </div>
  <div class="navigation-link mt-3" onclick="Chatra('openChat', true);">
    <span>Buy Protection</span>
    <img src="app/assets/arrow-right.svg" />
  </div>
</div>
