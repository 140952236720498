import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { UserProfile } from 'src/app/modules/shared/models/profile.model'

@Injectable()
export class ProfileStore {
  initialState: UserProfile = {
    data: {
      id: '',
      name: '',
      email: '',
      is_verified: false,
      profile: {
        avatar_url: '',
        referral_code: '',
        phone: '',
        birth_date: '',
        area_of_interests: [],
        notification_updates: false,
        notification_financial_report: false,
        notification_marketing_newsletter: false,
        notification_investment_opportunity: false,
        aggresiveness: '',
        goals_setting: '',
        type_of_investment: '',
        id_card_no: '',
        bank_acc_name: '',
        bank_name: '',
        bank_acc_no: '',
      },
      social_media: {
        facebook: null,
        google: null,
        wechat: null,
      },
    },
  }

  private readonly _stateSource = new BehaviorSubject<UserProfile>(
    this.initialState
  )

  constructer() {}

  getState(): Observable<UserProfile> {
    return this._stateSource.asObservable()
  }

  getStepsWithoutSub(): UserProfile {
    return this._stateSource.getValue()
  }

  setState = (data: UserProfile) => {
    this._stateSource.next(data)
  }

  resetState() {
    this._stateSource.next(this.initialState)
  }
}
