<div
  class="row property-grid gx-3 gx-lg-4"
  *ngIf="dataList?.length > 0; else no_data">
  <div class="col-6 col-xl-3 mb-3" *ngFor="let property of dataList">
    <app-property-card [property]="property"></app-property-card>
  </div>
  <div class="col-6 col-xl-3 mb-3">
    <app-add-property-card></app-add-property-card>
  </div>
</div>
<ng-template #no_data>
  <div class="row property-grid">
    <div class="col-6 col-xl-3 mb-3">
      <app-property-card></app-property-card>
    </div>
    <div class="col-6 col-xl-3 mb-3">
      <app-add-property-card></app-add-property-card>
    </div>
  </div>
</ng-template>
