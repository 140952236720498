import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import {
  ApplyPromoRes,
  OrderReq,
  OrderRes,
} from 'src/app/modules/shared/models/payment.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { PaymentService } from 'src/app/modules/shared/service/payment.service'
import {
  CartItem,
  User,
  UserCart,
} from 'src/app/modules/shared/state/user/user.model'
import { SecuredStore } from '../../../store/secured.store'
import { PromoModel } from '../../component/order-summary/model/promo.model'

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass'],
})
export class CheckoutComponent implements OnInit {
  checkoutDatas: UserCart
  items: CartItem[]
  userData: User
  allCarts: UserCart[]

  private readonly _checkoutDataSource = new BehaviorSubject<UserCart>(null)
  readonly checkoutDataState = this._checkoutDataSource.asObservable()

  private readonly _loadingSource = new BehaviorSubject<boolean>(null)
  readonly loadingState = this._loadingSource.asObservable()

  private readonly _promoCodeSource = new BehaviorSubject<PromoModel>(null)
  readonly promoCodeState = this._promoCodeSource.asObservable()

  constructor(
    private securedStore: SecuredStore,
    private userStore: UserStore,
    private paymentService: PaymentService,
    private router: Router,
    private toastService: CustomToastService
  ) {
    this.securedStore.updatePageTitle('Shopping Cart')
  }

  ngOnInit(): void {
    this.checkoutDatas = this.userStore.getCurrentUserCart()
    this.items = this.checkoutDatas?.cart
    this._checkoutDataSource.next(this.checkoutDatas)
    this.userData = this.userStore.getUser()
    this.allCarts = this.userStore.getCart()
  }

  onRemovePackage = (projId, unitId, serviceId) => {
    let projectIdx = this.checkoutDatas.cart.findIndex(proj => {
      if (proj.project_id == projId) {
        return proj
      }
    })

    if (projectIdx != -1) {
      let unitIdx = this.checkoutDatas.cart[projectIdx].unit.findIndex(unit => {
        if (unit.unit_type_id == unitId) {
          return unit
        }
      })

      if (unitIdx != -1) {
        this.checkoutDatas.cart[projectIdx].unit[unitIdx].service =
          this.checkoutDatas.cart[projectIdx].unit[unitIdx].service.filter(
            serv => {
              if (serv.service_id != serviceId) {
                return serv
              }
            }
          )

        if (
          this.checkoutDatas.cart[projectIdx].unit[unitIdx].service.length <= 0
        ) {
          this.checkoutDatas.cart[projectIdx].unit = this.checkoutDatas.cart[
            projectIdx
          ].unit.filter(unit => {
            if (unit.unit_type_id != unitId) {
              return unit
            }
          })
        }
      }

      if (this.checkoutDatas.cart[projectIdx].unit.length <= 0) {
        this.checkoutDatas.cart = this.checkoutDatas.cart.filter(proj => {
          if (proj.project_id != projId) {
            return proj
          }
        })
      }
    }

    this._checkoutDataSource.next(this.checkoutDatas)
    this.items = this.checkoutDatas?.cart

    // update existing cart
    let currUsrCartIdx = this.allCarts.findIndex(data => {
      if (data.user.id == this.userData.user.id) {
        return data
      }
    })
    this.allCarts[currUsrCartIdx] = this.checkoutDatas
    this.userStore.setCart(this.allCarts)
  }

  getPromo = (promoCode: string) => {
    if (promoCode == '') {
      // this.toastService.show(
      //   'Error',
      //   `Promo code application Error : No code applied`
      // )
      this._promoCodeSource.next({
        promo_err: true,
      })
    } else {
      this.paymentService
        .getPromo(promoCode)
        .then((res: any) => {
          // this.toastService.show('Success', `Promo code applied`)
          let promoRes: ApplyPromoRes = res?.data
          this._promoCodeSource.next({
            amount: Number(promoRes.amount),
            code: promoRes.code,
            type: promoRes.type,
            promo_err: false,
          })
        })
        .catch(err => {
          // this.toastService.show(
          //   'Error',
          //   `Promo code application Error : ${err.error.message}`
          // )
          this._promoCodeSource.next({
            promo_err: true,
          })
        })
        .finally(() => {})
    }
  }

  addOrder = (req: OrderReq) => {
    this._loadingSource.next(true)
    this.paymentService
      .addOrder(req)
      .then((res: OrderRes) => {
        this.doPayment(res.order_id, res.total)
      })
      .catch(err => {
        this.toastService.show('Error', `Order Error : ${err.error.message}`)
        console.log(err)
        this._loadingSource.next(false)
      })
      .finally(() => {})
  }

  doPayment = (orderId: string, total: number) => {
    this.paymentService
      .generatePaymentGatewayToken(orderId)
      .then((paymentResp: any) => {
        this.router.navigate(['/checkout/payment-gateway'], {
          queryParams: {
            token: paymentResp.token,
            price: total,
            orderId: orderId,
          },
        })
      })
      .catch(err => {
        this.toastService.show('Error', `Order Error : ${err.error.message}`)
        console.log(err)
      })
      .finally(() => {
        this._loadingSource.next(false)
      })
  }
}
