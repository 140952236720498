<div class="privpolicy-layout">
  <div class="background-pattern-container">
    <img
      src="app/assets/auth-background-pattern.svg"
      class="d-none d-sm-block background-pattern" />
    <img
      src="app/assets/auth-background-pattern-mobile.svg"
      class="d-block d-sm-none background-pattern" />
  </div>
  <div class="privpolicy-card-container">
    <div class="card privpolicy-card">
      <h1>Privacy Policy</h1>
      <p>Last modified: 11/1/17</p>
      <h2>Introduction</h2>
      <p>
        <strong>HostAStay.com</strong> (<strong>&#8220;Company&#8221;</strong>
        or <strong>&#8220;We&#8221;</strong>) respect your privacy and are
        committed to protecting it through our compliance with this policy.
      </p>
      <p>
        This policy describes the types of information we may collect from you
        or that you may provide when you visit the website www.hostastay.com
        (our <strong>&#8220;Website&#8221;</strong>) and our practices for
        collecting, using, maintaining, protecting and disclosing that
        information.
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li><p>On this Website.</p></li>
        <li>
          <p>
            In e-mail, text and other electronic messages between you and this
            Website.
          </p>
        </li>
        <li>
          <p>
            Through mobile and desktop applications you download from this
            Website, which provide dedicated non-browser-based interaction
            between you and this Website.
          </p>
        </li>
        <li>
          <p>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this policy.
          </p>
        </li>
        <li>
          <p>When you access third-party applications through this Website.</p>
        </li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          <p>
            us offline or through any other means, including on any other
            website operated by Company or any third party (including our
            affiliates and subsidiaries); or
          </p>
        </li>
        <li>
          <p>
            any third party (including our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from or on the Website
          </p>
        </li>
      </ul>
      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website. By accessing or using this Website, you agree to this privacy
        policy. This policy may change from time to time. Your continued use of
        this Website after we make changes is deemed to be acceptance of those
        changes, so please check the policy periodically for updates.
      </p>
      <h2>Children Under the Age of 13</h2>
      <p>
        Our Website is not intended for children under 13 years of age. No one
        under age 13 may provide any personal information to or on the Website.
        We do not knowingly collect personal information from children under 13.
        If you are under 13, do not use or provide any information on this
        Website or on or through any of its features. If we learn we have
        collected or received personal information from a child under 13 without
        verification of parental consent, we will delete that information. If
        you believe we might have any information from or about a child under
        13, please contact us at
        <span id="cloak584e8eaf1d4828df1033913c342aa6e8"
          ><a href="mailto:enquiry@hostastay.com"
            >enquiry@hostastay.com</a
          ></span
        >.
      </p>
      <h2>Information We Collect About You and How We Collect It</h2>
      <p>
        We collect several types of information from and about users of our
        Website, including information:
      </p>
      <ul>
        <li>
          <p>
            by which you may be personally identified, including but not limited
            to name, postal address, e-mail address, telephone number, or
            financial information (<strong
              >&#8220;personal information&#8221;</strong
            >);
          </p>
        </li>
        <li>
          <p>
            that is about you but individually does not identify you; and/or
          </p>
        </li>
        <li>
          <p>
            about your internet connection, the equipment you use to access our
            Website and usage details.
          </p>
        </li>
      </ul>
      <p>We collect this information:</p>
      <ul>
        <li><p>Directly from you when you provide it to us.</p></li>
        <li>
          <p>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses and
            information collected through cookies, web beacons and other
            tracking technologies.
          </p>
        </li>
        <li><p>From third parties, for example, our business partners.</p></li>
      </ul>
      <h2>Information You Provide to Us.</h2>
      <p>The information we collect on or through our Website may include:</p>
      <ul>
        <li>
          <p>
            Information that you provide by filling in forms on our Website.
            This includes information provided at the time of registering to use
            our Website, subscribing for and utilizing our services, posting
            material or requesting further services. We may also ask you for
            information when you enter a contest or promotion sponsored by us,
            and when you report a problem with our Website.
          </p>
        </li>
        <li>
          <p>
            Records and copies of your correspondence (including e-mail
            addresses), if you contact us.
          </p>
        </li>
        <li>
          <p>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </p>
        </li>
        <li>
          <p>
            Details of transactions you carry out through our Website and of the
            fulfillment of your orders. You may be required to provide financial
            information before placing an order through our Website.
          </p>
        </li>
        <li><p>Your search queries on the Website.</p></li>
      </ul>
      <p>
        You also may provide information to be published or displayed
        (hereinafter, <strong>&#8220;posted&#8221;</strong>) on public areas of
        the Website, or transmitted to other users of the Website or third
        parties (collectively,
        <strong>&#8220;User Contributions&#8221;</strong>). Your User
        Contributions are posted on and transmitted to others at your own risk.
        Please be aware that no security measures are perfect or impenetrable.
        Additionally, we cannot control the actions of other users of the
        Website with whom you may choose to share your User Contributions.
        Therefore, we cannot and do not guarantee that your User Contributions
        will not be viewed by unauthorized persons.
      </p>
      <p>
        Information We Collect Through Automatic Data Collection Technologies.
        As you navigate through and interact with our Website, we may use
        automatic data collection technologies to collect certain information
        about your equipment, browsing actions and patterns, including:
      </p>
      <ul>
        <li>
          <p>
            Details of your visits to our Website, including traffic data,
            location data, logs and other communication data and the resources
            that you access and use on the Website.
          </p>
        </li>
        <li>
          <p>
            Information about your computer and internet connection, including
            your IP address, operating system and browser type.
          </p>
        </li>
      </ul>
      <p>
        We also may use these technologies to collect information about your
        online activities over time and across third-party websites or other
        online services (behavioral tracking).
      </p>
      <p>
        The information we collect automatically may include statistical and
        personal information, and we may maintain it or associate it with
        personal information we collect in other ways or receive from third
        parties. It helps us to improve our Website and to deliver a better and
        more personalized service, including by enabling us to:
      </p>
      <ul>
        <li><p>Estimate our audience size and usage patterns.</p></li>
        <li>
          <p>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </p>
        </li>
        <li><p>Speed up your searches.</p></li>
        <li><p>Recognize you when you return to our Website.</p></li>
      </ul>
      <p>
        The technologies we use for this automatic data collection may include:
      </p>
      <ul>
        <li>
          <p>
            <strong>Cookies (or browser cookies).</strong> A cookie is a small
            file placed on the hard drive of your computer. You may refuse to
            accept browser cookies by activating the appropriate setting on your
            browser. However, if you select this setting you may be unable to
            access certain parts of our Website. Unless you have adjusted your
            browser setting so that it will refuse cookies, our system will
            issue cookies when you direct your browser to our Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Flash Cookies.</strong> Certain features of our Website may
            use local stored objects (or Flash cookies) to collect and store
            information about your preferences and navigation to, from and on
            our Website. Flash cookies are not managed by the same browser
            settings as are used for browser cookies.
          </p>
        </li>
        <li>
          <p>
            <strong>Web Beacons.</strong> Pages of our the Website and our
            e-mails may contain small electronic files known as web beacons
            (also referred to as clear gifs. pixel tags and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an e-mail and for other related
            website statistics (for example, recording the popularity of certain
            website content and verifying system and server integrity).
          </p>
        </li>
      </ul>
      <h2>Third-party Use of Cookies and Other Tracking Technologies.</h2>
      <p>
        Some content or applications, including advertisements, on the Website
        are served by third-parties, including advertisers, ad networks and
        servers, content providers and application providers. These third
        parties may use cookies alone or in conjunction with web beacons or
        other tracking technologies to collect information about you when you
        use our website. The information they collect may be associated with
        your personal information or they may collect information, including
        personal information, about your online activities over time and across
        different websites and other online services. They may use this
        information to provide you with interest-based (behavioral) advertising
        or other targeted content.
      </p>
      <p>
        We do not control these third parties&#8217; tracking technologies or
        how they may be used. If you have any questions about an advertisement
        or other targeted content, you should contact the responsible provider
        directly.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </p>
      <ul>
        <li><p>To present our Website and its contents to you.</p></li>
        <li>
          <p>
            To provide you with information, products or services that you
            request from us.
          </p>
        </li>
        <li><p>To fulfill any other purpose for which you provide it.</p></li>
        <li>
          <p>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </p>
        </li>
        <li>
          <p>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </p>
        </li>
        <li>
          <p>
            To allow you to participate in interactive features on our Website.
          </p>
        </li>
        <li>
          <p>
            In any other way we may describe when you provide the information.
          </p>
        </li>
        <li><p>For any other purpose with your consent.</p></li>
      </ul>
      <p>
        We may also use your information to contact you about our own and
        third-parties&#8217; goods and services that may be of interest to you.
        If you do not want us to use your information in this way, please
        contact us at
        <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a>.
      </p>
      <p>
        We may use the information we have collected from you to enable us to
        display advertisements to our advertisers&#8217; target audiences. Even
        though we do not disclose your personal information for these purposes
        without your consent, if you click on or otherwise interact with an
        advertisement, the advertiser may assume that you meet its target
        criteria.
      </p>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>
      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <ul>
        <li><p>To our subsidiaries and affiliates.</p></li>
        <li>
          <p>
            To contractors, service providers and other third parties we use to
            support our business and who are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </p>
        </li>
        <li>
          <p>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution or other sale or transfer
            of some or all of HostAStay.com’s assets, whether as a going concern
            or as part of bankruptcy, liquidation or similar proceeding, in
            which personal information held by HostAStay.com’s about our Website
            users is among the assets transferred.
          </p>
        </li>
        <li>
          <p>
            To third parties to market their products or services to you if you
            have not opted out of these disclosures by notifying us by email. We
            contractually require these third parties to keep personal
            information confidential and use it only for the purposes for which
            we disclose it to them.
          </p>
        </li>
        <li><p>To fulfill the purpose for which you provide it.</p></li>
        <li>
          <p>
            For any other purpose disclosed by us when you provide the
            information.
          </p>
        </li>
        <li><p>With your consent.</p></li>
      </ul>
      <p>We may also disclose your personal information:</p>
      <ul>
        <li>
          <p>
            To comply with any court order, law or legal process, including to
            respond to any government or regulatory request.
          </p>
        </li>
        <li>
          <p>
            To enforce or apply our terms of use and other agreements, including
            for billing and collection purposes.
          </p>
        </li>
        <li>
          <p>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of HostAStay.com, our customers or
            others. This includes exchanging information with other companies
            and organizations for the purposes of fraud protection and credit
            risk reduction.
          </p>
        </li>
      </ul>
      <h2>Choices About How We Use and Disclose Your Information</h2>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <ul>
        <li>
          <p>
            <strong>Tracking Technologies and Advertising.</strong> You can set
            your browser to refuse all or some browser cookies, or to alert you
            when cookies are being sent. To learn how you can manage your Flash
            cookie settings, visit the Flash player settings page on
            Adobe&#8217;s website. If you disable or refuse cookies, please note
            that some parts of this site may then be inaccessible or not
            function properly.
          </p>
        </li>
        <li>
          <p>
            <strong
              >Disclosure of Your Information for Third-Party
              Advertising.</strong
            >
            If you do not want us to share your personal information with
            unaffiliated or non-agent third parties for promotional purposes,
            you can opt-out by sending us an e-mail stating your request to
            <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a>.
          </p>
        </li>
        <li>
          <p>
            <strong>Promotional Offers from the Company.</strong> If you do not
            wish to have your contact information used by the Company to promote
            our own or third parties&#8217; products or services, you can
            opt-out by sending us an e-mail stating your request to
            <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a>. If
            we have sent you a promotional e-mail, you may send us a return
            e-mail asking to be omitted from future e-mail distributions. This
            opt out does not apply to information provided to the Company as a
            result of a product purchase, warranty registration, product service
            experience or other transactions.
          </p>
        </li>
        <li>
          <p>
            <strong>Targeted Advertising.</strong> If you do not want us to use
            information that we collect or that you provide to us to deliver
            advertisements according to our advertisers&#8217; target-audience
            preferences, you can opt-out by sending us an e-mail stating your
            request to
            <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a>.
            For this opt-out to function, you must have your browser set to
            accept browser cookies.We do not control third parties&#8217;
            collection or use of your information to serve interest-based
            advertising. However these third parties may provide you with ways
            to choose not to have your information collected or used in this
            way. You can opt out of receiving targeted ads from members of the
            Network Advertising Initiative (&#8220;NAI&#8221;) on the
            NAI&#8217;s website.
          </p>
        </li>
      </ul>
      <h2>Accessing and Correcting Your Information</h2>
      <p>
        You can review and change your personal information by logging into the
        Website and visiting your account profile page.
      </p>
      <p>
        You may also send us an e-mail at
        <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a> to
        request access to, correct or delete any personal information that you
        have provided to us. We cannot delete your personal information except
        by also deleting your user account. We may not accommodate a request to
        change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>
      <p>
        If you delete your User Contributions from the Website, copies of your
        User Contributions may remain viewable in cached and archived pages, or
        might have been copied or stored by other Website users. Proper access
        and use of information provided on the Website, including User
        Contributions, is governed by our terms of use.
      </p>
      <h2>Privacy Rights</h2>
      <p>
        Personal Data Protection Act 2010 (PDPA) permits users of our Website
        that are Malaysia residents to request certain information regarding our
        disclosure of personal information to third parties for their direct
        marketing purposes. To make such a request, please send an e-mail to
        <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a>.
      </p>
      <h2>Data Security</h2>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration and disclosure. All information you provide to us is stored
        on our secure servers behind firewalls. Any payment transactions will be
        encrypted using SSL technology.
      </p>
      <p>
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of our Website, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Website. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Website.
      </p>
      <h2>Changes to Our Privacy Policy</h2>
      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page either with a notice that the privacy policy has been updated
        on the Website home page or by email. If we make material changes to how
        we treat our users&#8217; personal information, we will notify you by
        e-mail to the primary e-mail address specified in your account or
        through a notice on the Website home page. The date the privacy policy
        was last revised is identified at the top of the page. You are
        responsible for ensuring we have an up-to-date active and deliverable
        e-mail address for you, and for periodically visiting our Website and
        this privacy policy to check for any changes.
      </p>
      <h2>Contact Information</h2>
      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at
        <a href="mailto:enquiry@hostastay.com">enquiry@hostastay.com</a>.
      </p>
    </div>
    <div class="bottom-bar">
      <button class="btn btn-primary" (click)="redirectHome()">
        Back to Home
      </button>
    </div>
  </div>
</div>
