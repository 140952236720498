import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import {
  OrderReq,
  OrderRes,
  TransactionRegistrationItem,
} from '../models/payment.model'

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  async getTransactionToken(
    transactionRegistrationItem: TransactionRegistrationItem[]
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.TRANSACTIONS)}`,
          { ...transactionRegistrationItem[0] }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async addOrder(req: OrderReq) {
    return new Promise((resolve, reject) => {
      this.http
        .post<OrderRes>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.ORDERS)}`,
          req
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async generatePaymentGatewayToken(order_id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.PAYMENTS)}`,
          { order_id: order_id }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getPromo(promoCode: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.baseUrl}${new ApiFactory(
            Endpoints.PROMO_CODE
          )}/${promoCode}`
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
