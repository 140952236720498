import { Location } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'

import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import { PropertyInfo } from 'src/app/modules/shared/models/property.model'

import {
  getCountryList,
  getStateList,
  getProjectTypeList,
  getLandTypeList,
  getTenancyTypeList,
} from 'src/app/modules/shared/factory/option.factory'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { Project } from 'src/app/modules/shared/models/project.model'
import { SecuredStore } from '../../../store/secured.store'
import { CustomModalService } from 'src/app/modules/shared/service/custom-modal.service'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'

@Component({
  selector: 'app-property-add',
  templateUrl: './property-add.component.html',
  styleUrls: ['./property-add.component.sass'],
})
export class PropertyAddComponent implements OnInit {
  isHasProject: boolean = false
  projectId: string
  submitted: boolean = false
  isLoading: boolean = false
  addPropertyForm = new FormGroup({
    project_name: new FormControl('', Validators.required),
    project_type: new FormControl('', Validators.required),
    land_type: new FormControl('', Validators.required),
    address_1: new FormControl('', Validators.required),
    address_2: new FormControl(''),
    state: new FormControl('', Validators.required),
    area: new FormControl('', Validators.required),
    post_code: new FormControl('', Validators.required),
    unit_number: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
  })

  countryList = getCountryList()
  stateList = getStateList()
  projectTypeList = getProjectTypeList()
  landTypeList = getLandTypeList()
  tenancyTypeList = getTenancyTypeList()

  private readonly _disableFieldsSource = new BehaviorSubject<boolean>(false)
  readonly disableFieldsState = this._disableFieldsSource.asObservable()

  errObject = {
    message: '',
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private propertiesService: PropertiesService,
    private projectsService: ProjectsService,
    private securedStore: SecuredStore,
    private customModalService: CustomModalService,
    private toastService: CustomToastService,
    private router: Router
  ) {
    this.securedStore.updatePageTitle('Add Property')
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('isHasProject') == 'true') {
      this.isHasProject = true
      this._disableFieldsSource.next(true)
      this.projectId = this.route.snapshot.paramMap.get('projectId')
      this.projectsService
        .getProjectById(this.projectId)
        .then((resp: Project) => {
          this.addPropertyForm.get('project_name').setValue(resp.name)
          this.addPropertyForm.get('project_type').setValue(resp.type)
          this.addPropertyForm.get('land_type').setValue(resp.land_type)
          this.addPropertyForm.get('address_1').setValue(resp.address_1)
          this.addPropertyForm.get('address_2').setValue(resp.address_2)
          this.addPropertyForm.get('state').setValue(resp.state)
          this.addPropertyForm.get('area').setValue(resp.area)
          this.addPropertyForm.get('post_code').setValue(resp.post_code)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }
  }

  onBackPressed() {
    this.location.back()
  }

  submit() {
    this.addPropertyForm.setErrors({ serverError: null })
    this.addPropertyForm.updateValueAndValidity()
    this.submitted = true
    this.addPropertyForm.markAllAsTouched()
    if (this.addPropertyForm.valid) {
      if (this.isHasProject) {
        const request = {
          project_id: this.projectId,
          ...(<PropertyInfo>this.addPropertyForm.value),
        }

        this.isLoading = true
        this.propertiesService
          .addHasProperty(request)
          .then(resp => {
            this.toastService.show(
              'Your Unit Submitted!',
              `Your request has been succesfully registered.`
            )
            this.router.navigate(['/my-properties'])
          })
          .catch(err => {
            this.errObject.message = 'Unexpected error occurred'
            this.addPropertyForm.setErrors({
              serverError: true,
            })
            this.toastService.show('Error', this.errObject.message)
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = true
        this.propertiesService
          .addCustomProperty(<PropertyInfo>this.addPropertyForm.value)
          .then(resp => {
            this.toastService.show(
              'Your Unit Submitted!',
              `Your request has been succesfully registered.`
            )
            this.router.navigate(['/my-properties'])
          })
          .catch(err => {
            this.errObject.message = 'Unexpected error occurred'
            this.addPropertyForm.setErrors({
              serverError: true,
            })
            this.toastService.show('Error', this.errObject.message)
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
