<div class="source-of-booking-container card">
  <span>Source Of Booking</span>
  <div class="pie-chart-container">
    <div id="sourcePieChart">
      <apx-chart
        [dataLabels]="chartOptions.dataLabels"
        [colors]="chartOptions.colors"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [legend]="chartOptions.legend"
        [responsive]="chartOptions.responsive">
      </apx-chart>
    </div>
  </div>
</div>
