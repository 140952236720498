import { Component, OnInit } from '@angular/core'
import { CustomToastService } from '../../service/custom-toast.service'

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.sass'],
})
export class CustomToastComponent implements OnInit {
  show = false
  autohide = true
  toastList: any[] = []

  constructor(public toastService: CustomToastService) {}

  ngOnInit(): void {
    // console.log('toast init called')
  }

  onHide(toast) {
    // console.log('hide triggered')
    this.toastService.remove(toast)
  }
}
