import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.sass'],
})
export class VerifyComponent implements OnInit {
  success: boolean = false

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.success = params.success == 'true' ? true : false
      if (this.success) {
        this.router.navigate([`/`])
      }
    })
  }

  ngOnInit(): void {}
}
