<div class="card purchases mb-4">
  <div class="card-content">
    <div class="purchase-category">
      <div class="purchase-title">
        <svg-icon
          class="me-2"
          src="app/assets/chevron-down.svg"
          (click)="onClickHide()"
          [ngClass]="{ hidden: isHidden }"></svg-icon>
        <h2>{{ serviceName }}</h2>
      </div>
      <div class="purchase-price">
        <h2 class="me-3">
          {{ packagePrice | currency: "MYR ":"symbol":"1.0-2" }}
        </h2>
        <div (click)="onRemovePackage(projectId, unitId, serviceId)">
          <svg-icon src="app/assets/cross.svg"></svg-icon>
        </div>
      </div>
    </div>
    <div class="purchase-item mt-2 pt-2" *ngIf="!isHidden">
      <h4>{{ packageName }}</h4>
      <!-- <p>{{ packageDesc }}</p> -->
      <p class="package-desc" [innerHTML]="packageDesc"></p>
    </div>
  </div>
</div>
