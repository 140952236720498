import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ApexOptions, ChartComponent } from 'ng-apexcharts'
import { Observable } from 'rxjs'
import { RateSummary } from 'src/app/modules/shared/models/project.model'

@Component({
  selector: 'app-revenue-rate',
  templateUrl: './revenue-rate.component.html',
  styleUrls: ['./revenue-rate.component.sass'],
})
export class RevenueRateComponent implements OnInit {
  @Input() revenueRateState: Observable<RateSummary>
  @ViewChild('revenuechart') chart: ChartComponent
  public chartOptions: Partial<ApexOptions>
  todayValue: number

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: 'RM',
          data: [0, 0],
        },
      ],
      chart: {
        height: 150,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
        offsetX: 5,
        background: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 7,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: ['', ''],
      },
      colors: ['#26A69A'],
      yaxis: {
        show: false,
      },
    }
  }

  ngOnInit(): void {
    this.revenueRateState.subscribe(data => {
      if (data) {
        this.todayValue = data.today
        this.chartOptions = {
          series: [
            {
              name: 'RM',
              data: [data.prev_month.amount, data.current_month.amount],
            },
          ],
          chart: {
            height: 150,
            type: 'line',
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -10,
            offsetX: 5,
            background: {
              enabled: false,
            },
          },
          stroke: {
            curve: 'smooth',
          },
          markers: {
            size: 7,
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: [data.prev_month.label, data.current_month.label],
          },
          colors: ['#26A69A'],
          yaxis: {
            show: false,
          },
        }
      }
    })
  }
}
