import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-grey-summary-card',
  templateUrl: './grey-summary-card.component.html',
  styleUrls: ['./grey-summary-card.component.sass'],
})
export class GreySummaryCardComponent implements OnInit {
  @Input() imageurl: string
  @Input() title: string
  @Input() type: string // nominal or percentage

  private readonly _showInfoDialogSource = new BehaviorSubject<boolean>(false)
  readonly showInfoDialogState = this._showInfoDialogSource.asObservable()
  showInfo: boolean = false

  constructor() {}

  ngOnInit(): void {
    this.showInfoDialogState.subscribe(val => {
      this.showInfo = val
    })
  }

  mouseEnter(div: string) {
    this._showInfoDialogSource.next(true)
  }

  mouseLeave(div: string) {
    this._showInfoDialogSource.next(false)
  }
}
