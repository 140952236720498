import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import {
  AggresivenessList,
  GoalSettingList,
  InvestmentTypeList,
  Option,
} from 'src/app/modules/shared/factory/option.factory'
import { ProfileStore } from '../../store/profile.store'

@Component({
  selector: 'app-investment-profile-card',
  templateUrl: './investment-profile-card.component.html',
  styleUrls: ['./investment-profile-card.component.sass'],
})
export class InvestmentProfileCardComponent implements OnInit {
  investmentFormgroup = new FormGroup({
    aggresiveness: new FormControl(''),
    goals_setting: new FormControl(''),
    invest_type: new FormControl(''),
  })

  aggresivenessList: Option[] = AggresivenessList
  goalSettingList: Option[] = GoalSettingList
  investmentTypeList: Option[] = InvestmentTypeList

  constructor(private profileStore: ProfileStore) {}

  ngOnInit(): void {
    this.profileStore.getState().subscribe({
      next: res => {
        this.investmentFormgroup
          .get('aggresiveness')
          .setValue(
            res.data.profile.aggresiveness == null
              ? ''
              : res.data.profile.aggresiveness
          )
        this.investmentFormgroup
          .get('goals_setting')
          .setValue(
            res.data.profile.goals_setting == null
              ? ''
              : res.data.profile.goals_setting
          )
        this.investmentFormgroup
          .get('invest_type')
          .setValue(
            res.data.profile.type_of_investment == null
              ? ''
              : res.data.profile.type_of_investment
          )
      },
    })
  }
}
