import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AppRoutingModule } from 'src/app/app-routing.module'

import { BareComponent } from './bare.component'
import { NotFoundComponent } from './404/not-found/not-found.component'
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BareChatraComponent } from './bare-chatra/bare-chatra/bare-chatra.component'

@NgModule({
  imports: [CommonModule, BrowserModule, AppRoutingModule],
  declarations: [
    BareComponent,
    NotFoundComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    BareChatraComponent,
  ],
  exports: [BareComponent],
})
export class BarePageModule {}
