<div class="rate-container">
  <div class="title">Occupancy Rate</div>
  <div class="content">
    <div class="graph">
      <div id="occupancychart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [dataLabels]="chartOptions.dataLabels"
          [grid]="chartOptions.grid"
          [stroke]="chartOptions.stroke"
          [markers]="chartOptions.markers"
          [colors]="chartOptions.colors"></apx-chart>
      </div>
    </div>
    <div class="price-summary">
      {{ todayValue | currency: "MYR ":"symbol":"1.0-2" }}
    </div>
    <!-- <div class="view-rates">
      View Rates <img src="app/assets/arrow-right.svg" />
    </div> -->
  </div>
</div>
