<div class="price-container">
  <div class="card order-summary">
    <h2 class="label">Order Summary</h2>
    <div class="order-items" *ngFor="let proj of projects">
      <div class="item mb-3" *ngFor="let unit of proj?.unit">
        <h4>{{ proj?.project_name }}</h4>
        <div
          class="d-flex align-items-center justify-content-between item-price"
          *ngFor="let service of unit?.service">
          <h4>{{ service?.service_name }}</h4>
          <h4>
            {{ service?.package[0]?.price | currency: "MYR ":"symbol":"1.0-2" }}
          </h4>
        </div>
      </div>
    </div>
    <div class="promo-container" [ngClass]="{ 'promo-err': isPromoError }">
      <div class="row">
        <div class="col-4 d-flex align-items-center"><h4>Promo Code</h4></div>
        <div class="col-8">
          <form [formGroup]="orderSummaryForm">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                formControlName="promoCode" />
              <div class="input-group-append d-flex align-items-center">
                <span (click)="applyPromo()">Apply</span>
              </div>
            </div>
          </form>
        </div>
        <div class="col-4"></div>
        <div class="col-8 d-flex align-items-start justify-content-center">
          <span class="body-1 mt-1" *ngIf="isPromoError"
            >Invalid promo code. Please try again</span
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between subtotal"
      *ngIf="subTotal > 0">
      <h4>Order Subtotal</h4>
      <h4>
        {{ subTotal | currency: "MYR ":"symbol":"1.0-2" }}
      </h4>
    </div>
    <div
      class="d-flex align-items-center justify-content-between subtotal"
      *ngIf="discount > 0">
      <h4>Order Discount Subtotal</h4>
      <h4>- {{ discount | currency: "MYR ":"symbol":"1.0-2" }}</h4>
    </div>
    <div class="total-container mb-3">
      <h2>Total</h2>
      <h2>{{ totalPayment | currency: "MYR ":"symbol":"1.0-2" }}</h2>
    </div>
    <div class="pay-button-container text-center pt-1">
      <button
        type="button"
        class="btn btn-primary"
        (click)="!isLoading && onPayButtonPressed()"
        [ngClass]="{ 'btn-load': isLoading }">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading"></span>
        {{ isLoading ? "Waiting" : "Pay" }}
      </button>
    </div>
  </div>
</div>
