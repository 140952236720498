import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { StatusUpdReq } from 'src/app/modules/shared/models/property.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'

@Component({
  selector: 'app-edit-status-modal',
  templateUrl: './edit-status-modal.component.html',
  styleUrls: ['./edit-status-modal.component.sass'],
})
export class EditStatusModalComponent implements OnInit {
  @Input() propertyId: string
  @Input() triggerDataReload = () => {}
  editStatusForm = new FormGroup({
    statusSelect: new FormControl(''),
    confirmation_text: new FormControl(''),
  })
  statusList: String[] = [
    'New Launch',
    'Under Construction',
    'Key Collection',
    'Defect Check',
    'Renovation',
    'Short Stay Setup',
    'Live',
    'Pending Approval',
    'Sold',
  ]

  constructor(
    private propertyService: PropertiesService,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {}

  onClickUpdateStatus = (): boolean => {
    let status = this.editStatusForm.get('statusSelect').value
    let req: StatusUpdReq = {
      status: status,
    }

    if (status != null && status != '') {
      if (this.editStatusForm.get('confirmation_text').value == 'CONFIRM') {
        this.propertyService
          .editStatus(this.propertyId, req)
          .then(res => {
            // console.log(res)
            this.toastService.show('Success', 'Status successfully updated')
            // window.location.reload()
            this.triggerDataReload()
          })
          .catch(err => {
            console.log(err)
            this.toastService.show('Error', 'Status update error')
          })
          .finally(() => {})
      } else {
        // alert('Please Type "CONFIRM"')
        this.toastService.show('Error', 'Please Type "CONFIRM"')
        return false
      }
    } else {
      // alert('Please Select the Status')
      this.toastService.show('Error', 'Please Select the Status')
      return false
    }

    return true
  }
}
