<div class="market-grade-card">
  <div class="title">Market Grade</div>
  <div class="content">
    <span class="content-label">Rental Demand</span>
    <div class="content-graph">
      <div
        class="actual-value"
        [ngStyle]="{ width: marketGrade?.rental_demand + '%' }"></div>
    </div>
    <div class="content-value">{{ marketGrade?.rental_demand }}</div>
  </div>
  <div class="content">
    <span class="content-label">Revenue Growth</span>
    <div class="content-graph">
      <div
        class="actual-value"
        [ngStyle]="{ width: marketGrade?.revenue_growth + '%' }"></div>
    </div>
    <div class="content-value">{{ marketGrade?.revenue_growth }}</div>
  </div>
  <div class="content">
    <span class="content-label">Seasonality</span>
    <div class="content-graph">
      <div
        class="actual-value"
        [ngStyle]="{ width: marketGrade?.seasonality + '%' }"></div>
    </div>
    <div class="content-value">{{ marketGrade?.seasonality }}</div>
  </div>
  <div class="content">
    <span class="content-label">Regulation</span>
    <div class="content-graph">
      <div
        class="actual-value"
        [ngStyle]="{ width: marketGrade?.regulation + '%' }"></div>
    </div>
    <div class="content-value">{{ marketGrade?.regulation }}</div>
  </div>
</div>
