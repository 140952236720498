import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-property-preview-banner',
  templateUrl: './property-preview-banner.component.html',
  styleUrls: ['./property-preview-banner.component.sass'],
})
export class PropertyPreviewBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
