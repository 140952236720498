import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { MediaPreviewComponent } from 'src/app/modules/shared/component/media-preview/media-preview.component'
import {
  DefectArea,
  Option,
} from 'src/app/modules/shared/factory/option.factory'
import { FileUploadResponse } from 'src/app/modules/shared/models/file-upload.model'
import {
  DefectAreaModel,
  DefectPatchReq,
  DefectReport,
  Image,
  LodgeDefectReq,
} from 'src/app/modules/shared/models/property.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { FilesUploadService } from 'src/app/modules/shared/service/files-upload.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import { DefectCheckModel } from './model/lodge-defect.model'

export interface MediaObj {
  safeUrl?: SafeUrl
  type?: string
  id?: string
}

@Component({
  selector: 'app-lodge-defect',
  templateUrl: './lodge-defect.component.html',
  styleUrls: ['./lodge-defect.component.sass'],
})
export class LodgeDefectComponent implements OnInit {
  @Input() defectCheckState: Observable<DefectCheckModel>
  @Input() reloadDataCallback = () => {}
  defectCheckData: DefectCheckModel
  submitted: boolean = false

  defectListShow: boolean = false
  formActive: boolean = false
  isEdit: boolean = true

  lodgeDefectForm = new FormGroup({
    areaLocation: new FormControl('', Validators.required),
    defectArea: new FormControl('', Validators.required),
    defectDesc: new FormControl('', Validators.required),
  })

  areaOpt: Option[] = [
    {
      id: 'Bedroom 1',
      label: 'Bedroom 1',
      value: 'Bedroom 1',
    },
    {
      id: 'Bedroom 2',
      label: 'Bedroom 2',
      value: 'Bedroom 2',
    },
  ]
  defectAreaOpt: Option[] = DefectArea
  mediaList: MediaObj[] = []
  imgUuids: string[] = []
  defectAreas: DefectAreaModel[] = []
  isLoading: boolean = false

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private uploadService: FilesUploadService,
    private propertyService: PropertiesService,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {
    this.defectCheckState.subscribe(data => {
      this.defectCheckData = data
      if (this.defectCheckData) {
        if (this.defectCheckData.defect_check_history?.length > 0) {
          this.defectListShow = true
        } else {
          this.defectListShow = false
        }
        this.defectAreas = this.defectCheckData.defect_areas
        if (this.defectAreas) {
          this.areaOpt = []
          for (let area of this.defectAreas) {
            this.areaOpt.push({
              id: area.area_location,
              label: area.area_location,
              value: area.area_location,
            })
          }
        }
      }
    })
  }

  handleAreaChange = (area: string) => {
    this.defectAreaOpt = []
    let defectLocation = this.defectAreas.find(val => {
      if (val.area_location == area) {
        return val
      }
    })

    this.defectAreaOpt = defectLocation?.defects_areas.map(val => ({
      id: val,
      label: val,
      value: val,
    }))
  }

  onAddDefect() {
    this.isEdit = true
    this.formActive = true
    this.defectListShow = false
  }

  onSubmitDefect() {
    this.submitted = true
    this.lodgeDefectForm.markAllAsTouched()
    if (this.lodgeDefectForm.valid) {
      let newDefects: DefectReport[] = []
      let defectReport: DefectReport = {
        area: this.lodgeDefectForm.get('areaLocation').value,
        defect_area: this.lodgeDefectForm.get('defectArea').value,
        description: this.lodgeDefectForm.get('defectDesc').value,
        images: this.imgUuids,
      }
      newDefects.push(defectReport)
      let req: LodgeDefectReq = {
        items: newDefects,
      }
      this.isLoading = true
      this.propertyService
        .LodgeDefect(this.defectCheckData.propertyId, req)
        .then(res => {
          this.toastService.show('Success', 'Defect successfully lodged')
          this.reloadDataCallback()
          this.formActive = false
        })
        .catch(err => {
          this.toastService.show('Error', 'Defect lodge error')
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  onPatchStatus(defectId, status: string) {
    let req: DefectPatchReq = {
      status: status,
    }
    this.propertyService
      .patchDefectStatus(this.defectCheckData.propertyId, defectId, req)
      .then(res => {
        // console.log(res)
        this.toastService.show('Success', 'Status updated')
        this.reloadDataCallback()
      })
      .catch(err => {
        this.toastService.show('Error', 'Status update error')
        console.log(err)
      })
      .finally(() => {})
  }

  onUpload(event) {
    let img: File = event.target.files[0]
    var imgUrl = URL.createObjectURL(img)
    var fileType = event.target.files[0].type.split('/')[0]
    var sanitizedImg = this.sanitizer.bypassSecurityTrustResourceUrl(imgUrl)

    this.uploadService
      .uploadFiles(img)
      .then((res: FileUploadResponse) => {
        this.imgUuids.push(res.file_ids[0])
        let media: MediaObj = {
          safeUrl: sanitizedImg,
          type: fileType,
          id: res.file_ids[0],
        }
        this.mediaList.push(media)
      })
      .catch(err => {
        console.log(err)
        this.toastService.show('Error', `Img upload failed`)
      })
      .finally(() => {})
    event.target.value = ''
  }

  onDeleteImg(idx: number) {
    this.mediaList.splice(idx, 1)
  }

  onMediaPreview(path, type) {
    const mediaModal = this.modalService.open(MediaPreviewComponent, {
      size: 'lg',
    })
    mediaModal.componentInstance.mediaType = type
    mediaModal.componentInstance.mediaPath = path
  }

  onPreviewDefect(
    area: string,
    defectArea: string,
    desc: string,
    imgList: Image[]
  ) {
    // console.log(imgList)
    this.isEdit = false
    this.formActive = true
    this.defectListShow = false
    this.lodgeDefectForm.get('areaLocation').setValue(area)
    this.lodgeDefectForm.get('areaLocation').disable()
    this.lodgeDefectForm.get('defectArea').setValue(defectArea)
    this.lodgeDefectForm.get('defectArea').disable()
    this.lodgeDefectForm.get('defectDesc').setValue(desc)
    this.lodgeDefectForm.get('defectDesc').disable()
    this.mediaList = []
    for (let img of imgList) {
      let media: MediaObj = {
        safeUrl: img.url,
        type: 'image',
      }
      this.mediaList.push(media)
    }
  }

  onReturn() {
    this.isEdit = true
    this.formActive = false
    this.defectListShow = true
    this.lodgeDefectForm.get('areaLocation').setValue('')
    this.lodgeDefectForm.get('areaLocation').enable()
    this.lodgeDefectForm.get('defectArea').setValue('')
    this.lodgeDefectForm.get('defectArea').enable()
    this.lodgeDefectForm.get('defectDesc').setValue('')
    this.lodgeDefectForm.get('defectDesc').enable()
    this.mediaList = []
  }
}
