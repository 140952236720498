import { Option } from '../factory/option.factory'

export class UserProfile {
  data: ProfileSummary

  constructor(obj: UserProfile) {
    this.data = obj.data
  }
}

export class ProfileSummary {
  id: string
  name: string
  email: string
  is_verified: boolean
  profile: ProfileData
  social_media: SocialMediaData

  constructor(obj: ProfileSummary) {
    this.id = obj.id
    this.name = obj.name
    this.email = obj.email
    this.is_verified = obj.is_verified
    this.profile = obj.profile
  }
}

export class ProfileData {
  avatar_url: string
  referral_code: string
  phone: string
  birth_date: string
  area_of_interests: string[]
  notification_updates: boolean
  notification_financial_report: boolean
  notification_marketing_newsletter: boolean
  notification_investment_opportunity: boolean
  aggresiveness: string
  goals_setting: string
  type_of_investment: string
  id_card_no: string
  bank_acc_name: string
  bank_name: string
  bank_acc_no: string

  constructor(obj: ProfileData) {
    this.avatar_url = obj.avatar_url
    this.referral_code = obj.referral_code
    this.phone = obj.phone
    this.birth_date = obj.birth_date
    this.area_of_interests = obj.area_of_interests
    this.notification_updates = obj.notification_updates
    this.notification_financial_report = obj.notification_financial_report
    this.notification_marketing_newsletter =
      obj.notification_marketing_newsletter
    this.notification_investment_opportunity =
      obj.notification_investment_opportunity
    this.aggresiveness = obj.aggresiveness
    this.goals_setting = obj.goals_setting
    this.type_of_investment = obj.type_of_investment
    this.id_card_no = obj.id_card_no
    this.bank_acc_name = obj.bank_acc_name
    this.bank_name = obj.bank_name
    this.bank_acc_no = obj.bank_acc_no
  }
}

export interface SocialMediaData {
  facebook: any
  google: any
  wechat: any
}

export class PatchUserData {
  email?: string
  name?: string
  avatar_id?: string
  password?: string
  password_confirmation?: string
  referral_code?: string
  phone?: string
  area_of_interests?: string[]
  birth_date?: string
  notification_updates?: boolean
  notification_financial_report?: boolean
  notification_investment_opportunity?: boolean
  notification_marketing_newsletter?: boolean
  aggresiveness?: string
  type_of_investment?: string
  goals_setting?: string
  bank_acc_name?: string
  bank_name?: string
  bank_acc_no?: string
  id_card_no?: string

  constructor(obj: PatchUserData) {
    this.email = obj.email
    this.name = obj.name
    this.avatar_id = obj.avatar_id
    this.password = obj.password
    this.password_confirmation = obj.password_confirmation
    this.referral_code = obj.referral_code
    this.phone = obj.phone
    this.area_of_interests = obj.area_of_interests
    this.birth_date = obj.birth_date
    this.notification_updates = obj.notification_updates
    this.notification_financial_report = obj.notification_financial_report
    this.notification_investment_opportunity =
      obj.notification_investment_opportunity
    this.notification_marketing_newsletter =
      obj.notification_marketing_newsletter
    this.aggresiveness = obj.aggresiveness
    this.type_of_investment = obj.type_of_investment
    this.goals_setting = obj.goals_setting
    this.bank_acc_name = obj.bank_acc_name
    this.bank_name = obj.bank_name
    this.bank_acc_no = obj.bank_acc_no
    this.id_card_no = obj.id_card_no
  }
}

export interface BankNamesResponse {
  bank_names?: string[]
}

export class BankNames {
  bank_names?: Option[]

  constructor(obj: BankNamesResponse) {
    this.bank_names = obj.bank_names.map((name: string) => {
      return {
        id: name,
        label: name,
        value: name,
      }
    })
  }
}
