import { Component, Input, OnInit, ViewChild } from '@angular/core'

import { ChartComponent } from 'ng-apexcharts'

import { Observable } from 'rxjs'
import { MarketTrend } from 'src/app/modules/shared/models/property.model'

@Component({
  selector: 'app-market-trend',
  templateUrl: './market-trend.component.html',
  styleUrls: ['./market-trend.component.sass'],
})
export class MarketTrendComponent implements OnInit {
  @Input() marketTrendState: Observable<MarketTrend[]>
  marketTrend: MarketTrend[]

  @ViewChild('occupancyTrend') chart

  selectedTrend: any = ''

  chartOptions: any = {
    series: [
      {
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: 'line',
    },
    xaxis: {
      categories: [],
    },
  }

  months: any = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  values = []
  defaultValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  constructor() {}

  ngOnInit(): void {
    this.marketTrendState.subscribe(data => {
      this.marketTrend = data
      if (this.marketTrend) {
        this.toggleTrend('occupancy')
      }
    })
  }

  toggleTrend = trend => {
    this.selectedTrend = trend
    let currentMonth = new Date().getMonth() + 1

    let name = trend
    this.values = this.defaultValues
    if (trend == 'occupancy') {
      for (let obj of this.marketTrend) {
        let date = new Date(0, obj.month, 0)
        this.values[date.getMonth()] = obj.occupancy_rate
      }
    } else {
      for (let obj of this.marketTrend) {
        let date = new Date(0, obj.month, 0)
        this.values[date.getMonth()] = obj.revenue
      }
    }

    this.chartOptions = {
      series: [
        {
          name: name,
          //? for only get data until current month
          data: this.values.slice(0, currentMonth),
        },
      ],
      chart: {
        height: 350,
        type: 'line',
      },
      xaxis: {
        categories: this.months,
      },
    }
  }
}
