import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Expenses } from 'src/app/modules/shared/models/property.model'
import { ExpenseModel } from './model/expense.model'

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.sass'],
})
export class ExpensesComponent implements OnInit {
  @Input() expensesState: Observable<ExpenseModel>
  @Input() isBppUser: boolean
  expenseData: Expenses
  isSold: boolean
  currency: string = 'MYR'

  constructor() {}

  ngOnInit(): void {
    this.expensesState.subscribe(data => {
      this.expenseData = data.expenseSummary
      this.isSold = data.isSold
    })
  }
}
