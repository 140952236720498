import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Services } from 'src/app/modules/shared/models/services.model'

@Component({
  selector: 'app-has-services-card',
  templateUrl: './has-services-card.component.html',
  styleUrls: ['./has-services-card.component.sass'],
})
export class HasServicesCardComponent implements OnInit {
  @Input() data: Services

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onClickNav() {
    this.router.navigate([
      this.data.page_link,
      this.data.id,
      this.data.identifier,
    ])
  }
}
