import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { Constants } from 'src/app/modules/shared/enums/constants.enum'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { ProjectOverviewModel } from './model/project-overview.model'

@Component({
  selector: 'app-project-overview-card',
  templateUrl: './project-overview-card.component.html',
  styleUrls: ['./project-overview-card.component.sass'],
})
export class ProjectOverviewCardComponent implements OnInit {
  @Input() projectOverviewState: Observable<ProjectOverviewModel>
  projectOverview: ProjectOverviewModel
  isFavorite: boolean = false
  statusDefectCheck = Constants.STATUS_DEFECT_CHECK
  statusKeyCollection = Constants.STATUS_KEY_COLLECTION
  statusLive = Constants.STATUS_LIVE
  statusNewLaunch = Constants.STATUS_NEW_LAUNCH
  statusPendingApproval = Constants.STATUS_PENDING_APPROVAL
  statusRenovation = Constants.STATUS_RENOVATION
  statusShortStaySetup = Constants.STATUS_SHORT_STAY_SETUP
  statusSold = Constants.STATUS_SOLD
  statusUnderConstruction = Constants.STATUS_UNDER_CONSTRUCTION

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  constructor(
    private router: Router,
    private projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.projectOverviewState.subscribe(val => {
      this.projectOverview = val
      if (this.projectOverview) {
        if (this.projectOverview.is_favorite) {
          this.isFavorite = true
        }
        if (this.projectOverview.images.length <= 0) {
          this.projectOverview.images.push({ url: 'app/assets/no-image.png' })
        }
      }
    })
  }

  slickInit(e) {
    // console.log('slick initialized')
  }

  breakpoint(e) {
    // console.log('breakpoint')
  }

  afterChange(e) {
    // console.log('afterChange')
  }

  beforeChange(e) {
    // console.log('beforeChange')
  }

  onClickFavorite() {
    this.isFavorite = !this.isFavorite
    // console.log('favorite clicked')
    this.projectService
      .addToFavorites(this.projectOverview.id)
      .then(res => {
        // console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }

  addToMyProperty() {
    this.router.navigate([
      '/my-properties/own-project-list/add',
      { isHasProject: true, projectId: this.projectOverview.id },
    ])
  }
}
