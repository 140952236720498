import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-ota-listings',
  templateUrl: './ota-listings.component.html',
  styleUrls: ['./ota-listings.component.sass'],
})
export class OtaListingsComponent implements OnInit {
  @Input() channelListingsState: Observable<any>
  unit: any
  channelArray = [
    ['agoda', false],
    ['airbnb', false],
    ['ctripcm', false],
    ['bookingcom', false],
    ['expedia', false],
    ['traveloka', false],
    ['tiketcom', false],
  ]

  constructor() {}

  ngOnInit(): void {
    this.channelListingsState.subscribe(data => {
      if (data) {
        this.channelArray = Object.entries(data)
      }
    })
  }
}
