import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable()
export class SecuredStore {
  initialState: SecuredStoreModel = {
    pageTitle: '',
  }

  private readonly _stateSource = new BehaviorSubject<SecuredStoreModel>(
    this.initialState
  )

  constructer() {}

  getState(): Observable<SecuredStoreModel> {
    return this._stateSource.asObservable()
  }

  getStateValue(): SecuredStoreModel {
    return this._stateSource.getValue()
  }

  setState = (data: SecuredStoreModel) => {
    this._stateSource.next(data)
  }

  resetState() {
    this._stateSource.next(this.initialState)
  }

  updatePageTitle(pageTitle: String) {
    this.setState({
      ...this.getStateValue(),
      pageTitle: pageTitle,
    })
  }
}

export interface SecuredStoreModel {
  pageTitle: String
}
