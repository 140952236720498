import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Services } from 'src/app/modules/shared/models/services.model'

@Component({
  selector: 'app-has-services-grid',
  templateUrl: './has-services-grid.component.html',
  styleUrls: ['./has-services-grid.component.sass'],
})
export class HasServicesGridComponent implements OnInit {
  @Input() dataListState: Observable<Services[]>
  dataList: Services[] = [
    {
      id: '',
      avatar_url: 'app/assets/key-collection-icon.svg',
      name: 'Key Collection On Behalf',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '',
      avatar_url: 'app/assets/insurance-service-icon.svg',
      name: 'Insurance Services',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '0becd8f5-8cfb-4795-a5a3-4b36e64ac3cc',
      avatar_url: 'app/assets/short-stay-essentials-icon.svg',
      name: 'Short Stay Essentials',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '64382456-25c7-4bd8-8e36-df1f4c6e65be',
      avatar_url: 'app/assets/reno-packages-icon.svg',
      name: 'Reno Packages',
      page_link: '/has-services/reno',
      is_enabled: true,
      identifier: '',
    },
    {
      id: '',
      avatar_url: 'app/assets/defect-check-icon.svg',
      name: 'Defect Check',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '10c4d5c0-e5c0-48a4-a39c-28c7b42e0664',
      avatar_url: 'app/assets/short-stay-manage-icon.svg',
      name: 'Short Stay Manage Services',
      page_link: '/has-services/shortstay-management',
      is_enabled: true,
      identifier: '',
    },
    {
      id: '',
      avatar_url: 'app/assets/fnb-icon.svg',
      name: 'F&B',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '',
      avatar_url: 'app/assets/cleaning-service-icon.svg',
      name: 'Cleaning',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '',
      avatar_url: 'app/assets/maintenance-services-icon.svg',
      name: 'Maintenance Services',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
    {
      id: '',
      avatar_url: 'app/assets/car-rental-icon.svg',
      name: 'Car Rental',
      page_link: '',
      is_enabled: false,
      identifier: '',
    },
  ]

  constructor() {}

  ngOnInit(): void {
    this.dataListState.subscribe(data => {
      this.dataList = data
    })
  }
}
