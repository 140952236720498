import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import {
  CartItem,
  ServiceItem,
  UnitItem,
  User,
  UserCart,
} from '../../../shared/state/user/user.model'

import { BehaviorSubject, Observable } from 'rxjs'

import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  //State
  private readonly _userSource = new BehaviorSubject<User>(null)
  readonly userState = this._userSource.asObservable()

  private readonly _userCartSource = new BehaviorSubject<UserCart[]>(null)
  readonly userCartState = this._userCartSource.asObservable()

  constructor(private router: Router) {}

  getUser(): User {
    if (this._userSource?.getValue() != null) {
      return this._userSource.getValue()
    } else {
      let storedUser =
        localStorage.getItem('userState') || sessionStorage.getItem('userState')
      if (storedUser != null) {
        this.setUser(JSON.parse(storedUser))
        return this._userSource.getValue()
      }
    }
  }
  setUser = (data: User) => {
    this._userSource.next(data)
  }
  rememberUser = (data: User) => {
    localStorage.setItem('userState', JSON.stringify(data))
  }
  sessionUser = (data: User) => {
    sessionStorage.setItem('userState', JSON.stringify(data))
  }
  removeUser = () => {
    localStorage.removeItem('userState')
    sessionStorage.removeItem('userState')
    this._userSource.next(null)
  }
  logoutUser = () => {
    this.removeUser()
    this.router.navigate([`/login`])
  }

  setCart = (userCarts: UserCart[]) => {
    localStorage.setItem('shoppingCarts', JSON.stringify(userCarts))
    this._userCartSource.next(userCarts)
  }

  setCartItemCount = (count: number) => {
    let user = this.getUser()
    let userCarts = this.getCart()
    let idx = userCarts.findIndex(cart => {
      if (cart.user.id == user.user.id) {
        return cart
      }
    })
    userCarts[idx].item_count = count
    this.setCart(userCarts)
  }

  subCart(): Observable<UserCart[]> {
    return this.userCartState
  }

  getCart = (): UserCart[] => {
    let cartsObj: UserCart[] = []
    cartsObj = this._userCartSource.getValue()
    if (cartsObj?.length <= 0 || cartsObj == null) {
      let carts = localStorage.getItem('shoppingCarts')
      cartsObj = JSON.parse(carts)
      if (cartsObj == null) {
        cartsObj = []
      }
    }
    return cartsObj
  }

  getCurrentUserCart = (): UserCart => {
    let allCart = this.getCart()
    let userData = this.getUser()
    let userCart = allCart?.find(val => {
      if (val.user.id == userData?.user.id) {
        return val
      }
    })
    return userCart
  }

  resetCart = () => {
    localStorage.removeItem('shoppingCarts')
    this._userCartSource.next([])
  }

  updateUnitItem(
    unit: UnitItem,
    servicesId: string,
    newService: ServiceItem
  ): UnitItem {
    let currServicePackageIdx = unit.service.findIndex(data => {
      if (data.service_id == servicesId) {
        return data
      }
    })

    if (currServicePackageIdx != -1) {
      unit.service[currServicePackageIdx].package = newService.package
    } else {
      unit.service.push(newService)
    }

    return unit
  }

  updateCartItem(
    cart: CartItem,
    newUnit: UnitItem,
    servicesId: string
  ): CartItem {
    let unitIdx = cart.unit.findIndex(val => {
      if (val.unit_type_id == newUnit.unit_type_id) {
        return val
      }
    })

    if (unitIdx != -1) {
      cart.unit[unitIdx] = this.updateUnitItem(
        cart.unit[unitIdx],
        servicesId,
        newUnit.service[0]
      )
    } else {
      cart.unit.push(newUnit)
    }

    return cart
  }

  setUsercart(newCartItem: CartItem, servicesId: string): UserCart {
    let currUserCart = this.getCurrentUserCart()
    let projIdx = currUserCart.cart.findIndex(data => {
      if (data.project_id == newCartItem.project_id) {
        return data
      }
    })

    if (projIdx != -1) {
      currUserCart.cart[projIdx] = this.updateCartItem(
        currUserCart.cart[projIdx],
        newCartItem.unit[0],
        servicesId
      )
    } else {
      currUserCart.cart.push(newCartItem)
    }

    return currUserCart
  }

  addToCart(newCart: UserCart, servicesId: string) {
    let shoppingCarts = this.getCart()
    let user = this.getUser()?.user
    let userCartIdx = shoppingCarts.findIndex(data => {
      if (data.user.id == user.id) {
        return data
      }
    })

    if (userCartIdx != -1) {
      shoppingCarts[userCartIdx] = this.setUsercart(newCart.cart[0], servicesId)
    } else {
      shoppingCarts?.push(newCart)
    }

    this.setCart(shoppingCarts)
  }
}
