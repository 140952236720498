<app-custom-modal [modalSubmit]="onClickUpdateStatus">
  <span modalTitle>Edit Status</span>
  <span modalBtn>OK</span>
  <form [formGroup]="editStatusForm" class="edit-status-form">
    <label for="status_radio">Edit Property Status</label>
    <div class="row" *ngFor="let status of statusList">
      <div class="col-12 v-row-center">
        <input
          type="radio"
          class="radio-btn"
          [value]="status"
          formControlName="statusSelect" /><span>{{ status }}</span>
      </div>
    </div>
    <div class="h-col-center mb-2">
      <custom-input
        [formGroup]="editStatusForm"
        id="confirmation_text"
        label="Type “CONFIRM” to change status"
        name="confirmation_text"
        type="text">
      </custom-input>
    </div>
  </form>
</app-custom-modal>
