import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { HostPartnerAgent } from 'src/app/modules/shared/models/project.model'

@Component({
  selector: 'app-reno-partner',
  templateUrl: './reno-partner.component.html',
  styleUrls: ['./reno-partner.component.sass'],
})
export class RenoPartnerComponent implements OnInit {
  @Input() renoPartnerDataState: Observable<HostPartnerAgent[]>
  renoPartnerData: HostPartnerAgent[]
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    infinite: false,
  }

  constructor() {}

  ngOnInit(): void {
    this.renoPartnerDataState.subscribe(data => {
      this.renoPartnerData = data
    })
  }

  slickInit(e) {
    // console.log('slick initialized')
  }

  breakpoint(e) {
    // console.log('breakpoint')
  }

  afterChange(e) {
    // console.log('afterChange')
  }

  beforeChange(e) {
    // console.log('beforeChange')
  }
}
