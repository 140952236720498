import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
  ListToSellReq,
  ListToSellRes,
} from 'src/app/modules/shared/models/property.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'

@Component({
  selector: 'app-list-to-sell-modal',
  templateUrl: './list-to-sell-modal.component.html',
  styleUrls: ['./list-to-sell-modal.component.sass'],
})
export class ListToSellModalComponent implements OnInit {
  @Input() propertyId: string
  @Input() indicativeMarketValue: number
  submitted: boolean = false

  listToSellForm = new FormGroup({
    sell_price: new FormControl('', Validators.required),
    get_help: new FormControl(''),
  })

  constructor(
    private propertyService: PropertiesService,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {}

  onSetIndicativeMarketValue() {
    this.listToSellForm.get('sell_price').setValue(this.indicativeMarketValue)
  }

  onClickSellReq = (): boolean => {
    this.submitted = true
    this.listToSellForm.markAllAsTouched()
    if (this.listToSellForm.valid) {
      let req: ListToSellReq = {
        selling_price: Number(this.listToSellForm.get('sell_price').value),
        get_help:
          this.listToSellForm.get('get_help').value == '' ? false : true,
      }
      this.propertyService
        .sellRequest(this.propertyId, req)
        .then((res: ListToSellRes) => {
          this.toastService.show('Success', 'Listing successful')
          // console.log(res)
        })
        .catch(error => {
          this.toastService.show('Error', 'Listing error')
          console.log(error)
        })
        .finally(() => {})

      return true
    }
  }
}
