export const environment = {
  production: true,
  baseUrl: 'https://admin.hostastay.com/api',
  signeasyBaseUrl: 'https://api.signeasy.com/v2.1',
  signeasyToken: 'Tqx13eZIpRdZW1ufCOR8jk6hIcUXiY',
  googleApiKey:
    '419909167865-qm1umud3lfik1rhos5d3g3kfuvp6m5pe.apps.googleusercontent.com',
  facebookApiKey: '3140526576275263',
  sentryDsn:
    'https://5d747606ecc34fd88a071cbec540ca31@o1220050.ingest.sentry.io/6362755',
}
