<div class="custom-card-container">
  <div class="content card-title">
    <ng-content select="[cardTitle]"></ng-content>
  </div>
  <div class="content">
    <button class="btn btn-primary" (click)="onClick()">
      <ng-content select="[cardBtn]"></ng-content>
    </button>
  </div>
</div>
