<div class="sale-price-card">
  <div class="card-title">Sale Price Range</div>
  <div class="pricing">{{ salesCardData?.price_range }}</div>
  <div class="separator"></div>
  <div class="project-detail-list">
    <div class="content-title">Project Details</div>
    <div class="row">
      <div class="project-detail col-6">
        <div class="label">Management Office (JMB)</div>
        <div class="value">{{ salesCardData?.management_office_location }}</div>
      </div>
      <div class="project-detail col-6">
        <div class="label">Project Type</div>
        <div class="value">{{ salesCardData?.type }}</div>
      </div>
      <div class="project-detail col-6">
        <div class="label">Built-up Size</div>
        <div class="value">{{ salesCardData?.built_up_size }}</div>
      </div>
      <div class="project-detail col-6">
        <div class="label">Built-up Price</div>
        <div class="value">{{ salesCardData?.built_up_price }}</div>
      </div>
      <div class="project-detail col-6">
        <div class="label">Listed On</div>
        <div class="value">{{ salesCardData?.listed_at }}</div>
      </div>
      <div class="project-detail col-6">
        <div class="label">Completion Year</div>
        <div class="value">{{ salesCardData?.completion_year }}</div>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="unit-types-container">
    <div class="content-title">Unit Types</div>
    <table class="table">
      <thead>
        <tr>
          <th class="type">Type</th>
          <th>Built (sq.ft)</th>
          <th colspan="2">Starting Price</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let unit of salesCardData?.unit_types">
          <td>
            Type {{ unit.type }} - {{ unit.bedroom }} &
            {{ unit.bathroom }}
          </td>
          <td>{{ unit.built_size }}</td>
          <td>{{ unit.starting_price }}</td>
          <td>
            <img
              src="app/assets/view_logo.png"
              class="view-icon"
              (click)="onMediaPreview(unit.file_url)"
              *ngIf="unit.file_url" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="unit-preview">
      <iframe *ngIf="matterportUrl" [src]="matterportUrl"></iframe>
    </div>
  </div>
  <div class="separator"></div>
  <div class="far-container">
    <div class="content-title">Facilities, Amenities & Regulatory</div>
    <div
      class="far-content"
      *ngFor="let info of salesCardData?.additional_informations">
      <div class="name">{{ info.title }}</div>
      <div class="row list">
        <div class="col-6" *ngFor="let item of info.items">
          <li>{{ item }}</li>
        </div>
      </div>
    </div>
  </div>
</div>
