<div class="card-container card h-100">
  <img [src]="imageurl" />
  <div
    class="content"
    (mouseenter)="mouseEnter('div a')"
    (mouseleave)="mouseLeave('div A')">
    <div class="title">
      <h4>{{ title }}</h4>
      <!-- <div class="info-image">
        <img src="app/assets/info-icon-blue.svg" />
      </div> -->
    </div>

    <!-- main value, either nominal or percentage -->
    <div
      class="large-font value"
      *ngIf="type == 'nominal'; else percentageMain">
      MYR -
    </div>
    <ng-template #percentageMain>
      <div class="large-font value">0 %</div>
    </ng-template>

    <div class="dialog" *ngIf="showInfo">
      <h2>{{ title }}</h2>
      <span class="display-3">Only for Short Stay Tenancy</span>
    </div>
  </div>
</div>
