<div class="reno-packages-grid">
  <h2 class="title">Reno Partner Listings</h2>
  <div class="row reno-packages-grid-container">
    <div
      class="col-6 reno-partners-card-container"
      *ngFor="let renoPartner of dataList">
      <app-reno-partners-card
        [data]="renoPartner"
        [getPackages]="getPackages"></app-reno-partners-card>
    </div>
  </div>
</div>
