<div class="property-detail-container">
  <div class="project-overview">
    <h2>Project Overview</h2>
    <div class="filter-date-container">
      <div class="button-bg bg-white" *ngIf="!isBppUser">
        <button
          type="button"
          class="btn btn-primary"
          (click)="toggleMenu('reports')">
          <img class="download-icon" src="app/assets/download-icon.svg" />
          Download Report
        </button>
        <ul
          class="dropdown-menu"
          [ngClass]="{ show: dropdownObj.reports.show }">
          <li *ngFor="let report of reportList">
            <div
              class="dropdown-item-container"
              (click)="report.url && openReport(report.url)"
              [ngClass]="{ 'download-disabled': !report.url }">
              <a class="dropdown-item">{{ report.name }}</a>
              <svg-icon
                class="download-icon"
                src="app/assets/download-icon.svg"></svg-icon>
            </div>
          </li>
        </ul>
      </div>

      <!-- button for BPP user -->
      <div class="button-bg bg-white" *ngIf="isBppUser">
        <button
          type="button"
          class="btn btn-primary"
          (click)="revenueReportUrl && openRevenue(revenueReportUrl)">
          <img class="download-icon" src="app/assets/download-icon.svg" />
          Download Report
        </button>
      </div>

      <div class="date-filter">
        <div
          class="filter-date bg-white ms-3"
          (click)="toggleMenu('monthYear')">
          <img src="app/assets/calendar.svg" />
          <span>{{ selectedMonthYearString }}</span>
          <img src="app/assets/chevron-down.svg" />
        </div>
        <ul
          class="dropdown-menu"
          [ngClass]="{ show: dropdownObj.monthYear.show }">
          <li
            *ngFor="let monthYear of monthYearList"
            (click)="updateMonthYear(monthYear)">
            <a class="dropdown-item"
              >{{ monthYear | date: "MMM" }} {{ monthYear | date: "YYYY" }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="project-detail-content">
    <div class="row mt-0 g-3 g-lg-4">
      <!-- main content -->
      <div class="col-12 col-lg-9">
        <!-- demo data notification -->
        <div class="mb-3 mb-lg-4" *ngIf="is_demo && !isDemoNotifClicked">
          <app-demo-data-notification
            [onClickNoUnitNotif]="
              onClickNoUnitNotif
            "></app-demo-data-notification>
        </div>

        <div class="mb-3 mb-lg-4">
          <app-property-overview
            [propertyOverviewState]="propertyOverviewState"
            [tenancyTypeListState]="tenancyTypeListState"
            [hostListState]="hostListState"
            [defectCheckCallback]="showDefectcheck"
            [triggerDataReload]="populateData"
            [isBppUser]="isBppUser"></app-property-overview>
        </div>

        <!-- not subscribed -->
        <div
          *ngIf="
            property?.status == statusSubExpired || isSubscribed == false;
            else subscribedTemplate
          ">
          <app-subscribe
            [projectId]="property?.project_id"
            [projectname]="property?.project_name"
            [unitLabel]="property?.unit_number"
            [unitId]="property?.id"></app-subscribe>
        </div>

        <!-- subscribed -->
        <ng-template #subscribedTemplate>
          <div
            *ngIf="
              (property?.status == statusLive ||
                property?.status == statusSold) &&
              !isShowDefectcheck
            ">
            <div class="row g-3 g-lg-4">
              <div class="col-12 col-lg-4">
                <app-summary-card
                  [summaryState]="totalEarningsState"></app-summary-card>
              </div>
              <div class="col-12 col-lg-4">
                <app-summary-card
                  [summaryState]="occupancyRateState"
                  *ngIf="!isBppUser; else greyOccupancy"></app-summary-card>
                <ng-template #greyOccupancy>
                  <app-grey-summary-card
                    imageurl="app/assets/greyed-occupancy-rate.svg"
                    title="Occupancy Rate"
                    type="percentage"></app-grey-summary-card>
                </ng-template>
              </div>
              <div class="col-12 col-lg-4">
                <app-summary-card
                  [summaryState]="avgDailyEarningsState"
                  *ngIf="!isBppUser; else greyDailyEarning"></app-summary-card>
                <ng-template #greyDailyEarning>
                  <app-grey-summary-card
                    imageurl="app/assets/greyed-daily-earning.svg"
                    title="Average Daily Earning"
                    type="nominal"></app-grey-summary-card>
                </ng-template>
              </div>
              <div class="col-12" *ngIf="!isBppUser">
                <app-market-trend
                  [marketTrendState]="marketTrendState"></app-market-trend>
              </div>
              <div class="col-12 col-lg-6" *ngIf="!isBppUser">
                <app-booking-summary
                  [bookingSummaryState]="
                    bookingSummaryState
                  "></app-booking-summary>
              </div>
              <div class="col-12" [ngClass]="{ 'col-lg-6': !isBppUser }">
                <app-expenses
                  [expensesState]="expensesState"
                  [isBppUser]="isBppUser"></app-expenses>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              property?.status == statusUnderConstruction && !isShowDefectcheck
            ">
            <div class="row">
              <div class="col-12">
                <app-timeline-card
                  timelineTitle="Construction Status"
                  [timelineState]="histConstState"></app-timeline-card>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              property?.status == statusKeyCollection && !isShowDefectcheck
            ">
            <div class="row g-3 g-lg-4">
              <div class="col-12">
                <app-key-collect
                  [keyCollectionState]="keyCollectionState"
                  [populateCallback]="populateData"></app-key-collect>
              </div>
              <div class="col-12">
                <app-key-collection-basic-card
                  [defectCheckCallback]="
                    showDefectcheck
                  "></app-key-collection-basic-card>
              </div>
            </div>
          </div>
          <div
            *ngIf="property?.status == statusDefectCheck || isShowDefectcheck">
            <div class="row g-3 g-lg-4">
              <div class="col-12">
                <app-lodge-defect
                  [defectCheckState]="defectCheckState"
                  [reloadDataCallback]="populateData"></app-lodge-defect>
              </div>
              <div class="col-12">
                <app-unit-rental-basic-card></app-unit-rental-basic-card>
              </div>
            </div>
          </div>
          <div
            *ngIf="property?.status == statusRenovation && !isShowDefectcheck">
            <div class="row">
              <div class="col-12">
                <app-timeline-card
                  timelineTitle="Renovation Status"
                  [timelineState]="renoHisState"></app-timeline-card>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              property?.status == statusShortStaySetup && !isShowDefectcheck
            ">
            <div class="row">
              <div class="col-12">
                <app-shortstay-card
                  [shortStayState]="shortStayState"></app-shortstay-card>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- sidebar - (right side) -->
      <div class="col-12 col-lg-3">
        <div class="row mt-0">
          <div class="col-12 mb-3 mb-lg-4">
            <app-doc-list-card
              docListTitle="Document"
              [documentListState]="documentListState"
              [triggerDataReload]="populateData"></app-doc-list-card>
          </div>

          <!-- only show if subscribed and not expired -->
          <div
            *ngIf="
              property?.status == !statusSubExpired || isSubscribed == true
            ">
            <div class="col-12 mb-3 mb-lg-4" *ngIf="!isBppUser">
              <app-ota-listings
                [channelListingsState]="
                  channelListingsState
                "></app-ota-listings>
            </div>
            <div
              class="col-12 mb-3 mb-lg-4"
              *ngIf="property?.status == statusLive && !isBppUser">
              <app-source-of-booking
                [sourceOfBookingState]="
                  sourceOfBookingState
                "></app-source-of-booking>
            </div>
            <div class="col-12 mb-3 mb-lg-4" *ngIf="!isBppUser">
              <app-insurance-protection
                [insuranceProtectionState]="
                  insuranceProtectionState
                "></app-insurance-protection>
            </div>
            <div class="col-12 mb-3 mb-lg-4">
              <app-host-card [hostState]="hostState"></app-host-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
