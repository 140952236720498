import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { UserStore } from '../../core/auth/store/user.store'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import {
  BankNamesResponse,
  PatchUserData,
  UserProfile,
} from '../models/profile.model'

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient, private userStore: UserStore) {}

  async getProfile() {
    return new Promise((resolve, reject) => {
      let user = this.userStore.getUser()
      this.http
        .get<UserProfile>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.AUTH_ME)
            .including()
            .withProfile()
            .withSocialMedia()
            .withAggresiveness()
            .withTypeOfInvestment()
            .withGoalsSetting()
            .toString()}`,
          {
            headers: { Authorization: user.access_token },
          }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async updateProfile(newData: PatchUserData) {
    return new Promise((resolve, reject) => {
      let user = this.userStore.getUser()
      this.http
        .patch<UserProfile>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.AUTH_ME)}`,
          newData,
          {
            headers: { Authorization: user.access_token },
          }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getBankNames() {
    return new Promise((resolve, reject) => {
      let user = this.userStore.getUser()
      this.http
        .get<BankNamesResponse>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.BANK_NAMES)}`,
          {
            headers: { Authorization: user.access_token },
          }
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
