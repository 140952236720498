import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-dashboard-no-active-unit-modal',
  templateUrl: './dashboard-no-active-unit-modal.component.html',
  styleUrls: ['./dashboard-no-active-unit-modal.component.sass'],
})
export class DashboardNoActiveUnitModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
