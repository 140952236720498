import { Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
// import { SidebarStore } from 'src/app/modules/shared/component/sidebar/store/sidebar.store'
import { Property } from 'src/app/modules/shared/models/property.model'

import { PropertiesService } from 'src/app/modules/shared/service/properties.service'
import { SecuredStore } from '../../../store/secured.store'

@Component({
  selector: 'app-property-listing',
  templateUrl: './property-listing.component.html',
  styleUrls: ['./property-listing.component.sass'],
})
export class PropertyListingComponent implements OnInit {
  private readonly _propertyListSource = new BehaviorSubject<Property[]>([])
  readonly propertyListState = this._propertyListSource.asObservable()

  constructor(
    private propertiesService: PropertiesService,
    private securedStore: SecuredStore
  ) {
    this.securedStore.updatePageTitle('My Properties')
  }

  ngOnInit(): void {
    this.propertiesService.getProperties().then((resp: Property[]) => {
      this._propertyListSource.next(resp)
    })
  }
}
