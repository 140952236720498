import { Component, OnInit } from '@angular/core'

declare const Chatra: any // Import the Chatra object

@Component({
  selector: 'app-bare-chatra',
  templateUrl: './bare-chatra.component.html',
  styleUrls: ['./bare-chatra.component.sass'],
})
export class BareChatraComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // Open Chatra
    Chatra('openChat', true)
  }
}
