import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { FileUploadResponse } from '../models/file-upload.model'

@Injectable({
  providedIn: 'root',
})
export class FilesUploadService {
  constructor(private http: HttpClient) {}

  async uploadFiles(file: File) {
    // console.log('file to upload : ')
    // console.log(file)
    // console.log(JSON.stringify(fileJsonList))

    return new Promise((resolve, reject) => {
      let data: FormData = new FormData()
      data.append('files[]', file)

      this.http
        .post<FileUploadResponse>(
          `${environment.baseUrl}${Endpoints.FILE_UPLOAD}`,
          data
        )
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
