import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Host } from 'src/app/modules/shared/models/property.model'

@Component({
  selector: 'app-host-card',
  templateUrl: './host-card.component.html',
  styleUrls: ['./host-card.component.sass'],
})
export class HostCardComponent implements OnInit {
  @Input() hostState: Observable<Host>
  hostData: Host
  constructor() {}

  ngOnInit(): void {
    this.hostState.subscribe(data => {
      this.hostData = data
    })
  }
}
