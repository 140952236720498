import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'

import { User } from 'src/app/modules/shared/state/user/user.model'
import { Auth } from 'src/app/modules/pages/auth/models/auth.model'
import { AuthConstants } from '../enums/auth.enum'
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private userStore: UserStore) {}

  async login(email: string, password: string, rememberLogin: boolean) {
    return new Promise((resolve, reject) => {
      let loginReq: Auth = {
        email: email,
        password: password,
        remember_me: rememberLogin,
        type: AuthConstants.default,
      }
      this.http
        .post<User>(`${environment.baseUrl}${Endpoints.LOGIN}`, loginReq)
        .subscribe({
          next: data => {
            this.userStore.removeUser()
            this.userStore.setUser(data)
            if (rememberLogin) {
              this.userStore.rememberUser(data)
            } else {
              this.userStore.sessionUser(data)
            }
            resolve(null)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async register(
    email: string,
    password: string,
    password_confirmation: string,
    referral_code: string
  ) {
    return new Promise((resolve, reject) => {
      let registerReq: Auth = {
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        referral_code: referral_code ? referral_code : undefined,
      }
      this.http
        .post<User>(`${environment.baseUrl}${Endpoints.REGISTER}`, registerReq)
        .subscribe({
          next: data => {
            this.userStore.removeUser()
            this.userStore.setUser(data)
            this.userStore.rememberUser(data)
            resolve(null)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async postChangePassword(
    password: string,
    password_confirmation: string,
    token: string,
    email: string
  ) {
    return new Promise((resolve, reject) => {
      let changePassReq: Auth = {
        password: password,
        password_confirmation: password_confirmation,
        token: token,
        email: email,
      }
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.CHANGE_PASSWORD)}`,
          changePassReq
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async postForgotPassword(email: string) {
    return new Promise((resolve, reject) => {
      let forgotPasswordReq: Auth = {
        email: email,
      }
      this.http
        .post<any>(
          `${environment.baseUrl}${new ApiFactory(Endpoints.FORGOT_PASSWORD)}`,
          forgotPasswordReq
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async socialLogin(type: string, token: string) {
    return new Promise((resolve, reject) => {
      let loginReq: Auth = {
        type: type,
        token: token,
      }
      this.http
        .post<User>(`${environment.baseUrl}${Endpoints.LOGIN}`, loginReq)
        .subscribe({
          next: data => {
            this.userStore.removeUser()
            this.userStore.setUser(data)
            this.userStore.rememberUser(data)
            resolve(null)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async socialConnect(type: string, token: string) {
    return new Promise((resolve, reject) => {
      let loginReq: Auth = {
        type: type,
        token: token,
      }
      this.http
        .post<User>(
          `${environment.baseUrl}${Endpoints.SOCIAL_CONNECT}`,
          loginReq
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async socialDisconnect(type: string) {
    return new Promise((resolve, reject) => {
      let loginReq: Auth = {
        type: type,
      }
      this.http
        .post<User>(
          `${environment.baseUrl}${Endpoints.SOCIAL_DISCONNECT}`,
          loginReq
        )
        .subscribe({
          next: data => {
            resolve(data)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
