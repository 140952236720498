import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/modules/shared/service/auth.service'

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.sass'],
  providers: [AuthService],
})
export class LoginFormComponent implements OnInit {
  submitted: boolean = false
  showPass: boolean = false
  isLoading: boolean = false
  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    keepMeLoggedIn: new FormControl(''),
  })
  errObject = {
    message:
      'Wrong login. Please try again or click Forgot Password to reset it.',
    hasError: false,
  }
  generateErrMessage: any = () => {
    let message = ''
    // if(this.loginForm.controls.email.errors?.required && this.loginForm.controls.password.errors?.required) {
    //   message = `Incorrect email address and password. Try again.`
    // }
    // else if(this.loginForm.controls.password.errors?.required) {
    //   message = `Incorrect password. Try again or click Forgot Password to reset it.`
    // }
    // else if(this.loginForm.controls.email.errors?.required){
    //   message = `Incorrect email address. Try again`
    // }

    return message
  }

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  showHidePassword() {
    this.showPass = !this.showPass
  }

  onSubmit = e => {
    e.preventDefault()
    this.submitted = true
    if (this.loginForm.valid) {
      this.isLoading = true
      this.authService
        .login(
          this.loginForm.value.email,
          this.loginForm.value.password,
          this.loginForm.value.keepMeLoggedIn || false
        )
        .then(() => {
          this.errObject.hasError = false
          this.router.navigate([`home`])
        })
        .catch(err => {
          console.log(err)
          this.errObject.message = err.error.message
          this.errObject.hasError = true
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
