import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[appModalContent]',
})
export class ModalContentDirective {
  @Input() title: string
  @Input() desc: string

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.innerHTML = `
    <div>
      <h1>${this.title}</h1>
      <p>${this.desc}</p>
    </div>`
  }
}
