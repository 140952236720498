<div class="toast-container">
  <div class="toast-content" *ngFor="let toast of toastService.toasts">
    <ngb-toast
      [header]="toast.header"
      [autohide]="true"
      [delay]="5000"
      (hidden)="onHide(toast)"
      [ngClass]="{ 'toast-err': toast.header == 'Error' }">
      {{ toast.content }}
    </ngb-toast>
  </div>
</div>
