<div class="has-welcome-popup d-flex flex-column align-items-center">
  <h1 class="align-self-start">Welcome to HostAStay</h1>
  <h2>
    You do not have an active unit subscription with us,
    you will be shown a sample dashboard report.
  </h2>
  <button class="btn btn-primary" (click)="activeModal.close()">
    <h3>ok</h3>
  </button>
</div>
