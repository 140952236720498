<form
  [formGroup]="forgotPassForm"
  class="forgot-pass-form"
  (ngSubmit)="onSubmit($event)"
  [ngClass]="{
    'has-errors': (forgotPassForm.invalid || errObject.hasError) && submitted
  }">
  <div class="row">
    <div class="col-12">
      <div class="custom-input-group">
        <span class="input-icon" id="email"
          ><img src="app/assets/mail.svg"
        /></span>
        <input
          id="email"
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Email Address"
          required />
      </div>
    </div>
    <div class="col-12" *ngIf="errObject.hasError">
      <div class="alert form-alert mt-3">
        <div class="d-flex align-items-center">
          <img class="pe-2 icon-warning" src="app/assets/warning.svg" />
          <span class="body-1">
            {{ errObject.message }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="successMsg != ''">
      <div class="alert mt-3">
        <div class="d-flex align-items-center text-success">
          <img class="pe-2 icon-success" src="app/assets/checkmark.svg" />
          <span class="body-1">
            {{ successMsg }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2 col-12 mt-8">
      <button
        type="{{ isLoading ? 'button' : 'submit' }}"
        class="btn btn-primary btn-continue"
        [ngClass]="{ 'btn-load': isLoading }">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading"></span>
        {{ isLoading ? "Waiting" : "Continue" }}
      </button>
    </div>
    <div class="col-12 mt-4">
      <div class="bottom-links">
        <small class="text-secondary"
          >Back to Sign In?
          <a [routerLink]="['/']" class="link-primary">Click Here</a></small
        >
      </div>
    </div>
  </div>
</form>
