import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { UpdateCategoryReq } from 'src/app/modules/shared/models/property.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { PropertiesService } from 'src/app/modules/shared/service/properties.service'

@Component({
  selector: 'app-edit-tenancy-modal',
  templateUrl: './edit-tenancy-modal.component.html',
  styleUrls: ['./edit-tenancy-modal.component.sass'],
})
export class EditTenancyModalComponent {
  @Input() propertyId: string
  @Input() tenancyTypeListState: Observable<Option[]>
  @Input() hostListState: Observable<Option[]>
  @Input() triggerDataReload = () => {}
  tenancyStatusList: Option[] = []
  managedByList: Option[] = []
  submitted: boolean = false

  editTenancyForm = new FormGroup({
    tenancy_status: new FormControl('', Validators.required),
    managed_by: new FormControl('', Validators.required),
  })

  constructor(
    private propertyService: PropertiesService,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {
    this.tenancyTypeListState.subscribe(data => {
      this.tenancyStatusList = data
    })

    this.hostListState.subscribe(data => {
      this.managedByList = data
    })
  }

  onClickEditCategory = (): boolean => {
    this.submitted = true
    this.editTenancyForm.markAllAsTouched()
    if (this.editTenancyForm.valid) {
      let req: UpdateCategoryReq = {
        category: this.editTenancyForm.get('tenancy_status').value,
        host_id: this.editTenancyForm.get('managed_by').value,
      }
      this.propertyService
        .updateCategory(this.propertyId, req)
        .then(res => {
          // console.log(res)
          this.toastService.show('Success', 'Category successfully updated')
          // window.location.reload()
          this.triggerDataReload()
        })
        .catch(err => {
          this.toastService.show('Error', 'Category update error')
          console.log(err)
        })
        .finally(() => {})

      return true
    }
  }
}
