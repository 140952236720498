<div class="documents-container card">
  <div class="title">{{ docListTitle }}</div>
  <div class="doc-list" *ngIf="docList?.length > 0; else noDataContainer">
    <div
      class="document"
      *ngFor="let doc of docList"
      (click)="
        onClickDoc(doc.status == 'signed' ? doc.file_url : doc.online_sign_url)
      ">
      <img class="doc-icon" src="app/assets/document-icon.svg" />
      <div class="doc-summary">
        <div class="doc-summary-name">{{ doc.name }}</div>
        <div class="doc-summary-status">{{ doc.status }}</div>
      </div>
      <img
        class="view-icon"
        [ngClass]="{ upsized: doc.status != 'signed' }"
        [src]="
          doc.status == 'signed'
            ? 'app/assets/view_logo.png'
            : 'app/assets/pencil.svg'
        " />
      <!--(click)="onMediaPreview(doc.file_url)"-->
    </div>
  </div>
  <ng-template #noDataContainer>
    <div class="empty-container">
      <span> No Document to Show </span>
    </div>
  </ng-template>
</div>
