<div class="content-container">
  <div class="dashboard-overview">
    <h2>Dashboard Overview</h2>
    <div class="filter-date-container">
      <div class="button-bg bg-white" *ngIf="!isBppUser">
        <button
          type="button"
          class="btn btn-primary"
          (click)="toggleMenu('reports')">
          <img class="download-icon" src="app/assets/download-icon.svg" />
          Download Report
        </button>
        <ul
          class="dropdown-menu"
          [ngClass]="{ show: dropdownObj.reports.show }">
          <li *ngFor="let report of reportList">
            <div
              class="download-item-container"
              (click)="report.url && openReport(report.url)"
              [ngClass]="{ 'download-disabled': !report.url }">
              <a class="dropdown-item">{{ report.name }}</a>
              <svg-icon
                class="download-icon"
                src="app/assets/download-icon.svg"></svg-icon>
            </div>
          </li>
        </ul>
      </div>

      <!-- button for BPP user -->
      <div class="button-bg bg-white" *ngIf="isBppUser">
        <button
          type="button"
          class="btn btn-primary"
          (click)="revenueReportUrl && openRevenue(revenueReportUrl)">
          <img class="download-icon" src="app/assets/download-icon.svg" />
          Download Report
        </button>
      </div>

      <div class="date-filter">
        <div
          class="filter-date bg-white ms-3"
          (click)="toggleMenu('monthYear')">
          <img src="app/assets/calendar.svg" />
          <span>{{ selectedMonthYearString }} </span>
          <img src="app/assets/chevron-down.svg" />
        </div>
        <ul
          class="dropdown-menu"
          [ngClass]="{ show: dropdownObj.monthYear.show }">
          <li
            *ngFor="let monthYear of monthYearList"
            (click)="updateMonthYear(monthYear)">
            <a class="dropdown-item"
              >{{ monthYear | date: "MMM" }} {{ monthYear | date: "YYYY" }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="dashboard-content">
    <div class="row mt-0 g-3 g-lg-4">
      <div class="col-12 col-lg-9">
        <div
          class="row g-3 g-lg-4 mb-4"
          *ngIf="noActiveUnits && isShowNoUnitNotif && !isUnitNotifClicked">
          <div class="col-12">
            <app-demo-data-notification
              [onClickNoUnitNotif]="
                onClickNoUnitNotif
              "></app-demo-data-notification>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-lg-3 g-3 g-lg-4">
          <div class="col" *ngIf="!isBppUser">
            <app-summary-card
              [summaryState]="totalEarningsState"></app-summary-card>
          </div>
          <div class="col" *ngIf="!isBppUser">
            <app-summary-card [summaryState]="occRateState"></app-summary-card>
          </div>
          <div class="col" *ngIf="!isBppUser">
            <app-summary-card
              [summaryState]="avgDailyEarningState"></app-summary-card>
          </div>
        </div>
        <div class="row mt-0 g-3 g-lg-4" *ngIf="!isBppUser">
          <div class="col-12">
            <app-property-revenue-overview
              [revenueOverviewState]="earningsOverviewState"
              [graphYear]="responseDataYear"></app-property-revenue-overview>
          </div>
        </div>
        <div class="row mt-0 g-3 g-lg-4">
          <div class="col-12 col-lg-8">
            <app-properties-chart
              [propertySumState]="propertySumState"
              [isBppUser]="isBppUser"></app-properties-chart>
          </div>
          <div class="col-12 col-lg-4">
            <div class="row g-3 g-lg-4 rent-sale">
              <div class="col-12" *ngIf="isBppUser">
                <app-monthly-earnings
                  [summaryState]="totalEarningsState"></app-monthly-earnings>
              </div>
              <div class="col-12">
                <app-properties-for-rent
                  [propertySumState]="
                    propertySumState
                  "></app-properties-for-rent>
              </div>
              <div class="col-12">
                <app-properties-for-sale
                  [propertySumState]="propertySumState"
                  z></app-properties-for-sale>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-0 g-3 g-lg-4">
          <div class="col-12">
            <app-property-preview-banner></app-property-preview-banner>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="row g-3 g-lg-4">
          <div class="col-12" *ngIf="setupUnfinished && !isBppUser">
            <app-has-account-setup
              [accSetupState]="accSetupState"></app-has-account-setup>
          </div>
          <div class="col-12">
            <app-doc-list-card
              docListTitle="Pending Document"
              [documentListState]="pendingDocState"
              [triggerDataReload]="populateData"></app-doc-list-card>
          </div>
          <div class="col-12">
            <app-recent-activity
              [recentActState]="recentActState"></app-recent-activity>
          </div>
          <div class="col-12">
            <app-my-protection
              [protectionState]="protectionState"></app-my-protection>
          </div>
          <div class="col-12" *ngIf="!isBppUser">
            <app-source-of-booking
              [sourceOfBookingState]="
                sourceOfBookingState
              "></app-source-of-booking>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
