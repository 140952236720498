import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { UserStore } from '../../core/auth/store/user.store'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass'],
})
export class AuthComponent implements OnInit {
  introObj: {} = {
    '/': {
      h2: 'USER LOGIN',
    },
    '/login': {
      h2: 'USER LOGIN',
    },
    '/sign-up': {
      h2: 'USER SIGN UP',
    },
    '/verify': {
      h2: 'USER LOGIN',
    },
    '/forgot-password': {
      h2: 'Forgot Password',
    },
    '/new-password': {
      h2: 'New Password',
    },
  }
  currentRoute: string

  constructor(private userStore: UserStore, private router: Router) {}

  ngOnInit(): void {
    const user = this.userStore.getUser()
    if (user) {
      this.router.navigate([`/home`])
    }
    this.currentRoute = this.router.url.split('?')[0]
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.currentRoute = val.url.split('?')[0]
      }
    })
  }
}
