<div class="timeline-container">
  <div class="header">
    <div class="title">{{ timelineTitle }}</div>
    <div class="developer-data">
      <div class="developer-details">
        <span>Developer : {{ timelineData?.developer_name }}</span>
        <span>Targeted VP Date: {{ timelineData?.vp_date }}</span>
      </div>
      <div>
        <img [src]="timelineData?.developer_avatar" alt="" />
      </div>
    </div>
  </div>
  <div class="content">
    <div
      class="timeline-data"
      *ngIf="timelineData?.history_data.length > 0; else noDataContainer">
      <div *ngFor="let his of timelineData?.history_data; let index = index">
        <div
          class="status-container"
          [ngClass]="{ hidden: isHideList && index > 2 }">
          <div class="time">
            <div>Day</div>
            <div>{{ his.date }}</div>
          </div>
          <div class="checkpoint">
            <div
              class="checkpoint-dot"
              [ngClass]="{ 'checkpoint-dot-active': index == 0 }"></div>
            <div class="checkpoint-separator"></div>
          </div>
          <div class="detail">
            <div class="detail-text">
              {{ his.description }}
            </div>
            <div class="detail-image">
              <div class="single-img" *ngFor="let img of his.files">
                <img [src]="img.url" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="show-more"
        (click)="showMore()"
        *ngIf="isHideList && timelineData?.history_data.length > 3">
        <span>Show More</span>
        <img src="app/assets/chevron-down-blue.svg" />
      </div>
    </div>
    <ng-template #noDataContainer>
      <div class="empty-container">
        <span> No Data to Show </span>
      </div>
    </ng-template>
  </div>
</div>
