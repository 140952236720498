<div class="project-overview">
  <div class="image-slider">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let slide of projectOverview?.images">
        <img src="{{ slide.url }}" alt="" class="slider-card" />
      </div>
    </ngx-slick-carousel>
    <div
      class="dir-btn left"
      (click)="slickModal.slickPrev()"
      [ngClass]="{ 'd-none': projectOverview?.images.length <= 1 }">
      <img src="app/assets/arrow-left.svg" alt="" />
    </div>
    <div
      class="dir-btn right"
      (click)="slickModal.slickNext()"
      [ngClass]="{ 'd-none': projectOverview?.images.length <= 1 }">
      <img src="app/assets/arrow-right.svg" alt="" />
    </div>
  </div>
  <div class="project-overview-content">
    <div class="overview-information-container">
      <div class="overview-information">
        <div class="overview-information-header">
          <div class="project-name">{{ projectOverview?.name }}</div>
          <div class="btn-container">
            <button
              type="button"
              class="btn btn-outline-primary btn-favorite"
              [ngClass]="{ fav: isFavorite }"
              (click)="onClickFavorite()">
              <svg-icon src="app/assets/is_favorite_icon.svg"></svg-icon>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-add"
              (click)="addToMyProperty()">
              + Add to My Property
            </button>
          </div>
        </div>

        <div class="project-location">
          {{ projectOverview?.location }}
        </div>
        <div class="project-description">
          {{ projectOverview?.description }}
        </div>
      </div>
    </div>
    <div class="project-tags mt-2 flex-wrap">
      <div
        class="tag"
        [ngClass]="{
          statusLive: projectOverview?.status == statusLive,
          statusDefectCheck: projectOverview?.status == statusDefectCheck,
          statusKeyCollection: projectOverview?.status == statusKeyCollection,
          statusPendingApproval:
            projectOverview?.status == statusPendingApproval,
          statusRenovation: projectOverview?.status == statusRenovation,
          statusShortStaySetup: projectOverview?.status == statusShortStaySetup,
          statusSold: projectOverview?.status == statusSold,
          statusUnderConstruction:
            projectOverview?.status == statusUnderConstruction,
          statusNewLaunch: projectOverview?.status == statusNewLaunch
        }">
        {{ projectOverview?.status }}
      </div>
      <div class="tag" *ngFor="let tag of projectOverview?.tags">{{ tag }}</div>
    </div>
  </div>
</div>
