import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { NgApexchartsModule } from 'ng-apexcharts'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { AppRoutingModule } from 'src/app/app-routing.module'

import { SidebarComponent } from './component/sidebar/sidebar.component'
import { NotificationBarComponent } from './component/notification-bar/notification-bar.component'
import { MiniMenuComponent } from './component/mini-menu/mini-menu.component'
import { CustomInputComponent } from './component/custom-input/custom-input.component'
import { SplashComponent } from './component/splash/splash.component'
import { SidebarStore } from './component/sidebar/store/sidebar.store'
import { CustomModalComponent } from './component/custom-modal/custom-modal.component'
import { SourceOfBookingComponent } from './component/source-of-booking/source-of-booking.component'
import { AddCustomProjectCardComponent } from './component/add-custom-project-card/add-custom-project-card.component'
import { ProjectGridComponent } from './component/project-grid/project-grid.component'
import { ProjectCardComponent } from './component/project-card/project-card.component'
import { MediaPreviewComponent } from './component/media-preview/media-preview.component'
import { DocListCardComponent } from './component/doc-list-card/doc-list-card.component'
import { ModalContentDirective } from './directive/modal-content.directive'
import { Utility } from './utility/utility'
import { SummaryCardComponent } from './component/summary-card/summary-card.component'
import { DemoDataNotificationComponent } from './component/demo-data-notification/demo-data-notification.component'
import { GreySummaryCardComponent } from './component/grey-summary-card/grey-summary-card.component'

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AngularSvgIconModule.forRoot(),
    NgbModule,
  ],
  declarations: [
    SidebarComponent,
    NotificationBarComponent,
    MiniMenuComponent,
    CustomInputComponent,
    SplashComponent,
    CustomModalComponent,
    SourceOfBookingComponent,
    AddCustomProjectCardComponent,
    ProjectGridComponent,
    ProjectCardComponent,
    MediaPreviewComponent,
    DocListCardComponent,
    ModalContentDirective,
    SummaryCardComponent,
    DemoDataNotificationComponent,
    GreySummaryCardComponent,
  ],
  exports: [
    SidebarComponent,
    NotificationBarComponent,
    MiniMenuComponent,
    CustomInputComponent,
    SplashComponent,
    CustomModalComponent,
    SourceOfBookingComponent,
    AddCustomProjectCardComponent,
    ProjectGridComponent,
    ProjectCardComponent,
    MediaPreviewComponent,
    DocListCardComponent,
    NgbModule,
    SummaryCardComponent,
    DemoDataNotificationComponent,
    GreySummaryCardComponent,
  ],
  providers: [SidebarStore, Utility],
})
export class SharedModule {}
