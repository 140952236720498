<div class="contaiiner-layout">
  <div class="background-pattern-container">
    <img
      src="app/assets/auth-background-pattern.svg"
      class="d-none d-sm-block background-pattern" />
    <img
      src="app/assets/auth-background-pattern-mobile.svg"
      class="d-block d-sm-none background-pattern" />
  </div>
</div>
