export interface Project {
  id?: string
  name?: string
  description?: string
  status?: string
  type?: string
  land_type?: string
  land_title?: string
  tenancy_type?: string
  tags?: string[]
  location?: string
  address_1?: string
  address_2?: string
  state?: string
  area?: string
  post_code?: string
  price_range?: string
  management_office_location?: string
  built_up_size?: string
  built_up_price?: string
  listed_at?: string
  completion_year?: string
  display_suite_link?: string
  is_favorite?: boolean
  images?: Images[]
  developer?: Developer
  developers?: Developer[]
  unit_types?: Unit[]
  additional_informations?: AdditionalInfo[]
  hosts?: HostPartnerAgent[]
  renovation_partners?: HostPartnerAgent[]
  agents?: HostPartnerAgent[]
  market_grade?: MarketGrade
  average_daily_rate?: RateSummary
  occupancy_rate?: RateSummary
  revenue_rate?: RateSummary
  vp_date?: string
}

export interface MarketGrade {
  rental_demand: number
  revenue_growth: number
  seasonality: number
  regulation: number
}

export interface RateSummary {
  current_month: RateLabel
  prev_month: RateLabel
  today: number
}

export interface RateLabel {
  label: string
  amount: number
}

export interface AdditionalInfo {
  id: string
  title?: string
  items?: string[]
}

export interface Images {
  url: string
}

export interface Developer {
  id: string
  avatar_url?: string
  name?: string
  description?: string
  website?: string
}

export interface Unit {
  id: string
  type?: string
  description?: string
  built_size?: string
  starting_price?: string
  bedroom?: string
  bathroom?: string
  max_people?: string
  market_value?: string
  file_url?: string
}

export interface HostPartnerAgent {
  id: string
  name?: string
  description?: string
  avatar_url?: string
}

// export interface RenoPartner {
//   id: string
//   name?: string
//   description?: string
//   avatar_url?: string
// }

// export interface Agent {
//   id: string
//   name: string
//   description: string
//   avatar_url?: string
// }

export interface ProjectFilter {
  state: string[]
  projectType: string[]
  tenancyType: string[]
  projectStatus: string[]
}

export const craftLocation = (
  address_1: string,
  address_2: string,
  post_code: string,
  state: string,
  area: string
): string => {
  return `${address_1}${
    address_2 ? ', ' + address_2 : ''
  }, ${post_code}, ${state}, ${area}`
}
