<div class="property-add-container">
  <div class="content">
    <div class="form-container">
      <div class="form-title">Add Property</div>
      <form
        [formGroup]="addPropertyForm"
        [ngClass]="{ 'has-errors': addPropertyForm.invalid && submitted }"
        (ngSubmit)="submit()">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="project_name"
              label="Project Name"
              name="project_name"
              type="text"
              [disableFieldsState]="disableFieldsState">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="project_type"
              label="Project Type"
              name="project_type"
              type="select"
              [disableFieldsState]="disableFieldsState"
              [valueList]="projectTypeList">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="land_type"
              label="Land Type"
              name="land_type"
              type="select"
              [disableFieldsState]="disableFieldsState"
              [valueList]="landTypeList">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="address_1"
              label="Address 1"
              name="address_1"
              type="text"
              [disableFieldsState]="disableFieldsState">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="address_2"
              label="Address 2"
              name="address_2"
              type="text"
              [disableFieldsState]="disableFieldsState">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="state"
              label="State"
              name="state"
              type="select"
              [disableFieldsState]="disableFieldsState"
              [valueList]="stateList">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="area"
              label="Area"
              name="area"
              type="select"
              [disableFieldsState]="disableFieldsState"
              [valueList]="countryList">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="post_code"
              label="Postcode"
              name="post_code"
              type="text"
              [disableFieldsState]="disableFieldsState">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="unit_number"
              label="Unit No"
              name="unit_number"
              type="text">
            </custom-input>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <custom-input
              [formGroup]="addPropertyForm"
              id="category"
              label="Category"
              name="category"
              type="select"
              [valueList]="tenancyTypeList">
            </custom-input>
          </div>
          <div class="col-12" *ngIf="errObject.message">
            <div class="alert form-alert mt-3">
              <div class="d-flex align-items-center text-danger">
                <img class="pe-2 icon-warning" src="app/assets/warning.svg" />
                <span class="body-1">
                  {{ errObject.message }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="btn-group">
              <button class="btn text-primary" (click)="onBackPressed()">
                Back
              </button>
              <button
                type="{{ isLoading ? 'button' : 'submit' }}"
                class="btn btn-outline-primary"
                [ngClass]="{ 'btn-load': isLoading }">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="isLoading"></span>
                {{ isLoading ? "Waiting" : "Submit" }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
