<div class="notfound-layout">
  <div class="background-pattern-container">
    <img
      src="app/assets/auth-background-pattern.svg"
      class="d-none d-sm-block background-pattern" />
    <img
      src="app/assets/auth-background-pattern-mobile.svg"
      class="d-block d-sm-none background-pattern" />
  </div>
  <div class="notfound-container">
    <span class="display-5 text-light">404</span>
    <span class="display-1 text-light mt-4 mb-2"
      >Sorry. the content you’re looking for doesn’t exist. Either it was
      removed, or you mistyped the link.</span
    >
    <button class="btn text-primary bg-light mt-5" (click)="redirectHome()">
      <h2>Go to homepage</h2>
    </button>
  </div>
</div>
