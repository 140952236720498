<form
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit($event)"
  [ngClass]="{ 'has-errors': errObject.hasError && submitted }"
  ngNativeValidate>
  <div class="row">
    <div class="col-12">
      <div class="custom-input-group">
        <span class="input-icon" id="email"
          ><img src="app/assets/mail.svg"
        /></span>
        <input
          id="email"
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Email Address"
          required />
      </div>
    </div>
    <div class="col-12">
      <div class="custom-input-group mt-3">
        <span class="input-icon" id="password"
          ><img src="app/assets/key.svg"
        /></span>
        <input
          id="password"
          [type]="showPass ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
          placeholder="Password"
          required />
        <span class="right-icon"
          ><img
            class="show-hide"
            src="app/assets/show-hide-icon.svg"
            (click)="showHidePassword()"
        /></span>
      </div>
    </div>
    <div class="col-12">
      <div class="form-check-container">
        <div class="alert form-alert mt-3">
          <div class="d-flex align-items-center text-danger">
            <img class="pe-2 icon-warning" src="app/assets/warning.svg" />
            <span class="body-1">
              {{ errObject.message }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="keepMeLoggedIn"
              formControlName="keepMeLoggedIn" />
            <label class="form-check-label text-secondary" for="keepMeLoggedIn">
              Keep me logged in
            </label>
          </div>
          <a [routerLink]="['/forgot-password']" class="link-primary"
            >Forgot Password</a
          >
        </div>
        <!-- <div *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched) && submitted" class="alert form-alert">
                    <div class="d-flex align-items-center text-danger">
                        <img class="pe-2 icon-warning" src="app/assets/warning.svg">
                        <span class="body-1">
                            {{errMessage()}}
                        </span>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="d-grid gap-2 col-12">
      <button
        type="{{ isLoading ? 'button' : 'submit' }}"
        class="btn btn-primary btn-login"
        [ngClass]="{ 'btn-load': isLoading }">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading"></span>
        {{ isLoading ? "Waiting" : "Log in" }}
      </button>
    </div>
    <div class="col-12 mt-4">
      <div class="bottom-links">
        <small class="text-secondary"
          >Host? <a class="link-primary">Login Here</a></small
        >
        <small class="text-secondary"
          >New User?
          <a [routerLink]="['/sign-up']" class="link-primary"
            >Register Now!</a
          ></small
        >
      </div>
    </div>
  </div>
</form>
