import { Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router'
import { Project } from 'src/app/modules/shared/models/project.model'
import { ProjectsService } from 'src/app/modules/shared/service/projects.service'
import { SecuredStore } from '../../../store/secured.store'

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.sass'],
})
export class ProjectListComponent implements OnInit {
  private readonly _projectListSource = new BehaviorSubject<Project[]>([])
  readonly projectListState = this._projectListSource.asObservable()

  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    private securedStore: SecuredStore
  ) {
    this.securedStore.updatePageTitle('Project Listings')
  }

  ngOnInit(): void {
    this.refresh()
  }

  handleCardClick = id => {
    this.router.navigate([
      '/project-listings/detail',
      { id: id },
      // { id: this.propertyData.id, unit_no: this.propertyData.unit_no },
    ])
  }

  refresh = (filterConfigStr?: string) => {
    this.projectsService
      .getProjects(filterConfigStr)
      .then((resp: Project[]) => {
        this._projectListSource.next(resp)
      })
  }
}
