
import { PropertyDetail } from '../models/property.model'

export const populateMarketTrend = (demoData: PropertyDetail[]) => {

  return demoData.map(demo => {
    return {
      year: demo.year.toString(),
      month: demo.month,
      occupancy_rate: demo.occupancy_rate.current_month,
      revenue: demo.total_earnings.current_month,
    }
  })
}

export const DemoDashboardData = (demoData) => {
  return demoData.map((data, index) => {
    return {
      year: data.year,
      month: data.month,
      total_earnings: data.total_earnings.current_month,
      occupancy_rate: data.occupancy_rate.current_month,
      average_daily_earnings: data.total_earnings.current_month / new Date(data.year, data.month, 0).getDate(),
      earnings_overview: {
        monthly_avg_occupancy_rate: 0,
        monthly_avg_earnings: 0,
        current_year_earnings: {
          jan: 6880,
          feb: 6880,
          mar: 6880,
          apr: 6880,
          may: 6880,
          jun: 6880,
          jul: 6880, //DemoPropertyDetail[0].total_revenue.current_month,
          aug: 6880, //DemoPropertyDetail[1].total_revenue.current_month,
          sep: 6880,
          oct: 6880,
          nov: 6880,
          dec: 6880,
        },
      },
      pending_documents: [
        {
          id: '1',
          name: 'Document Name',
          file_url: '',
          online_sign_url: '',
          status: 'unsigned',
        },
        {
          id: '2',
          name: 'Document Name',
          file_url: '',
          online_sign_url: '',
          status: 'signed',
        },
      ],
      property: {
        total_units: 5,
        for_sale: 2,
        for_rent: 3,
        property_owned_share: {
          under_construction: 0,
          key_collection: 1,
          defect_check: 0,
          renovation: 0,
          short_stay_setup: 2,
          live: 1,
          new_launch: 0,
          sold: 0,
          pending_approval: 1,
        },
      },
      recent_activities: [
        {
          user: 'You',
          activity: 'added new property',
          date: '2022-08-09 14:54:12',
        },
        {
          user: 'You',
          activity: 'explored services',
          date: '2022-04-04 23:40:44',
        },
        {
          user: 'You',
          activity: 'explored property',
          date: '2022-03-14 12:58:13',
        },
      ],
      protection: { unprotected: 3, protected: 2 },
      source_of_booking: data.source_of_booking,
      has_account_setup: [
        {
          label: 'Update your profile',
          is_completed: false,
          time_needed: '5 minutes',
          action_id: 'property-added',
          nav_url: '/profile',
          btn_label: 'My Profile',
        },
        {
          label: 'Explore property demo',
          is_completed: false,
          time_needed: '10 minutes',
          action_id: 'property-explored',
          nav_url: '/my-properties',
          btn_label: 'Properties',
        },
        {
          label: 'Add property to start monetizing!',
          is_completed: false,
          time_needed: '10 minutes',
          action_id: 'property-added',
          nav_url: '/my-properties/own-project-list',
          btn_label: 'Add Property',
        },
        {
          label: 'Explore HAS services',
          is_completed: false,
          time_needed: '10 minutes',
          action_id: 'services-explored',
          nav_url: '/has-services',
          btn_label: 'Navigate',
        },
      ],
      download_report: {
        construction_url: '',
        defect_check_url: '',
        renovation_url: '',
        revenue_url: '',
      },
    }
  })
}


const month = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

//generate earning stats
export const earningStatsData = (demoData) => {

  // pop latest month item
  demoData.pop();

  return {
    monthly_avg_occupancy_rate: 100,
    monthly_avg_earnings: 6880,
    current_year_earnings: demoData.reduce(
      (obj, item, index) => Object.assign(obj, { [month[index]]: item.total_earnings.current_month }), {})
  }
}


// export const DemoDashboard: DashboardApiRes[] = [
//   {
//     year: 2022,
//     month: 7,
//     total_earnings: 0,
//     occupancy_rate: 0,
//     average_daily_earnings: 0,
//     earnings_overview: {
//       monthly_avg_occupancy_rate: 0,
//       monthly_avg_earnings: 0,
//       current_year_earnings: {
//         jan: 0,
//         feb: 0,
//         mar: 0,
//         apr: 0,
//         may: 0,
//         jun: 0,
//         jul: 0, //DemoPropertyDetail[0].total_revenue.current_month,
//         aug: 0, //DemoPropertyDetail[1].total_revenue.current_month,
//         sep: 0,
//         oct: 0,
//         nov: 0,
//         dec: 0,
//       },
//     },
//     pending_documents: [
//       {
//         id: '1',
//         name: 'Document Name',
//         file_url: '',
//         online_sign_url: '',
//         status: 'unsigned',
//       },
//       {
//         id: '2',
//         name: 'Document Name',
//         file_url: '',
//         online_sign_url: '',
//         status: 'signed',
//       },
//     ],
//     property: {
//       total_units: 5,
//       for_sale: 2,
//       for_rent: 3,
//       property_owned_share: {
//         under_construction: 0,
//         key_collection: 1,
//         defect_check: 0,
//         renovation: 0,
//         short_stay_setup: 2,
//         live: 1,
//         new_launch: 0,
//         sold: 0,
//         pending_approval: 1,
//       },
//     },
//     recent_activities: [
//       {
//         user: 'You',
//         activity: 'added new property',
//         date: '2022-08-09 14:54:12',
//       },
//       {
//         user: 'You',
//         activity: 'explored services',
//         date: '2022-04-04 23:40:44',
//       },
//       {
//         user: 'You',
//         activity: 'explored property',
//         date: '2022-03-14 12:58:13',
//       },
//     ],
//     protection: { unprotected: 3, protected: 2 },
//     source_of_booking: DemoPropertyDetail[0].source_of_booking,
//     has_account_setup: [
//       {
//         label: 'Update your profile',
//         is_completed: false,
//         time_needed: '5 minutes',
//         action_id: 'property-added',
//         nav_url: '/profile',
//         btn_label: 'My Profile',
//       },
//       {
//         label: 'Explore property demo',
//         is_completed: false,
//         time_needed: '10 minutes',
//         action_id: 'property-explored',
//         nav_url: '/my-properties',
//         btn_label: 'Properties',
//       },
//       {
//         label: 'Add property to start monetizing!',
//         is_completed: false,
//         time_needed: '10 minutes',
//         action_id: 'property-added',
//         nav_url: '/my-properties/own-project-list',
//         btn_label: 'Add Property',
//       },
//       {
//         label: 'Explore HAS services',
//         is_completed: false,
//         time_needed: '10 minutes',
//         action_id: 'services-explored',
//         nav_url: '/has-services',
//         btn_label: 'Navigate',
//       },
//     ],
//     download_report: {
//       construction_url: '',
//       defect_check_url: '',
//       renovation_url: '',
//       revenue_url: '',
//     },
//   },
//   {
//     year: 2022,
//     month: 8,
//     total_earnings: 0,
//     occupancy_rate: 0,
//     average_daily_earnings: 0,
//     earnings_overview: {
//       monthly_avg_occupancy_rate: 0,
//       monthly_avg_earnings: 0,
//       current_year_earnings: {
//         jan: 0,
//         feb: 0,
//         mar: 0,
//         apr: 0,
//         may: 0,
//         jun: 0,
//         jul: 0, //DemoPropertyDetail[0].total_revenue.current_month,
//         aug: 0, //DemoPropertyDetail[1].total_revenue.current_month,
//         sep: 0,
//         oct: 0,
//         nov: 0,
//         dec: 0,
//       },
//     },
//     pending_documents: [
//       {
//         id: '1',
//         name: 'Document Name',
//         file_url: '',
//         online_sign_url: '',
//         status: 'unsigned',
//       },
//       {
//         id: '2',
//         name: 'Document Name',
//         file_url: '',
//         online_sign_url: '',
//         status: 'signed',
//       },
//     ],
//     property: {
//       total_units: 5,
//       for_sale: 2,
//       for_rent: 3,
//       property_owned_share: {
//         under_construction: 0,
//         key_collection: 1,
//         defect_check: 0,
//         renovation: 0,
//         short_stay_setup: 2,
//         live: 1,
//         new_launch: 0,
//         sold: 0,
//         pending_approval: 1,
//       },
//     },
//     recent_activities: [
//       {
//         user: 'You',
//         activity: 'added new property',
//         date: '2022-08-09 14:54:12',
//       },
//       {
//         user: 'You',
//         activity: 'explored services',
//         date: '2022-04-04 23:40:44',
//       },
//       {
//         user: 'You',
//         activity: 'explored property',
//         date: '2022-03-14 12:58:13',
//       },
//     ],
//     protection: { unprotected: 3, protected: 2 },
//     source_of_booking: DemoPropertyDetail[1].source_of_booking,
//     has_account_setup: [
//       {
//         label: 'Update your profile',
//         is_completed: false,
//         time_needed: '5 minutes',
//         action_id: 'property-added',
//         nav_url: '/profile',
//         btn_label: 'My Profile',
//       },
//       {
//         label: 'Explore property demo',
//         is_completed: false,
//         time_needed: '10 minutes',
//         action_id: 'property-explored',
//         nav_url: '/my-properties',
//         btn_label: 'Properties',
//       },
//       {
//         label: 'Add property to start monetizing!',
//         is_completed: false,
//         time_needed: '10 minutes',
//         action_id: 'property-added',
//         nav_url: '/my-properties/own-project-list',
//         btn_label: 'Add Property',
//       },
//       {
//         label: 'Explore HAS services',
//         is_completed: false,
//         time_needed: '10 minutes',
//         action_id: 'services-explored',
//         nav_url: '/has-services',
//         btn_label: 'Navigate',
//       },
//     ],
//     download_report: {
//       construction_url: '',
//       defect_check_url: '',
//       renovation_url: '',
//       revenue_url: '',
//     },
//   },
// ]
