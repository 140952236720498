<div class="expenses bg-white">
  <div class="title">Expenses</div>
  <div class="content mt-3">
    <table>
      <tbody>
        <ng-template [ngIf]="!isSold">
          <ng-template
            [ngIf]="expenseData?.lists?.length > 0"
            [ngIfElse]="noDataList">
            <tr *ngFor="let category of expenseData?.lists">
              <td>{{ category.name }}</td>
              <td *ngIf="currency != null; else defaultCurrency">
                {{ currency }}
              </td>
              <td class="text-right">{{ category.amount | number: "1.2" }}</td>
            </tr>
          </ng-template>
          <ng-template #noDataList>
            <tr>
              <td class="pb-4">-</td>
              <td class="pb-4">MYR</td>
              <td class="text-right pb-4">-</td>
            </tr>
          </ng-template>
        </ng-template>
        <tr class="total-row">
          <td>Total Expenses</td>
          <td *ngIf="currency != null; else defaultCurrency">{{ currency }}</td>
          <td
            class="text-right"
            *ngIf="expenseData?.total_expenses > 0; else noValue">
            {{ expenseData?.total_expenses | number: "1.2" }}
          </td>
        </tr>
        <tr class="borne-row" *ngIf="!isSold && !isBppUser">
          <td>Borne by Host:</td>
          <td *ngIf="currency != null; else defaultCurrency">{{ currency }}</td>
          <td
            class="text-right"
            *ngIf="expenseData?.borne_by_host > 0; else noValue">
            {{ expenseData?.borne_by_host | number: "1.2" }}
          </td>
        </tr>
        <tr class="borne-row" *ngIf="!isSold && !isBppUser">
          <td>Borne by Owner:</td>
          <td *ngIf="currency != null; else defaultCurrency">{{ currency }}</td>
          <td
            class="text-right"
            *ngIf="expenseData?.borne_by_owner > 0; else noValue">
            {{ expenseData?.borne_by_owner | number: "1.2" }}
          </td>
        </tr>
        <tr class="divider">
          <td colspan="3">
            <div></div>
          </td>
        </tr>
        <div *ngIf="!isBppUser; else bppTemplate">
          <tr class="total-row">
            <td>Total Net Revenue</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.total_net_revenue > 0; else noValue">
              {{ expenseData?.total_net_revenue | number: "1.2" }}
            </td>
          </tr>
          <tr class="total-row">
            <td>Owner's Revenue</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.owner_revenue > 0; else noValue">
              {{ expenseData?.owner_revenue | number: "1.2" }}
            </td>
          </tr>
          <tr class="owner-borne-row">
            <td>Owner Borne Expenses</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.owner_borne_expenses > 0; else noValue">
              {{ expenseData?.owner_borne_expenses | number: "1.2" }}
            </td>
          </tr>
          <tr class="total-row">
            <td>Owner's Net Profit</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.owner_net_profit > 0; else noValue">
              {{ expenseData?.owner_net_profit | number: "1.2" }}
            </td>
          </tr>
          <tr class="total-row">
            <td>Per Unit Net Revenue</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.per_unit_revenue > 0; else noValue">
              {{ expenseData?.per_unit_revenue | number: "1.2" }}
            </td>
          </tr>
        </div>
        <ng-template #bppTemplate>
          <tr class="total-row">
            <td>Unit Revenue</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.per_unit_revenue > 0; else noValue">
              {{ expenseData?.per_unit_revenue | number: "1.2" }}
            </td>
          </tr>
          <tr class="total-row">
            <td>Unit Expenses</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.total_expenses > 0; else noValue">
              {{ expenseData?.total_expenses | number: "1.2" }}
            </td>
          </tr>
          <tr class="total-row">
            <td>Unit Net Profit</td>
            <td *ngIf="currency != null; else defaultCurrency">
              {{ currency }}
            </td>
            <td
              class="text-right"
              *ngIf="expenseData?.owner_net_profit > 0; else noValue">
              {{ expenseData?.owner_net_profit | number: "1.2" }}
            </td>
          </tr>
        </ng-template>
        <ng-template #defaultCurrency>
          <td>MYR</td>
        </ng-template>
        <ng-template #noValue>
          <td class="text-right">-</td>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
