import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-key-collection-basic-card',
  templateUrl: './key-collection.component.html',
  styleUrls: ['./key-collection.component.sass'],
})
export class KeyCollectionBasicCardComponent implements OnInit {
  @Input() defectCheckCallback = () => {}

  constructor() {}

  ngOnInit(): void {}
}
