import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { InsuranceProtectionModel } from './model/insurance-protection.model'

@Component({
  selector: 'app-insurance-protection',
  templateUrl: './insurance-protection.component.html',
  styleUrls: ['./insurance-protection.component.sass'],
})
export class InsuranceProtectionComponent implements OnInit {
  @Input() insuranceProtectionState: Observable<InsuranceProtectionModel>
  insuranceData: InsuranceProtectionModel

  constructor() {}

  ngOnInit(): void {
    this.insuranceProtectionState.subscribe(data => {
      this.insuranceData = data
    })
  }
}
