<form
  [formGroup]="signUpForm"
  (ngSubmit)="onSubmit($event)"
  [ngClass]="{ 'has-errors': signUpForm.invalid && submitted }">
  <div class="row">
    <div class="col-12">
      <div class="custom-input-group">
        <span class="input-icon" id="email"
          ><img src="app/assets/mail.svg"
        /></span>
        <input
          id="email"
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Email Address" />
      </div>
    </div>
    <div class="col-12">
      <div class="custom-input-group mt-3">
        <span class="input-icon" id="password"
          ><img src="app/assets/key.svg"
        /></span>
        <input
          id="password"
          [type]="showPass.password ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
          placeholder="Password"
          #pass />
        <span class="right-icon"
          ><img
            class="show-hide"
            src="app/assets/show-hide-icon.svg"
            (click)="showHidePassword(pass.id)"
        /></span>
      </div>
    </div>
    <div class="col-12">
      <div class="custom-input-group mt-3">
        <span class="input-icon" id="confirm_password"
          ><img src="app/assets/key.svg"
        /></span>
        <input
          id="confirm_password"
          [type]="showPass.confirm_password ? 'text' : 'password'"
          class="form-control"
          formControlName="confirm_password"
          placeholder="Confirm Password"
          #confirmPass />
        <span class="right-icon"
          ><img
            class="show-hide"
            src="app/assets/show-hide-icon.svg"
            (click)="showHidePassword(confirmPass.id)"
        /></span>
      </div>
    </div>
    <div class="col-12">
      <div class="custom-input-group mt-3">
        <span class="input-icon" id="referralCode"
          ><img src="app/assets/referral.svg"
        /></span>
        <input
          id="referralCode"
          type="text"
          class="form-control"
          formControlName="referralCode"
          placeholder="Referral Code (Optional)"
          [attr.disabled]="disableField ? '' : null" />
      </div>
    </div>
    <div class="col-12">
      <div class="alert form-alert mt-3">
        <div class="d-flex align-items-center text-danger">
          <img class="pe-2 icon-warning" src="app/assets/warning.svg" />
          <span class="body-1">
            {{ errObject.message }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2 col-12">
      <button
        type="{{ isLoading ? 'button' : 'submit' }}"
        class="btn btn-primary btn-sign-up mt-5"
        [ngClass]="{ 'btn-load': isLoading }">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading"></span>
        {{ isLoading ? "Waiting" : "Sign Up" }}
      </button>
    </div>
    <div class="col-12 mt-4">
      <div class="bottom-links">
        <small class="text-secondary"
          >Host?
          <a [routerLink]="['/login']" class="link-primary"
            >Sign Up here</a
          ></small
        >
        <small class="text-secondary"
          >Member?
          <a [routerLink]="['/login']" class="link-primary"
            >Login here</a
          ></small
        >
      </div>
    </div>
  </div>
</form>
